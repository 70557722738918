

import { Grid } from "@mui/material";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function BotButtons({ editMode, setEditMode, saveForm, triggerDelete }) {

    const navigate = useNavigate();
    return (
        <Grid item xs={12} lg={2} container alignItems="center" textAlign="end">
        {!editMode && (
          <>
            <HitchButton
              variant="outlined"
              color="white"
              onClick={(e) => setEditMode(true)}>
              Edit
            </HitchButton>
            <HitchButton
              variant="outlined"
              color="white"
              onClick={(e) => navigate('/deployment/my-bots')}>
              Bot List
            </HitchButton>
          </>
        )}
        {editMode && (
          <>
            <HitchButton
              variant="outlined"
              color="white"
              onClick={(e) => saveForm()}>
              Save
            </HitchButton>
            <HitchButton
              variant="outlined"
              color="white"
              onClick={(e) => setEditMode(false)}>
              Cancel
            </HitchButton>
            <HitchButton
              variant="outlined"
              color="white"
              onClick={(e) => triggerDelete()}>
              Delete
            </HitchButton>
          </>
        )}
      </Grid>
    );
}

export default BotButtons;