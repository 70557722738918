
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6/index.js

import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HitchDataTable from "components/HitchDataTable";
import { Grid } from "@mui/material";
import HitchButton from "components/HitchButton";
import HitchDisplayField from "components/HitchDisplayField";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";
import HitchSelect from "components/HitchSelect";

function H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6() {
    const [config, setConfig] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [ users, setUsers ] = useState([]);

    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser
    } = useHubConnections();

    function saveConfig(values) {
        console.log('save', values);

        const theConfiguration = {
            Configuration: {
                serviceId: config.serviceId,
                connectionId: config.connectionId,
                gptModelName: config.gptModelName,
                integrationUserId: values.integrationUserId
            }
        };

        console.log('Saving the following Configuration Data', theConfiguration);
        hitchDashCommand('marketing', 'config', 'update', theConfiguration, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("42f21f1a-d26f-4cd5-b759-e1246783a695", setConfig);
                dashboardConnection.on("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", setUsers);
                hitchDashCommand('marketing', 'config', 'get', {});
                hitchDashCommand('account', 'role', 'user-list', {
                    theAccountId: hitchUser.accountId
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("42f21f1a-d26f-4cd5-b759-e1246783a695", setConfig);
                dashboardConnection.off("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", setUsers);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (config) {
            console.log('the Config', config);
        }
    }, [config]);

    useEffect(() => {
        if (users) {
            console.log('the Users', users);
        }
    }, [users]);

    const sortedUsers = [...users].sort((a, b) => a.name.localeCompare(b.name));

    const getUserName = (userId) => {
        const retUser = users.find((usr) => usr.id === userId);
        return retUser ? retUser.name : "N/A";
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Marketing Service
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Configuration
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3}>
                            {!editMode ? (
                                <HitchBox mb={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <HitchButton
                                            variant="outlined"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                                color: white.main,
                                            })}
                                            onClick={() => setEditMode(true)}
                                        >
                                            Edit
                                        </HitchButton>
                                    </Grid>
                                    <HitchDisplayField label="Integration User:" value={getUserName(config?.integrationUserId)} />
                                </HitchBox>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{
                                            integrationUserId: config?.integrationUserId || "",
                                        }}
                                        onSubmit={(values) => saveConfig(values)}
                                    >
                                        {({ values, setFieldValue, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <HitchBox>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={5}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <HitchButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                            })}
                                                        >
                                                            save
                                                        </HitchButton>
                                                        <HitchButton
                                                            variant="outlined"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                                color: white.main,
                                                            })}
                                                            onClick={() => setEditMode(false)}
                                                        >
                                                            cancel
                                                        </HitchButton>
                                                    </Grid>

                                                    <HitchSelect
                                                        name="integrationUserId"
                                                        label="User For Integration"
                                                        options={sortedUsers.map((option) => ({
                                                            label: option.name,
                                                            value: option.id,
                                                        }))}
                                                        placeholder="Select user"
                                                        isClearable
                                                    />

                                                </HitchBox>
                                            </Form>
                                        )}
                                    </Formik>


                                </>
                            )}

                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6;