import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";

function OrderSummary() {
  return (
    <>
      <HitchBox mb={2}>
        <HitchTypography variant="h6" color="white" fontWeight="medium">
          Order Summary
        </HitchTypography>
      </HitchBox>
      <HitchBox display="flex" justifyContent="space-between" mb="20px">
        <HitchTypography variant="button" fontWeight="regular" color="text">
          Product Price:
        </HitchTypography>
        <HitchBox>
          <HitchTypography variant="body2" color="white" fontWeight="medium">
            $90
          </HitchTypography>
        </HitchBox>
      </HitchBox>
      <HitchBox display="flex" justifyContent="space-between" mb="20px">
        <HitchTypography variant="button" fontWeight="regular" color="text">
          Delivery:
        </HitchTypography>
        <HitchBox>
          <HitchTypography variant="body2" color="white" fontWeight="medium">
            $14
          </HitchTypography>
        </HitchBox>
      </HitchBox>
      <HitchBox display="flex" justifyContent="space-between" mb="20px">
        <HitchTypography variant="button" fontWeight="regular" color="text">
          Taxes:
        </HitchTypography>
        <HitchBox>
          <HitchTypography variant="body2" color="white" fontWeight="medium">
            $1.95
          </HitchTypography>
        </HitchBox>
      </HitchBox>
      <HitchBox display="flex" justifyContent="space-between" mt={3}>
        <HitchTypography variant="body1" fontWeight="light" color="text">
          Total:
        </HitchTypography>
        <HitchBox>
          <HitchTypography variant="body1" color="white" fontWeight="medium">
            $1.95
          </HitchTypography>
        </HitchBox>
      </HitchBox>
    </>
  );
}

export default OrderSummary;
