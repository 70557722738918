
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/nodes/NodeDetails/index.js

import { Card, Grid } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import HitchEditButtons from "components/HitchEditButtons";
import HitchFormField from "components/HitchFormField";
import HitchSelect from "components/HitchSelect";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HitchDisplayBadgeList from "components/HitchDisplayBadgeList";
import HitchFormBadgeList from "components/HitchFormBadgeList";

function NodeDetails() {
    const { nodeId } = useParams();
    const [node, setNode] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [images, setImages] = useState([]);
    const [imageVersions, setImageVersions] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [replicaCounts, setReplicaCounts] = useState([0,1,2,3]);
    const { dashboardConnection, hitchDashCommand, hitchUser } = useHubConnections();
    const navigate = useNavigate();

    function saveNode(values) {
        console.log('save', values);
        const theNode = {
            theNode: {
                id: node.id,
                created: node.created,
                createdBy: node.createdBy,
                updated: node.updated,
                updatedBy: node.updatedBy,
                accountId: values.accountId,
                replicas: values.replicas,
                roleList: values.roleList,
                imageId: values.imageId,
                imageVersionId: values.imageVersionId,
                name: values.name
            }
        };
        console.log('Saving the following Node Data', theNode);
        hitchDashCommand('admin', 'node', 'update', theNode, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection && nodeId) {
            const subscribeToEvents = () => {
                console.log('subscribing to events!');

                dashboardConnection.on("01a3b296-c045-4ada-abe8-8c53f0752cb2", nodeReceived);
                dashboardConnection.on("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
                dashboardConnection.on("4aae7b7f-7b6f-4b83-96bc-38f211404929", setImages);
                dashboardConnection.on("afedcf64-7554-44d1-bc0c-fad33eb1f5b5", setImageVersions);

                hitchDashCommand('admin', 'node', 'get', { nodeId: nodeId });
                hitchDashCommand('admin', 'account', 'list', {});
                hitchDashCommand('admin', 'image', 'list', {}); 
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("01a3b296-c045-4ada-abe8-8c53f0752cb2", nodeReceived);
                dashboardConnection.off("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
                dashboardConnection.off("4aae7b7f-7b6f-4b83-96bc-38f211404929", setImages);
                dashboardConnection.off("afedcf64-7554-44d1-bc0c-fad33eb1f5b5", setImageVersions);
            };
        }
    }, [dashboardConnection, nodeId]);

    function deleteConfirmed() {
        console.log('delete confirmed.');
        hitchDashCommand('admin', 'node', 'delete', { nodeId: nodeId }, () => {
            navigate('/hitch-admin/nodes');
        });

    }

    useEffect(() => {
        if (node?.imageId) {
            hitchDashCommand("admin", "image-version", "list", { imageId: node.imageId });
        }
    }, [node.imageId]);


    const sortedAccounts = [...accounts].sort((a, b) => a.name.localeCompare(b.name));
    const sortedImages = [...images].sort((a, b) => a.name.localeCompare(b.name));
    const sortedImageVersions = [...imageVersions].sort((a, b) => {
        const parseVersion = (version) => version?.split('.').map(Number);
    
        const [majorA, minorA, patchA, buildA] = parseVersion(a.name);
        const [majorB, minorB, patchB, buildB] = parseVersion(b.name);
    
        return (
            majorB - majorA ||  minorB - minorA ||  patchB - patchA ||  buildB - buildA
        );
    });
    
    function nodeReceived(data) {
        if(!data || data === "An Error has occurred.") {
            setNode({
                id: nodeId,
                name: '',
                replicas: 0,
                accountId: hitchUser.accountId,
                roleList: '',
                imageId: null,
                imageVersionId: null
            });
            setEditMode(true);
            return;
        }
        setNode(data);
        if (data?.imageId) {
            hitchDashCommand("admin", "image-version", "list", { imageId: data.imageId });
        }
    }

    function findNameById(list, id)  {
        const foundItem = list.find((item) => item.id === id);
        return foundItem ? foundItem.name : "Unknown";
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox py={3}>
                <Card
                    sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                        overflow: "visible",
                        background: linearGradient(
                            gradients.cardDark.main,
                            gradients.cardDark.state,
                            gradients.cardDark.deg
                        ),
                    })}
                >
                    <HitchBox p={3}>
                        <HitchBox mb={5}>
                            <HitchTypography variant="lg" fontWeight="medium" color="white">
                                Node: {node?.name}
                            </HitchTypography>
                        </HitchBox>

                        {!editMode ? (
                            <HitchBox mb={2}>
                                <HitchEditButtons 
                                    editAction={() => setEditMode(true)}
                                    deleteAction={() => deleteConfirmed()}
                                    parentLabel= 'Back to Nodes'
                                    parentRoute='/hitch-admin/nodes'
                                />
                                <HitchDisplayField label="Node Name" value={node?.name} />
                                <HitchDisplayField label="Account" value={findNameById(accounts, node?.accountId)} />
                                <HitchDisplayBadgeList label="Roles" value={node?.roleList} />
                                <HitchDisplayField label="Replicas" value={node?.replicas} />
                                <HitchDisplayField label="Image" value={findNameById(images, node?.imageId)} />
                                <HitchDisplayField
                                    label="Image Version"
                                    value={findNameById(imageVersions, node?.imageVersionId)}
                                />
                            </HitchBox>
                        ) : (
                            <>
                                <Formik
                                    initialValues={{
                                        name: node?.name || "",
                                        accountId: node?.accountId || "",
                                        roleList: node?.roleList || "",
                                        replicas: node?.replicas || 0,
                                        imageId: node?.imageId || "",
                                        imageVersionId: node?.imageVersionId || "",
                                    }}
                                    onSubmit={(values) => saveNode(values)}
                                >
                                    {({ values, handleChange, setFieldValue, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <HitchBox>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={5}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <HitchButton
                                                        type="submit"
                                                        variant="outlined"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                        })}
                                                    >
                                                        save
                                                    </HitchButton>
                                                    <HitchButton
                                                        variant="contained"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                        })}
                                                        onClick={() => setEditMode(false)}
                                                    >
                                                        cancel
                                                    </HitchButton>
                                                </Grid>

                                                <HitchFormField
                                                    name="name"
                                                    label="Node Name"
                                                    fullWidth
                                                    editMode={editMode}
                                                />

                                                <HitchSelect
                                                    name="accountId"
                                                    label="Account"
                                                    options={sortedAccounts.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    placeholder="Select account"
                                                    onChange={(e) => setFieldValue("accountId", e.value)}
                                                    editMode={editMode}
                                                    isClearable
                                                />

                                                <HitchFormBadgeList 
                                                    name="roleList"
                                                    label="Roles"
                                                    editMode={editMode}
                                                    fullWidth
                                                /> 

                                                <HitchFormField
                                                    name="roleList"
                                                    label="Roles"
                                                    fullWidth
                                                    editMode={editMode}
                                                />

                                                <HitchSelect
                                                    name="replicas"
                                                    label="Replica Count"
                                                    options={replicaCounts.map((option) => ({
                                                        label: option,
                                                        value: option,
                                                    }))}
                                                    placeholder="Select Count"
                                                    isClearable
                                                    editMode={editMode}
                                                />

                                                <HitchSelect
                                                    name="imageId"
                                                    label="Container Image"
                                                    options={sortedImages.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    placeholder="Select Image"
                                                    onChange={(e) => {
                                                        setFieldValue("imageId", e.value);
                                                        hitchDashCommand("admin", "image-version", "list", { imageId: e.value });
                                                    }}
                                                    isClearable
                                                    editMode={editMode}
                                                />

                                                <HitchSelect
                                                    name="imageVersionId"
                                                    label="Image Version"
                                                    options={sortedImageVersions.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    placeholder="Select Image Version"
                                                    onChange={(e) => setFieldValue("imageVersionId", e.value)}
                                                    isClearable
                                                    editMode={editMode}
                                                />

                                            </HitchBox>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        )}
                    </HitchBox>
                </Card>
            </HitchBox>
        </DashboardLayout>
    );
}

export default NodeDetails;