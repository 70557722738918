
import { Card } from "@mui/material";
import HitchTextArea from "components/HitchTextArea";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useState } from "react";

function Demo() {
    const [textValue, setTextValue] = useState('');
    const [textValue2, setTextValue2] = useState('');
    const [error, setError] = useState(false);
    const [error2, setError2] = useState(false);
    const [helperText, setHelperText] = useState('');
    const [helperText2, setHelperText2] = useState('');
  
    const handleChange = (event) => {
      const value = event.target.value;
      setTextValue(value);
  
      // Example validation logic
      if (value.length > 100) {
        setError(true);
        setHelperText('Text exceeds maximum length of 100 characters');
      } else {
        setError(false);
        setHelperText('');
      }
    };

    const handleChange2 = (event) => {
        const value = event.target.value;
        setTextValue2(value);
    
        // Example validation logic
        if (value.length > 100) {
          setError2(true);
          setHelperText2('Text exceeds maximum length of 100 characters');
        } else {
          setError2(false);
          setHelperText2('');
        }
      };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Translation Demo
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                AI Demo
                            </HitchTypography>
                        </HitchBox>

                        <HitchBox p={3}>
                        <HitchTextArea
                            size="medium"
                            error={error}
                            success={!error && textValue.length > 0}
                            helperText={helperText}
                            fullWidth
                            placeholder="Enter your text here..."
                            value={textValue}
                            onChange={handleChange}
                            rows={3} // Limit the text area to 3 rows
                        />
                        <HitchButton color="white" variant="outlined">
                            Translate
                        </HitchButton>
                        <HitchButton color="white" variant="outlined">
                            Make Professional
                        </HitchButton>
                        <HitchTextArea
                            size="medium"
                            error={error2}
                            success={!error2 && textValue2.length > 0}
                            helperText={helperText2}
                            fullWidth
                            placeholder="Enter your text here..."
                            value={textValue2}
                            onChange={handleChange2}
                            rows={3} // Limit the text area to 3 rows
                        />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>

    )
}

export default Demo;