import { useState } from "react";
import Grid from "@mui/material/Grid";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import Module from "./module";

function Subscription() {
    const [selectedModules, setSelectedModules] = useState({
        design: false,
        development: false,
        marketing: false,
    });

    const handleSet = (module) => {
        setSelectedModules((prevState) => ({
            ...prevState,
            [module]: !prevState[module],
        }));
    };

    return (
        <HitchBox>
            <HitchBox width="80%" textAlign="center" mx="auto" mb={4}>
                <HitchBox mb={1}>
                    <HitchTypography variant="lg" fontWeight="bold" color="white">
                        What are you doing? (checkboxes)
                    </HitchTypography>
                </HitchBox>
                <HitchTypography variant="button" fontWeight="regular" color="text">
                    What Modules Do You Want?
                </HitchTypography>
            </HitchBox>
            <HitchBox mt={2}>
                <Grid container spacing={2} justifyContent="center">
                    <Module 
                        design={selectedModules.design}
                        handleSet={() => handleSet('design')}
                        hitchId={'c5e92791-b9e7-4d7e-ae22-90cce4ec0b43'} 
                        label="GitHub" 
                    />
                    <Module 
                        design={selectedModules.development}
                        handleSet={() => handleSet('development')}
                        hitchId={'d8ba1a8e-8733-47bb-a932-62f86e459f66'} 
                        label="Chat" 
                    />
                    <Module 
                        design={selectedModules.marketing}
                        handleSet={() => handleSet('marketing')}
                        hitchId={'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc'} 
                        label="Open AI" 
                    />
                </Grid>
            </HitchBox>
        </HitchBox>
    );
}

export default Subscription;