import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Vision UI Dashboard React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiInput from "components/VuiInput";
import HitchButton from "components/HitchButton";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background-cover-auth-signin.png";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <CoverLayout
      title="Nice to see you!"
      color="white"
      description="Enter your email and password to sign in"
      image={bgImage}
      premotto={"INSPIRED BY THE FUTURE:"}
      motto={"THE VISION UI DASHBOARD"}
    >
      <HitchBox component="form" role="form">
        <HitchBox mb={2}>
          <HitchBox mb={1} ml={0.5}>
            <HitchTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </HitchTypography>
          </HitchBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="email"
              placeholder="Your email..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
        </HitchBox>
        <HitchBox mb={2}>
          <HitchBox mb={1} ml={0.5}>
            <HitchTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </HitchTypography>
          </HitchBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="Your password..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
        </HitchBox>
        <HitchBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <HitchTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Remember me
          </HitchTypography>
        </HitchBox>
        <HitchBox mt={4} mb={1}>
          <HitchButton color="info" fullWidth>
            SIGN IN
          </HitchButton>
        </HitchBox>
        <HitchBox mt={3} textAlign="center">
          <HitchTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <HitchTypography
              component={Link}
              to="/authentication/sign-up/cover"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </HitchTypography>
          </HitchTypography>
        </HitchBox>
      </HitchBox>
    </CoverLayout>
  );
}

export default SignIn;
