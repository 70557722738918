
import { Card } from "@mui/material";
// Vision UI Dashboard React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import BasicLineChart from "examples/Charts/LineCharts/BasicLineChart";
import { lineChartDataDashboard, lineChartOptionsDashboard } from "../../data/lineChart";

const SalesOverview = () => {
  return (
    <Card>
      <HitchBox sx={{ height: "100%" }}>
        <HitchTypography variant="lg" color="white" fontWeight="bold" mb="5px">
          Sales Overview
        </HitchTypography>
        <HitchBox display="flex" alignItems="center" mb="40px">
          <HitchTypography variant="button" color="success" fontWeight="bold">
            +5% more{" "}
            <HitchTypography variant="button" color="text" fontWeight="regular">
              in 2021
            </HitchTypography>
          </HitchTypography>
        </HitchBox>
        <HitchBox sx={{ height: "310px" }}>
          <BasicLineChart
            lineChartData={lineChartDataDashboard}
            lineChartOptions={lineChartOptionsDashboard}
          />
        </HitchBox>
      </HitchBox>
    </Card>
  );
};

export default SalesOverview;
