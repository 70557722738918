
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/H269A23C5_AB35_4F6C_9ADF_5B1C30285A66/index.js

import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import HitchButton from "components/HitchButton";
import HitchFormField from "components/HitchFormField";
import { Form, Formik } from "formik";
import HitchDisplayField from "components/HitchDisplayField";
import HitchFeatureSelector from "components/HitchFeatureSelector";

function H269A23C5_AB35_4F6C_9ADF_5B1C30285A66() {
    const [config, setConfig] = useState({});
    const [editMode, setEditMode] = useState(false);

    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser
    } = useHubConnections();

    function saveConfig(values) {
        console.log('save', values);

        const theConfiguration = {
            Configuration: {
                serviceId: config.serviceId,
                connectionId: config.connectionId,
                gptModelName: config.gptModelName,
                codeRepo: values.codeRepo,
                docsRepo: values.docsRepo
            }
        };

        hitchDashCommand('engineering', 'config', 'update', theConfiguration, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("64766163-ad48-49b1-8d83-ce280d4ca05a", setConfig);
                hitchDashCommand('engineering', 'config', 'get', {});
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("64766163-ad48-49b1-8d83-ce280d4ca05a", setConfig);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (config) {
            console.log('config', config);
        }
    }, [config]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Engineering Service
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Configuration
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3}>
                            {!editMode ? (
                                <HitchBox mb={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <HitchButton
                                            variant="outlined"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                                color: white.main,
                                            })}
                                            onClick={() => setEditMode(true)}
                                        >
                                            Edit
                                        </HitchButton>
                                    </Grid>
                                    <HitchDisplayField label="Code Repository" value={config?.codeRepo} />
                                    <HitchDisplayField label="Docs Repository" value={config?.docsRepo} />
                                </HitchBox>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{
                                            codeRepo: config?.codeRepo || "",
                                            docsRepo: config?.docsRepo || "",
                                        }}
                                        onSubmit={(values) => saveConfig(values)}
                                    >
                                        {({ values, setFieldValue, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <HitchBox>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={5}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <HitchButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                            })}
                                                        >
                                                            save
                                                        </HitchButton>
                                                        <HitchButton
                                                            variant="outlined"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                                color: white.main,
                                                            })}
                                                            onClick={() => setEditMode(false)}
                                                        >
                                                            cancel
                                                        </HitchButton>
                                                    </Grid>

                                                    <HitchFormField
                                                        name="codeRepo"
                                                        label="Code Repository"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="docsRepo"
                                                        label="Docs Repository"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />
                                                </HitchBox>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            )}
                        </HitchBox>
                    </Card>
                    <HitchFeatureSelector
                            serviceId={"269a23c5-ab35-4f6c-9adf-5b1c30285a66"} />
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default H269A23C5_AB35_4F6C_9ADF_5B1C30285A66;