import { Card, CardContent, Divider, Grid } from "@mui/material";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import MarkdownRenderer from "hitch/chat/MarkdownRenderer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GitHubUser from "./GitHubUser";
import HitchDisplayField from "components/HitchDisplayField";
import HitchMarkdownField from "components/HitchMarkdownField";
import HitchLinkField from "components/HitchLinkField";


function IssueComments() {

    const { repoId, issueId } = useParams();
    const [comments, setComments] = useState([]);

    const { dashboardConnection, hitchDashCommand } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection && repoId && issueId) {
            const subscribeToEvents = () => {
                dashboardConnection.on("8c2af7d5-eebe-46bd-9824-dac83bee2ee0", (data) => {
                    setComments(data);
                });

                hitchDashCommand("github", "issuescomments", "list-for-issue", {
                    repoId: repoId,
                    issueNumber: parseInt(issueId, 10),
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("8c2af7d5-eebe-46bd-9824-dac83bee2ee0");
            };
        }
    }, [dashboardConnection, issueId, repoId]);

    useEffect(() => {
        if (comments) {
            console.log('comments', comments);
        }
    }, [comments]);



    return (
        <Card sx={{ marginTop: 3 }}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <HitchTypography variant="h5">Comments</HitchTypography>
                    </Grid>
                    {comments.map(a => (
                        <Grid item xs={12} key={a.id}>
                            <GitHubUser user={a?.user} />
                            <Divider />
                            <Grid container>
                                <Grid item xs={12}>
                                    <HitchLinkField label="View on GitHub" url={a.html_url} newTab={true} />
                                    <HitchBox bgColor="#777" color="#333">
                                        <HitchMarkdownField label="Body" markdown={a.body} />
                                    </HitchBox>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <HitchDisplayField label="Created" value={a?.created_at} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <HitchDisplayField label="Updated" value={a?.updated_at} />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>

        </Card>
    )
}

export default IssueComments;