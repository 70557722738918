
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/ChatInput.js  

import React from 'react';
import HitchBox from 'components/HitchBox';
import HitchButton from 'components/HitchButton';
import HitchTypography from 'components/HitchTypography';
import { Icon } from '@mui/material';
import { useHubConnections } from 'context/HubConnectionsProvider';
import { useChatConnections } from 'context/ChatConnectionProvider';
import ChatMessageInput from './ChatMessageInput'; // Import the new component

function ChatInput({ placeholder, newMessage, handleSendMessage, setNewMessage }) {
  const { hitchUser } = useHubConnections();
  const { isTyping, isBusy } = useChatConnections(); // Access isTyping from context   
  const isMessageEmpty = !newMessage.content;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isTyping && !isMessageEmpty) {
      handleSendMessage(newMessage);
      setNewMessage({ content: '', contentType: 'text', user: null }); // Clear input after send
    } else {
      if (isTyping) {
        console.log(`Didn't fire, because isTyping.`);
      }
      if (isMessageEmpty) {
        console.log(`Didn't fire, because isMessageEmpty.`);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('key');
      if (e.shiftKey) {
        // Allow new line
        return;
      } else {
        // Prevent default behavior and submit
        handleSubmit(e);
      }
    }
  };

  return (
    <HitchBox
      display="grid"
      gridTemplateColumns="1fr auto" // Input takes 1fr, button takes auto
      alignItems="center"
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
        mt: 2,
        px: 2, // Adjusted padding
        py: 1.5, // Adjusted padding
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <ChatMessageInput
        placeholder={placeholder}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        onKeyDown={handleKeyDown} // Pass the key down handler
        submitChat={handleSubmit}
        disabled={isBusy} // Disable input when isBusy
      />

      {isBusy && (
        <HitchButton
          variant="contained"
          color="info"
          startIcon={<Icon>cancel</Icon>}
          sx={{
            minWidth: '100px',
            height: '100%',
            backgroundColor: '#ff0000',
            flexShrink: 0,
            alignSelf: 'center',
            borderTopLeftRadius: 0, // Remove top-left radius
            borderBottomLeftRadius: 0, // Remove bottom-left radius
            animation: 'pulse 1s infinite alternate', // Add pulsing animation
            '@keyframes pulse': {
              '0%': { backgroundColor: '#ff0000' },
              '100%': { backgroundColor: '#800000' }, // Maroon color
            },
          }}
        >
          <HitchTypography variant="button" color="white">
            Cancel
          </HitchTypography>
        </HitchButton>
      )}
      {!isBusy && (
        <HitchButton
          variant="contained"
          color="info"
          onClick={handleSubmit}
          startIcon={<Icon>send</Icon>}
          sx={{
            minWidth: '100px',
            height: '100%',
            backgroundColor: '#1A73E8',
            flexShrink: 0,
            alignSelf: 'center',
            borderTopLeftRadius: 0, // Remove top-left radius
            borderBottomLeftRadius: 0, // Remove bottom-left radius
          }}
        >
          <HitchTypography variant="button" color="white">
            Send
          </HitchTypography>
        </HitchButton>
      )}
    </HitchBox>
  );
}

export default ChatInput;