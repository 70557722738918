// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/ChatHeader.js

import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import HitchBox from "components/HitchBox";
import { IoDocument } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoMdCube } from "react-icons/io";
import { useEffect, useState } from "react";
import { IoMdAdd, IoMdRemove } from "react-icons/io"; // Import icons for toggle

function ChatHeader({ appBarVisible, setAppBarVisible, setShowHistory, setShowPluginSidebar, setBotRibbonVisible, botRibbonVisible, activeTab, setActiveTab }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < 1200 ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const handleSetTabValue = (event, newValue) => {
    if (newValue === 2) { // "BOTS" tab clicked
      setBotRibbonVisible(!botRibbonVisible); // Toggle bot ribbon visibility
      setActiveTab(2); // Keep "BOTS" tab active
    } else if (newValue === 1) { // "HISTORY" tab clicked
      setActiveTab(1); // Keep "HISTORY" tab active
      if (setShowHistory) {
        setShowHistory(); // Open chat history modal
      }
    } else {
      setActiveTab(newValue); // Set active tab to the selected one
      if (newValue !== 2 && setBotRibbonVisible) {
        setBotRibbonVisible(false); // Hide bot ribbon if not on "BOTS" tab
      }
      if (setShowPluginSidebar) {
        setShowPluginSidebar(false); // Close plugin sidebar if not on relevant tab
      }
    }
  };

  const toggleAppBar = () => {
    setAppBarVisible(!appBarVisible); // Toggle AppBar visibility
  };

  return (
    <HitchBox position="relative">
      
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ gap: "16px" }}
        >
          <Grid item xs={12} md={12} lg={12} xl={12} xxl={12}>
            {appBarVisible && ( // Conditionally render AppBar
              <AppBar position="static" sx={{ minHeight: '48px' }}> {/* Set AppBar height */}
                <Tabs
                  orientation={tabsOrientation}
                  value={activeTab}
                  onChange={handleSetTabValue}
                  sx={{ background: "transparent", display: "flex", justifyContent: "center" }}
                >
                  <Tab 
                    onClick={toggleAppBar}
                    icon={<IoMdRemove size="16px" />}
                  />
                 
                  <Tab
                    label="CHAT"
                    icon={<IoMdCube size="16px" color="white" fontWeight="bold" />}
                    value={0}
                  />
                  <Tab
                    label="HISTORY"
                    icon={<IoDocument size="16px" color="white" fontWeight="bold" />}
                    value={1}
                  />
                  <Tab
                    label="BOTS"
                    icon={<IoBuild size="16px" color="white" fontWeight="bold" />}
                    value={2}
                  />
                </Tabs>
              </AppBar>
            )}
           {!appBarVisible && (
              <IoMdAdd size="24px" color="white" onClick={toggleAppBar} />
           )}
          
          </Grid>
        </Grid>
      
    </HitchBox>
  );
}

export default ChatHeader;