import { useEffect, useState } from "react";
import { DialogTitle, DialogContent, DialogActions, TextField, Divider, List, ListItem, ListItemText } from "@mui/material";
import HitchButton from "components/HitchButton";
import VuiDropzone from "components/VuiDropzone";
import HitchDialog from "components/HitchDialog";
import HitchTempFileUpload from "components/HitchTempFileUpload";
import HitchDisplayField from "components/HitchDisplayField";
import { useHubConnections } from "context/HubConnectionsProvider";

function UploadEvidenceModal({ headTaskId, open, onClose, onSave }) {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [relatedTasks, setRelatedTasks] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();

    const handleFileUpload = async (fileData, file) => {
        console.log('fileData', fileData);
        console.log('file', file);

        if (file && fileData && file.accepted) {



            hitchDashCommand('sos', 'task-evidence', 'intake', {
                headTaskId: headTaskId,
                contentType: fileData.contentType,
                tempFileId: fileData.tempId,
                fileName: file.upload.filename
            });

            const mockResponse = {
                repositoryId: "<TEMPORARY>",
                fileId: fileData?.tempId,
                fileName: file.upload.filename,
                contentType: fileData?.contentType,
                status: "pending",
                comments: "***PENDING***",
                tasks: [
                    
                ]
            };


            setUploadedFile(mockResponse);
        }
    };

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("785fdab1-9cc3-40b2-a1ae-b56a9559facd", evidenceReceived);
            // LoadThePage();
            return () => {
                dashboardConnection.off("785fdab1-9cc3-40b2-a1ae-b56a9559facd", evidenceReceived);
            }
        }

    }, [dashboardConnection, isConnectionReady]);

    function evidenceReceived(data) {
        console.log('', data);

        setUploadedFile(data);
    }

    useEffect(() => {
        if (uploadedFile) {
            console.log('uploadedFile', uploadedFile);
        }
    }, [uploadedFile]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);

        // Mock search results
        setSearchResults(["Search Result 1", "Search Result 2"]);
    };

    const handleAttachTask = (task) => {
        if (!relatedTasks.includes(task)) {
            setRelatedTasks([...relatedTasks, task]);
        }
    };

    const handleRemoveEvidence = () => {
        setUploadedFile(null);
        // setSummary("");
        // setRelatedTasks([]);
        // setUploadStatus("");
        // setComments("");
    };

    const handleSave = () => {
        console.log('Stub: Save action triggered...'); // Stub for save action
        // Implement save logic here
    };

    const handleAbort = () => {
        console.log('Stub: Abort action triggered...'); // Stub for abort action
        // Implement abort logic here
    };

    return (
        <HitchDialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Submit Evidence</DialogTitle>
            <DialogContent>
                {!uploadedFile && (
                    <HitchTempFileUpload label="Upload File" onFileUploaded={handleFileUpload} />
                )}

                {uploadedFile && uploadedFile.fileId && (
                    <>
                        <HitchDisplayField label="Repository" value={uploadedFile.repositoryId} />
                        <HitchDisplayField label="File Identifier" value={uploadedFile.fileId} />
                        <HitchDisplayField label="File Name" value={uploadedFile.fileName} />
                        <HitchDisplayField label="Content Type" value={uploadedFile.contentType} />
                        <HitchDisplayField label="Status" value={uploadedFile.status} />

                        <Divider sx={{ my: 2 }} />

                        {uploadedFile.comments && (
                            <HitchDisplayField label="Comments" value={uploadedFile?.comments} />
                        )}


                        <HitchButton color="error" onClick={handleRemoveEvidence}>Remove</HitchButton>
                    </>
                )}

                <TextField
                    fullWidth
                    placeholder="Search for tasks to attach"
                    value={searchTerm}
                    onChange={handleSearch}
                    margin="normal"
                />
                <List>
                    {searchResults.map((result, index) => (
                        <ListItem button key={index} onClick={() => handleAttachTask(result)}>
                            <ListItemText primary={result} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <HitchButton color="white" variant="outlined" onClick={onClose}>Cancel</HitchButton>
                <HitchButton color="primary" onClick={handleSave}>Save</HitchButton>
                <HitchButton color="error" onClick={handleAbort}>Abort</HitchButton>
            </DialogActions>
        </HitchDialog>
    );
}

export default UploadEvidenceModal;