import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function SosVendorChat() {
    const navigate = useNavigate();
    const [chatHistory, setChatHistory] = useState([]);
    const [message, setMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const handleSendMessage = () => {
        if (message.trim()) {
            setChatHistory([...chatHistory, { sender: 'User', text: message }]);
            setMessage('');
            // Simulated AI response
            setTimeout(() => {
                setChatHistory(prevHistory => [...prevHistory, { sender: 'AI', text: `Searching vendor policies for: "${message}"...` }]);
            }, 1000);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Vendor Chat AI
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search Vendor Policies"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <div style={{ border: '1px solid #ccc', padding: '10px', minHeight: '200px', overflowY: 'auto' }}>
                                    {chatHistory.map((msg, index) => (
                                        <div key={index} style={{ textAlign: msg.sender === 'User' ? 'right' : 'left' }}>
                                            <b>{msg.sender}:</b> {msg.text}
                                        </div>
                                    ))}
                                </div>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Type your message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                />
                                <HitchButton variant="outlined" color="white" onClick={handleSendMessage}>Send</HitchButton>
                            </div>
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosVendorChat;
