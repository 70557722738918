import React, { useState } from 'react';
import Card from "@mui/material/Card";
import whiteCurved from "assets/images/animated-card-background.png";
import VuiBadge from "components/VuiBadge";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tilt } from 'react-tilt';
import { Grid } from '@mui/material';
import PieChartWidget from 'components/HitchWidget/PieChartWidget';

function StatsCard({ color = "black", title, count, max, percentage, action }) {
    const [selectFramework, setSelectFramework] = useState(false);
    const sharedStyles = {
        transform: `translateZ(50px) scale(0.8)`,
        transition: "all 500ms linear",
    };

    const actionStyles = {
        my: 1,
        width: ({ functions: { pxToRem } }) => pxToRem(160),
        ...sharedStyles,

        "&:hover, &:focus": {
            transform: `translateZ(50px) scale(.9)`,
        },
    };

    // Calculate percentage if max is provided
    const percentageComplete = max !== null ? ((count / max) * 100).toFixed(2) : null;

    return (
        <Tilt
            options={{
                reverse: false,
                max: 15,
                perspective: 1000,
                scale: 1,
                speed: 300,
                transition: true,
                axis: null,
                reset: true,
                easing: "cubic-bezier(0.03,0.98,0.52,0.99)",
            }}
            style={{ transformStyle: "preserve-3d" }}
        >
            <Card
                sx={{
                    backgroundImage: `url(${whiteCurved})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "visible",
                    minHeight: "315px",
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PieChartWidget
                            chartData={[
                                { value: count, label: 'Complete', color: '#00AAAA' },
                                { value: (max - count), label: 'Incomplete', color: '#111111' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <HitchBox display="flex" p={2} flexDirection="column" alignItems="center">
                            <HitchBox mt={1}>
                                {!selectFramework && (
                                    <HitchTypography
                                        fontSize={22}
                                        color="white"
                                        textTransform="capitalize"
                                        fontWeight="medium"
                                        
                                        sx={{
                                            mt: 1,
                                            ...sharedStyles,
                                        }}
                                    >
                                        {title}
                                    </HitchTypography>
                                )}
                            </HitchBox>
                            <HitchTypography variant="h1" fontWeight="bold" color="white" sx={sharedStyles}>
                                {count} {max !== null ? ` / ${max}` : ''}
                            </HitchTypography>
                            {/* Display percentage if max is provided */}
                            {max && percentageComplete !== null && (
                                <HitchTypography variant="h3" fontWeight="medium" color="white" sx={sharedStyles}>
                                    {`Percentage Complete: ${percentageComplete}%`}
                                </HitchTypography>
                            )}
                            <HitchBox sx={sharedStyles}>
                                <VuiBadge
                                    color={percentage.color}
                                    badgeContent={<>&nbsp;{percentage.label}&nbsp;</>}
                                    size="lg"
                                    container
                                />
                            </HitchBox>
                            {action.type === "internal" ? (
                                <HitchButton
                                    component={Link}
                                    to={action.route}
                                    variant="contained"
                                    color="primary"
                                    sx={actionStyles}
                                >
                                    {action.label}
                                </HitchButton>
                            ) : (
                                <HitchButton
                                    component="a"
                                    href={action.route}
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    sx={actionStyles}
                                >
                                    {action.label}
                                </HitchButton>
                            )}
                        </HitchBox>
                    </Grid>
                </Grid>
            </Card>
        </Tilt>
    );
}

// Typechecking props for the StatsCard
StatsCard.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    max: PropTypes.number,
    percentage: PropTypes.shape({
        color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"])
            .isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
    action: PropTypes.shape({
        type: PropTypes.oneOf(["external", "internal"]).isRequired,
        route: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

export default StatsCard;