

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/deployment/index.js

import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useAdminHubConnections } from "context/HubAdminConnectionProvider";
import { useEffect, useState } from "react";

function Deployments() {

    const cols = [
        { accessor: 'id', Header: 'ID', width: '25%' },
        { accessor: 'name', Header: 'Organization Name' },
        { accessor: 'subscriptionId', Header: 'Subscription Id', width: '25%' }
    ];

    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        adminConnection,
        hitchUser,
        isConnectionReady
    } = useAdminHubConnections();

    const {
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack,
    } = useHubConnections();

    useEffect(() => {
        if (adminConnection) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');

                adminConnection.on('Accounts', (data) => {
                    setLoading(true);
                    setList(data);
                });

                adminConnection.on('Account', (data) => {
                    const editingModel = data;
                    setSelectedOrganizations((prevOrganizations) => [...prevOrganizations, editingModel]);
                });

                // Add other event subscriptions here
            };

            subscribeToEvents();

            adminConnection.invoke("GetAccounts", hitchUser?.userId, searchTerm)
                .then(() => {
                    console.log('getting accounts');
                    setLoading(true);
                })
                .catch(error => console.error("Error sending message:", error));

            return () => {
                adminConnection.off('Accounts');
                adminConnection.off('Account');
                // Unsubscribe from other events here
            };
        }
    }, [adminConnection]);


    useEffect(() => {
        if(list) {
            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI Deployments (WILL GO HERE, THIS IS A SCAFFOLD TABLE PAGE)
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Managing Accounts on the Platform.
                            </HitchTypography>
                        </HitchBox>
                        <DataTable table={dataTableData} />
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default Deployments;