
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/administration/user/UserDetail.js

import { Grid, TextField } from "@mui/material";
import VuiBadge from "components/VuiBadge";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import { Form, Formik } from "formik";
import HitchImageUploader from "hitch/image-uploader";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useEffect, useState } from "react";
import {isUserSignedIn } from "services/authService";
import { useNavigate } from "react-router-dom";

function UserDetail({user}) {
    const { hitchUser, account, dashboardConnection, hitchDashCommand, isConnectionReady, errorSnack, infoSnack, successSnack } = useHubConnections();
    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();

    function cancelEdit() {
        setEditMode(false);
    }



    return (
        <Grid container spacing={2} alignItems="center">
            {!editMode ? (
                <>
                    {/* Read Mode */}
                    <Grid item xs={12} md={7}>
                        <HitchBox
                            display="flex"
                            alignItems="center"
                            sx={({ breakpoints }) => ({
                                flexDirection: "column",
                                [breakpoints.up("md")]: {
                                    flexDirection: "row",
                                },
                            })}
                        >
                            <HitchBox mr={2} mb={{ xs: "18px", md: "0px" }}>
                                <HitchImageUploader guid={user?.id} />
                            </HitchBox>
                            <HitchBox
                                lineHeight={1}
                                display="flex"
                                sx={({ breakpoints }) => ({
                                    flexDirection: "column",
                                    alignItems: "center",
                                    [breakpoints.up("md")]: {
                                        flexDirection: "column",
                                        alignItems: "start",
                                    },
                                })}
                            >
                                {user?.firstName && (
                                    <HitchTypography variant="md" fontWeight="medium" color="white">
                                        {user.firstName} {user.lastName}
                                    </HitchTypography>
                                )}
                                {user?.email && (
                                    <HitchTypography variant="button" fontWeight="regular" color="text">
                                        {user.email}
                                    </HitchTypography>
                                )}
                                {user?.defaultPhoneNumber && (
                                    <HitchTypography variant="button" fontWeight="regular" color="text">
                                        {user.defaultPhoneNumber}
                                    </HitchTypography>
                                )}
                                
                                <VuiBadge
                                    variant="contained"
                                    color="success"
                                    size="md"
                                    badgeContent="subscribed"
                                    container
                                />
                            </HitchBox>
                        </HitchBox>
                    </Grid>

                    {/* Buttons in Read Mode */}
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        
                        <HitchButton 
                            variant="outlined" 
                            color="white"                 
                            sx={({ palette: { white } }) => ({
                                border: `1px solid ${white.main}`,
                                color: white.main,
                            })} 
                            onClick={() => setEditMode(true)}>
                            edit
                        </HitchButton>
                        <HitchBox mt={0.5} ml={2}>
                            <HitchButton color="white">
                                Open Support Ticket
                            </HitchButton>
                        </HitchBox>
                    </Grid>
                </>
            ) : (
                <>
                    {/* Edit Mode */}
                    <Formik
                        initialValues={{
                            firstName: user?.firstName || "",
                            lastName: user?.lastName || "",
                            email: user?.email || "",
                            defaultPhoneNumber: user?.defaultPhoneNumber || "",
                        }}
                        onSubmit={(values) => saveToService(values)}
                    >
                        <Form style={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7}>
                                    <HitchBox
                                        display="flex"
                                        alignItems="center"
                                        sx={({ breakpoints }) => ({
                                            flexDirection: "column",
                                            [breakpoints.up("md")]: {
                                                flexDirection: "row",
                                            },
                                        })}
                                    >
                                        <HitchBox mr={2} mb={{ xs: "18px", md: "0px" }}>
                                            <HitchImageUploader guid={user?.id} />
                                        </HitchBox>
                                        <HitchBox
                                            lineHeight={1}
                                            display="flex"
                                            sx={({ breakpoints }) => ({
                                                flexDirection: "column",
                                                alignItems: "center",
                                                [breakpoints.up("md")]: {
                                                    flexDirection: "column",
                                                    alignItems: "start",
                                                },
                                            })}
                                        >
                                            <FormField
                                                name="firstName"
                                                label="First Name"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="lastName"
                                                label="Last Name"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="email"
                                                label="Email Address"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="defaultPhoneNumber"
                                                label="Phone Number"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </HitchBox>
                                    </HitchBox>
                                </Grid>

                                {/* Buttons in Edit Mode */}
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <HitchButton 
                                        type="submit" 
                                        variant="outlined" 
                                        color="white" 
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })} >
                                        Save
                                    </HitchButton>
                                    <HitchButton 
                                        variant="outlined" 
                                        color="white"                 
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })} 
                                        onClick={cancelEdit}>
                                        cancel
                                    </HitchButton>
                                    <HitchBox mt={0.5} ml={2}>
                                        <HitchButton>
                                            Open Support Ticket
                                        </HitchButton>
                                    </HitchBox>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </>
            )}
        </Grid>
    );
}

export default UserDetail;