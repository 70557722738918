
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HitchButton from "components/HitchButton";
import MonacoEditor from "@monaco-editor/react";
import VuiInput from "components/VuiInput";

function HitchExpressionEditor({ property, onUpdate, onCancel }) {
    const [expression, setExpression] = useState(property?.expression || "");
    const [name, setName] = useState(property?.name || "");
    const [loading, setLoading] = useState(false); // Suggestion: Add loading state for better feedback

    const handleTest = async () => {
        // Test expression by sending it to the server or evaluating locally
        const url = `${window._env_.HITCH_API}/api/testExpression`;
        setLoading(true); // Start loading before the API call
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ expression }),
            });
            const result = await response.json();
            alert(`Expression test successful: ${JSON.stringify(result)}`);
        } catch (error) {
            alert(`Expression test failed: ${error.message}`);
        } finally {
            setLoading(false); // End loading after the API call
        }
    };

    return (
        <Modal open={true} onClose={onCancel}>
            <Box sx={{ p: 4, bgcolor: "white", borderRadius: 4, maxWidth: 800, mx: "auto", mt: 4 }}>
                <Box mb={2}>
                    <h3 style={{ margin: 0 }}>Edit Property</h3>
                </Box>
                {/* Add a text field for the property name */}
                <VuiInput
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Property Name"
                    style={{ width: "100%", marginBottom: "10px", padding: "8px" }}
                />
                <Box mb={2}>
                    <h3 style={{ margin: 0 }}>Edit Expression</h3>
                </Box>
                <MonacoEditor
                    height="300px"
                    defaultLanguage="javascript"
                    value={expression}
                    onChange={setExpression}
                />
                <Box mt={2} display="flex" justifyContent="space-between">
                    <HitchButton
                        onClick={() => onUpdate(name, expression)}
                        color="success"
                        variant="contained"
                        disabled={loading} // Disable button while loading
                    >
                        Update
                    </HitchButton>

                    <HitchButton
                        onClick={handleTest}
                        color="info"
                        variant="contained"
                        disabled={loading} // Suggestion: Disable buttons while loading
                    >
                        {loading ? "Testing..." : "Test"} {/* Suggestion: Show loading feedback */}
                    </HitchButton>
                    <HitchButton
                        onClick={onCancel}
                        color="error"
                        variant="contained"
                        disabled={loading} // Suggestion: Disable cancel during loading for consistency
                    >
                        Cancel
                    </HitchButton>
                </Box>
            </Box>
        </Modal>
    );
}

export default HitchExpressionEditor;
