import { Drawer } from "@mui/material";
import TreeNode from "./TreeNode";
import { useNavigate } from "react-router-dom";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";

function SosTaskTree({data, treeOpen, setTreeOpen}) {

    const navigate = useNavigate();
    const[data2, setData2] = useState([]);
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();

    useEffect(() => {
            if (dashboardConnection && isConnectionReady && data && data.id) {
                const fetchTask = () => {
                    hitchDashCommand("sos", "task", "top-parent", {
                        taskId: data.id,
                    });
                };
    
                // Fetch the task when the component mounts or when taskId changes
                fetchTask();
    
                dashboardConnection.on("f0edf3cd-6f1b-4bc1-a10f-03ce4254e2f5", setData2);
    
                return () => {
                    dashboardConnection.off("f0edf3cd-6f1b-4bc1-a10f-03ce4254e2f5", setData2);
                };
            }
        }, [dashboardConnection, isConnectionReady, data]); // Listen for taskId changes

    function onNodeClick(data) {
        if(data && data.parentId) {
            navigate(`/third/sos/tasks/${data.id}?parentId=${data.parentId}`);
            return;
        }
        navigate(`/third/sos/tasks/${data.id}`);
    }

    useEffect(() => {
        if(data2) {
            console.log('data2', data2);
        }
    }, [data2]);


    return (
        <Drawer 
            open={treeOpen} 
            onClose={() => setTreeOpen(false)}
            sx={{
                '& .MuiDrawer-paper': {
                    width: {
                        xs: '100%', // 100% width on small screens
                        sm: '900px', // 400px on medium and larger screens
                    },
                },
            }}
        >
            <TreeNode node={data2} onNodeClick={onNodeClick} />
        </Drawer>
    );
}

export default SosTaskTree;