
import { Card } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function AccountRoles() {
    const { accountId } = useParams();
    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();
    const [roleList, setRoleList] = useState([]);


    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("f1483793-2e84-4771-8ec5-7bb827d9d264", (data) => {
                    console.log('f1483793-2e84-4771-8ec5-7bb827d9d264', data);
                    setRoleList(data);
                });
            };

            subscribeToEvents();
            hitchDashCommand('admin', 'account-role', 'list', { theAccountId: accountId });

            return () => {
                dashboardConnection.off('f1483793-2e84-4771-8ec5-7bb827d9d264');
            };
        }
    }, [dashboardConnection, accountId]);

    const cols = [
        { accessor: 'name', Header: 'Connection Name', width: '50%' }
    ];

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    useEffect(() => {
        if (roleList) {
            setDataTableData((prevState) => ({
                ...prevState,
                rows: roleList
            }));
        }
    }, [roleList]);

    function handleRowClick(obj) {
        console.log('Row clicked:', obj);
    }

    return (
        <Card
            sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                overflow: "visible",
                background: linearGradient(
                    gradients.cardDark.main,
                    gradients.cardDark.state,
                    gradients.cardDark.deg
                ),
            })}
        >
            <HitchBox p={3}>
                <HitchBox mb={5}>
                    <HitchTypography variant="lg" fontWeight="medium" color="white">
                        Organization Roles
                    </HitchTypography>
                </HitchBox>
                <HitchBox mb={2}>
                    <HitchDataTable
                        table={dataTableData}
                        canSearch
                        onRowClick={handleRowClick} />
                </HitchBox>

            </HitchBox>
        </Card>
    );
}

export default AccountRoles;