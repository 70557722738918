
import { Card, Chip } from "@mui/material";
import HitchBadgeList from "components/HitchBadgeList";
import HitchDataTable from "components/HitchDataTable";
import VuiBadge from "components/VuiBadge";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function Nodes() {
    const [list, setList] = useState([]);
    const [images, setImages] = useState([]);
    const [versions, setVersions] = useState([]);
    const [processedList, setProcessedList] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: [
            { accessor: "name", Header: "Name", width: "20%" },
            { accessor: "replicas", Header: "Replicas", width: "20%" },
            {
                accessor: "imageVersion",
                Header: "Version",
                width: "20%",
                Cell: ({ value }) => (
                    <HitchBox>
                        <HitchTypography>{value?.imageName || "Unknown Image"}</HitchTypography>
                        <HitchTypography variant="caption" color="text">
                            {value?.versionName || "Unknown Version"}
                        </HitchTypography>
                    </HitchBox>
                ),
            },
            {
                accessor: "roleList",
                Header: "Roles",
                width: "40%",
                Cell: ({ value }) => <HitchBadgeList value={value} />,
            },
        ],
        rows: [],
    });


    const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();
    const navigate = useNavigate();

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    // Fetch initial data
    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            console.log('GO GO GO!');
            const subscribeToEvents = () => {
                dashboardConnection.on("2b228782-740e-4ca4-83a9-d141e82363b0", theList);
                dashboardConnection.on("4aae7b7f-7b6f-4b83-96bc-38f211404929", setImages);
                dashboardConnection.on("afedcf64-7554-44d1-bc0c-fad33eb1f5b5", (e) => {
                    if (e && Array.isArray(e)) {
                        addVersions(e);
                    }
                });

                hitchDashCommand("admin", "image", "list", {});
                hitchDashCommand("admin", "node", "list", {});
                hitchDashCommand("admin", "image-version", "latest-versions", {});
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("2b228782-740e-4ca4-83a9-d141e82363b0", theList);
                dashboardConnection.off("4aae7b7f-7b6f-4b83-96bc-38f211404929", setImages);
                dashboardConnection.off("afedcf64-7554-44d1-bc0c-fad33eb1f5b5");
            };
        }
    }, [dashboardConnection, isConnectionReady]);

    function theList(data) {
        console.log('theList', data);
        setList(data);
    }

    useEffect(() => {
        const updatedList = list.map((node) => {
            const image = images.find((img) => img.id === node.imageId);
            const version = versions?.find((ver) => ver.id === node.imageVersionId);

            return {
                ...node,
                imageVersion: {
                    imageName: image?.displayName,
                    versionName: version?.name,
                },
            };
        });

        setProcessedList(updatedList);
    }, [list, images, versions]);

    // Update the data table rows
    useEffect(() => {
        setDataTableData((prevState) => ({
            ...prevState,
            rows: processedList,
        }));
    }, [processedList]);

    function newNode() {
        navigate(`./${uuidv4()}`);
    }

    function addVersions(newVersions) {
        setVersions((prevVersions) => {
            const merged = [...prevVersions];
            newVersions.forEach((newVersion) => {
                if (!merged.some((v) => v.id === newVersion.id)) {
                    merged.push(newVersion);
                }
            });
            return merged;
        });
    }

    useEffect(() => {
        if (images.length > 0) {
            reloadTheVersions();
        }
    }, [images]);

    function reloadTheVersions() {
        if (images) {
            images.forEach((image) => {
                hitchDashCommand("admin", "image-version", "list", { imageId: image.id });
            });
        } else {
            console.error('cannot reload versions when there are no images.');
        }
    }

    function reloadTheImages() {
        hitchDashCommand("admin", "image", "list", {});
        hitchDashCommand("admin", "node", "list", {});
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI Platform Nodes
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Version Control of Environment.
                            </HitchTypography>
                            <HitchTypography>
                                <HitchButton
                                    variant="outlined"
                                    color="white"
                                    onClick={() => newNode()}
                                >
                                    Add A New Node
                                </HitchButton>
                                <HitchButton
                                    variant="outlined"
                                    color="white"
                                    onClick={() => reloadTheImages()}>
                                    Reload Images
                                </HitchButton>
                                <HitchButton
                                    variant="outlined"
                                    color="white"
                                    onClick={() => reloadTheVersions()}>
                                    Reload Versions
                                </HitchButton>
                            </HitchTypography>
                        </HitchBox>
                        <HitchDataTable
                            table={dataTableData}
                            canSearch
                            onRowClick={handleRowClick}
                        />
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default Nodes;
