
// FILE: /UI/hitch-ai-ui-2/src/examples/Cards/ProjectCards/ComplexProjectCard/index.js

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiAvatar from "components/VuiAvatar";

// Custom styles for ComplexProjectCard
function ComplexProjectCard(
  { 
    color = "dark", 
    icon, 
    title, 
    dateTime = "", 
    description, 
    members = [], 
    dropdown = false 
  }
) {
  const renderMembers = members.map((member, key) => {
    const memberKey = `member-${key}`;

    return (
      <VuiAvatar
        key={memberKey}
        src={member}
        alt="member profile"
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          cursor: "pointer",
          position: "relative",

          "&:not(:first-of-type)": {
            ml: -1.25,
          },

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    );
  });

  return (
    <Card sx={{ minHeight: "100%" }}>
      <HitchBox>
        <HitchBox display="flex" alignItems="center">
          <VuiAvatar alt={title} size="xl" variant="rounded" bgColor={color} sx={{ p: 1 }}>
            {icon}
          </VuiAvatar>
          <HitchBox ml={2} lineHeight={0}>
            <HitchBox mb={1} lineHeight={0}>
              <HitchTypography
                variant="h6"
                color="white"
                textTransform="capitalize"
                fontWeight="medium"
              >
                {title}
              </HitchTypography>
            </HitchBox>
            {members.length > -1 ? <HitchBox display="flex">{renderMembers}</HitchBox> : null}
          </HitchBox>
          {dropdown && (
            <HitchTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer", color: "#fff" }}>
                more_vert
              </Icon>
            </HitchTypography>
          )}
          {dropdown.menu}
        </HitchBox>
        <HitchBox my={2} lineHeight={1}>
          <HitchTypography variant="button" fontWeight="regular" color="text">
            {description}
          </HitchTypography>
        </HitchBox>
        <Divider light />
        <HitchBox display="flex" justifyContent="space-between" alignItems="center">
          {members.length > -1 ? (
            <HitchBox display="flex" flexDirection="column" lineHeight={0}>
              <HitchTypography variant="button" color="white" fontWeight="medium">
                {members.length}
              </HitchTypography>
              <HitchTypography variant="button" fontWeight="medium" color="text">
                Participants
              </HitchTypography>
            </HitchBox>
          ) : null}
          {dateTime ? (
            <HitchBox display="flex" flexDirection="column" lineHeight={0}>
              <HitchTypography variant="button" color="white" fontWeight="medium">
                {dateTime}
              </HitchTypography>
              <HitchTypography variant="button" fontWeight="medium" color="text">
                Due date
              </HitchTypography>
            </HitchBox>
          ) : null}
        </HitchBox>
      </HitchBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexProjectCard;
