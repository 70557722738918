
import { Card } from "@mui/material";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function AccountBilling() {
    const { accountId } = useParams();
    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();
    const [theAccount, setTheAccount] = useState({});

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                // dashboardConnection.on("0f84a216-e3d0-475c-ac15-70880ba4c0c3", (data) => {
                //     console.log('0f84a216-e3d0-475c-ac15-70880ba4c0c3', data);
                //     setTheAccount(data);
                // });
            };

            subscribeToEvents();
            // hitchDashCommand('admin', 'account', 'get', { theAccountId: accountId });

            return () => {
                // dashboardConnection.off('0f84a216-e3d0-475c-ac15-70880ba4c0c3');
            };
        }
    }, [dashboardConnection, accountId]);


    return (
        <Card
            sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                overflow: "visible",
                background: linearGradient(
                    gradients.cardDark.main,
                    gradients.cardDark.state,
                    gradients.cardDark.deg
                ),
            })}
        >
            <HitchBox p={3}>
                <HitchBox mb={5}>
                    <HitchTypography variant="lg" fontWeight="medium" color="white">
                        Organization Billing
                    </HitchTypography>
                </HitchBox>

            </HitchBox>
        </Card>
    );
}

export default AccountBilling;