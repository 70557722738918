
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchButton from "components/HitchButton";
import HitchDataTable from "components/HitchDataTable";
import GitHubRuleCondition from "../GitHubRuleCondition"; 
import { v4 as uuidv4 } from "uuid";

function GitHubRuleConditions({ conditionList, setConditionList, accountId, ruleId }) {
    const cols = [
        { accessor: "id", Header: "ID", width: "25%" },
        { accessor: "name", Header: "Condition Name", width: "35%" },
        { accessor: "conditionExpression", Header: "Expression", width: "40%" },
    ];

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: [],
    });

    const [currentCondition, setCurrentCondition] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEditCondition = (condition) => {
        setCurrentCondition({ ...condition });
        setIsModalOpen(true);
    };

    const handleAddCondition = () => {
        const newCondition = {
            id: uuidv4(),
            accountId: accountId,
            ruleId: ruleId,
            name: "",
            conditionExpression: "",
        };

        setConditionList((prev) => [...prev, newCondition]);
        setCurrentCondition(newCondition);
        setIsModalOpen(true);
    };

    const handleSaveCondition = (updatedCondition) => {
        setConditionList((prevConditions) =>
            prevConditions.map((cond) =>
                cond.id === updatedCondition.id ? updatedCondition : cond
            )
        );
        console.log("Updated conditionList:", conditionList);
        setIsModalOpen(false);
    };


    useEffect(() => {
        setDataTableData((prevState) => ({
            ...prevState,
            rows: conditionList,
        }));
    }, [conditionList]);

    return (
        <HitchBox mb={3}>
            <Card>
                <HitchBox p={3}>
                    <HitchTypography variant="h6" color="white">
                        Conditions
                    </HitchTypography>
                    <HitchButton onClick={handleAddCondition} variant="contained" color="info">
                        Add Condition
                    </HitchButton>
                    <HitchDataTable
                        table={dataTableData}
                        canSearch
                        onRowClick={(row) => handleEditCondition(row)}
                    />
                    {isModalOpen && currentCondition && (
                        <GitHubRuleCondition
                            condition={currentCondition}
                            onChange={setCurrentCondition} // Pass down local editing handler
                            onSave={handleSaveCondition} // Pass save handler
                            onClose={() => setIsModalOpen(false)} // Close modal
                        />
                    )}
                </HitchBox>
            </Card>
        </HitchBox>
    );
}

export default GitHubRuleConditions;

