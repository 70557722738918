// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/routes.js

import Default from 'layouts/dashboards/default';
import CRM from 'layouts/dashboards/crm';
import ProfileOverview from 'layouts/pages/profile/profile-overview';
import Teams from 'layouts/pages/profile/teams';
import AllProjects from 'layouts/pages/profile/all-projects';
import Reports from 'layouts/pages/users/reports';
import NewUser from 'layouts/pages/users/new-user';
import Settings from 'layouts/pages/account/settings';
import Billing from 'layouts/pages/account/billing';
import Invoice from 'layouts/pages/account/invoice';
import General from 'layouts/pages/projects/general';
import Timeline from 'layouts/pages/projects/timeline';
import Widgets from 'layouts/pages/widgets';
import Charts from 'layouts/pages/charts';
import Alerts from 'layouts/pages/alerts';
import PricingPage from 'layouts/pages/pricing-page';
import RTL from 'layouts/pages/rtl';
import Kanban from 'layouts/applications/kanban';
import Wizard from 'layouts/applications/wizard';
import DataTables from 'layouts/applications/data-tables';
import Calendar from 'layouts/applications/calendar';
import NewProduct from 'layouts/ecommerce/products/new-product';
import EditProduct from 'layouts/ecommerce/products/edit-product';
import ProductPage from 'layouts/ecommerce/products/product-page';
import OrderList from 'layouts/ecommerce/orders/order-list';
import OrderDetails from 'layouts/ecommerce/orders/order-details';
import SignInBasic from 'layouts/authentication/sign-in/basic';
import SignInCover from 'layouts/authentication/sign-in/cover';
import SignInIllustration from 'layouts/authentication/sign-in/illustration';
import SignUpBasic from 'layouts/authentication/sign-up/basic';
import SignUpCover from 'layouts/authentication/sign-up/cover';
import SignUpIllustration from 'layouts/authentication/sign-up/illustration';

import { IoDocument, IoChatboxOutline, IoAirplaneOutline, IoShieldHalf } from 'react-icons/io5';
import { IoBuild } from 'react-icons/io5';
import { IoDocuments } from 'react-icons/io5';
import { FaShoppingCart } from 'react-icons/fa';
import { IoHome } from 'react-icons/io5';
import SidenavIcon from 'components/SidenavIcon';
import GeneralChat from 'hitch/chat/general';
import ChatRedirect from 'hitch/chat/general/ChatRedirect';
import Login from 'hitch/login';
import Accounts from 'hitch/hitch-admin/accounts';
import Users from 'hitch/hitch-admin/users';
import Bots from 'hitch/bot';
import Deployments from 'hitch/deployment';
import DocumentRepository from 'hitch/plugins/documentRepository';
import Cluster from 'hitch/hitch-admin/cluster';
import Organization from 'hitch/administration/organization';
import User from 'hitch/administration/user';
import ChangeOrg from 'hitch/administration/changeorg';
import UiComponents from 'hitch/hitch-admin/uicomponents';
import Icons from 'hitch/hitch-admin/icons';
import Services from 'hitch/hitch-admin/services';
import Routes from 'hitch/hitch-admin/routes';
import MenuItems from 'hitch/hitch-admin/menu-items';
import H1AC6BD75_1F4C_4A45_97FF_06F7796D49DF from 'third/H1AC6BD75_1F4C_4A45_97FF_06F7796D49DF';
import H1C438B47_05EA_48FD_A91A_577CCB1EE365 from 'third/H1C438B47_05EA_48FD_A91A_577CCB1EE365';
import H3BE7B759_7303_40D8_B988_EC50FD02964B from 'third/H3BE7B759_7303_40D8_B988_EC50FD02964B';
import H4C4AAE13_C697_45AA_A120_78D2C742D6D6 from 'third/H4C4AAE13_C697_45AA_A120_78D2C742D6D6';
import H6A2912C3_9A22_416C_B21B_C73428BC829C from 'third/H6A2912C3_9A22_416C_B21B_C73428BC829C';
import H6B69B441_87EA_4EF7_A97C_F665622D6E64 from 'third/H6B69B441_87EA_4EF7_A97C_F665622D6E64';
import H6D0A4BB6_DEB9_4A7F_B421_DD2EF0099445 from 'third/H6D0A4BB6_DEB9_4A7F_B421_DD2EF0099445';
import H7B8CC6A9_9280_4550_8B65_63A0AD8B35B2 from 'third/H7B8CC6A9_9280_4550_8B65_63A0AD8B35B2';
import H8D1ADF8D_D55A_4599_8C83_18140FC74E79 from 'third/H8D1ADF8D_D55A_4599_8C83_18140FC74E79';
import H8D65343E_7492_48B9_963C_CD4674575037 from 'third/H8D65343E_7492_48B9_963C_CD4674575037';
import H8FA7B421_AC36_469E_9435_724466F757F8 from 'third/H8FA7B421_AC36_469E_9435_724466F757F8';
import H8FE57EEB_684F_449F_B4F8_C30D433ED226 from 'third/H8FE57EEB_684F_449F_B4F8_C30D433ED226';
import H25A9E00C_3F96_4381_AD48_F1B42924F036 from 'third/H25A9E00C_3F96_4381_AD48_F1B42924F036';
import H26CA2283_DA0A_4B33_9B1D_A31F8B1B39C0 from 'third/H26CA2283_DA0A_4B33_9B1D_A31F8B1B39C0';
import H32D2536E_E847_4E8D_9CC1_587A3FA64088 from 'third/H32D2536E_E847_4E8D_9CC1_587A3FA64088';
import H342FB936_5DE4_4644_9D36_C29C3427430B from 'third/H342FB936_5DE4_4644_9D36_C29C3427430B';
import H897C8DF5_A0BC_4640_A4D7_898DCB3B2912 from 'third/H897C8DF5_A0BC_4640_A4D7_898DCB3B2912';
import H931D43C4_9696_4F9C_AE49_AC5033EB53F1 from 'third/H931D43C4_9696_4F9C_AE49_AC5033EB53F1';
import H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6 from 'third/H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6';
import H4899D10E_569E_4E3B_99B1_71718C8CDB60 from 'third/H4899D10E_569E_4E3B_99B1_71718C8CDB60';
import H8406AA84_31FA_4B27_B9B4_3C3098FB0823 from 'third/H8406AA84_31FA_4B27_B9B4_3C3098FB0823';
import H12624D35_410C_4BA9_99BC_A54AA016EDB6 from 'third/H12624D35_410C_4BA9_99BC_A54AA016EDB6';
import H64070CA9_BE63_4560_A54A_0BE25E69BA1F from 'third/H64070CA9_BE63_4560_A54A_0BE25E69BA1F';
import H609272C7_1AEC_4859_BB46_7B110FE435C2 from 'third/H609272C7_1AEC_4859_BB46_7B110FE435C2';
import H747949B5_4FBC_435E_A6CE_39F7EFB5B25D from 'third/H747949B5_4FBC_435E_A6CE_39F7EFB5B25D';
import H5067710D_D661_4210_A253_42FCBDD8E25F from 'third/H5067710D_D661_4210_A253_42FCBDD8E25F';
import H17416671_228B_426D_97D6_70DF2A41497D from 'third/H17416671_228B_426D_97D6_70DF2A41497D';
import HAD765ED8_5433_41D4_8409_FBD45B5CA60A from 'third/HAD765ED8_5433_41D4_8409_FBD45B5CA60A';
import HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87 from 'third/HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87';
import HB87D7FD6_DB15_464C_94C8_7AE2155FC0A7 from 'third/HB87D7FD6_DB15_464C_94C8_7AE2155FC0A7';
import HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43 from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43';
import HC9302AA7_5B3F_43D3_8B1D_085CD9EAF310 from 'third/HC9302AA7_5B3F_43D3_8B1D_085CD9EAF310';
import HD8BA1A8E_8733_47BB_A932_62F86E459F66 from 'third/HD8BA1A8E_8733_47BB_A932_62F86E459F66';
import HDCC44CE8_CFE8_43FC_AB72_2EACDE0C97B3 from 'third/HDCC44CE8_CFE8_43FC_AB72_2EACDE0C97B3';
import HE59D1BA9_5B71_4F5A_9205_9CE82CBF2DFC from 'third/HE59D1BA9_5B71_4F5A_9205_9CE82CBF2DFC';
import HE0360D10_8FA4_46E8_AEC9_63A8CBFF65C6 from 'third/HE0360D10_8FA4_46E8_AEC9_63A8CBFF65C6';
import HE705C3CE_510B_49C4_9A82_C99CD8F045C5 from 'third/HE705C3CE_510B_49C4_9A82_C99CD8F045C5';
import HEA87C0BE_6CEB_44D6_A04A_F95B3B8B5E03 from 'third/HEA87C0BE_6CEB_44D6_A04A_F95B3B8B5E03';
import HEAB48696_F75F_4482_BFEA_E25D786E9376 from 'third/HEAB48696_F75F_4482_BFEA_E25D786E9376';
import HED099C5D_B5A8_4C09_8E72_C9E7EBC640FC from 'third/HED099C5D_B5A8_4C09_8E72_C9E7EBC640FC';
import HF27C5991_E4C2_4331_B2C1_D50E479E6338 from 'third/HF27C5991_E4C2_4331_B2C1_D50E479E6338';
import HFFFFFFFF_FFFF_FFFF_FFFF_FFFFFFFFFFFF from 'third/HFFFFFFFF_FFFF_FFFF_FFFF_FFFFFFFFFFFF';
import GitHubRepositories from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories';
import GitHubIssues from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Issues';
import GitHubLabels from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Labels';
import H269A23C5_AB35_4F6C_9ADF_5B1C30285A66 from 'third/H269A23C5_AB35_4F6C_9ADF_5B1C30285A66';
import Documentation from 'third/H269A23C5_AB35_4F6C_9ADF_5B1C30285A66/Documentation';
import WebHooks from 'hitch/hitch-admin/webhooks';
import Demo from 'third/HD8BA1A8E_8733_47BB_A932_62F86E459F66/Demo';
import GitHubWebhook from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Webhook';
import GitHubRules from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Webhook/GitHubRules';
import Nodes from 'hitch/hitch-admin/nodes';
import { components } from 'react-select/dist/react-select.cjs.prod';
import H4FED2823_7FDB_41F8_ADAD_A562F4A2E709 from 'third/H4FED2823_7FDB_41F8_ADAD_A562F4A2E709';
import SosIntegration from 'third/sos/integration';
import SosPolicies from 'third/sos/policies';
import SosReporting from 'third/sos/reporting';
import SosRiskManagement from 'third/sos/risk-management';
import SosTraining from 'third/sos/training';
import SosWorkflow from 'third/sos/workflow';
import SosIntegrationRequirements from 'third/sos/integration/requirements';
import SosPoliciesRequirements from 'third/sos/policies/requirements';
import SosWorkflowRequirements from 'third/sos/workflow/requirements';
import SosTrainingRequirements from 'third/sos/training/requirements';
import SosRiskManagementRequirements from 'third/sos/risk-management/requirements';
import SosReportingRequirements from 'third/sos/reporting/requirements';
import GoogleDrive from 'third/HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87/drive';
import Connections from 'hitch/administration/connections';
import SosTasks from 'third/sos/tasks';
import HitchDef from 'hitch/administration/hitchdef';
import SosDashboard from 'third/sos/dashboard';
import SosEvidence from 'third/sos/evidence';
import HitchAdminDashboard from 'hitch/hitch-admin/dashboard';
import ADns from 'hitch/hitch-admin/adns';
import SosVulnerabilities from 'third/sos/vulnerability';
import SosTags from 'third/sos/tags';
import SosAccess from 'third/sos/access';
import SosContractors from 'third/sos/contractors';
import SosEmployees from 'third/sos/employees';
import SosAssets from 'third/sos/assets';
import SosDiscovery from 'third/sos/discovery';
import SosVendorChat from 'third/sos/vendors/chat';
import SosVendorPolicies from 'third/sos/vendors/policies';
import SosVendorOutboundQuestionnaire from 'third/sos/vendors/questionnaire/outbound';
import SosVendorInboundQuestionnaire from 'third/sos/vendors/questionnaire/inbound';
import SosVendors from 'third/sos/vendors';
import SosAudits from 'third/sos/audits';
import SosEvents from 'third/sos/events';
import SosWhistleBlowers from 'third/sos/whistle-blower';


const routes = [
  { type: 'title', title: 'Hitch.AI', key: 'title-hitch-ai' },
  {
    type: 'collapse',
    name: 'Chat System',
    key: 'chat-system',
    icon: <IoChatboxOutline size="15px" color="inherit" />,
    collapse: [
      {
        name: 'Chat Room',
        key: 'chat-room',
        collapse: [
          {
            name: 'General Chat',
            key: 'general-chat',
            layout: '/admin',
            path: '/chat/general', // This redirects to GUID path
            component: <ChatRedirect />,
          },
          {
            name: 'Account Chat',
            key: 'account-chat',
            layout: '/admin',
            path: '/chat/account',
            component: <Default />, // Replace with your actual component
          },
          {
            name: 'Admin Chat',
            key: 'admin-chat',
            layout: '/admin',
            path: '/chat/admin',
            component: <Default />, // Replace with your actual component
          },
        ],
      },
      {
        name: 'Deployment',
        key: 'deployment',
        collapse: [
          {
            name: 'My Bots',
            key: 'my-bots',
            layout: '/admin',
            path: '/deployment/my-bots',
            component: <Bots />,
          },
          {
            name: 'My Sites',
            key: 'my-sites',
            layout: '/admin',
            path: '/deployment/my-sites',
            component: <Deployments />, // Replace with your actual component
          },
        ],
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Integrations',
    key: 'integrations',
    icon: <IoDocument size="15px" color="inherit" />,
    collapse: [
      {
        name: 'Hitch Plugins',
        key: 'hitch-plugins',
        collapse: [
          {
            name: 'Document Repository',
            key: 'document-repository',
            layout: '/admin',
            path: '/integrations/hitch/document-repository',
            component: <DocumentRepository />,
          },
          {
            name: 'Scheduling',
            key: 'scheduling',
            layout: '/admin',
            path: '/integrations/hitch/scheduling',
            component: <Default />, // Replace with your actual component
          },
          {
            name: 'Email Attendant',
            key: 'email-attendant',
            layout: '/admin',
            path: '/integrations/hitch/email-attendant',
            component: <Default />, // Replace with your actual component
          },
        ],
      },
      {
        name: 'Third Party',
        key: 'third-party',
        collapse: [
          {
            name: 'h1ac6bd75_1f4c_4a45_97ff_06f7796d49df',
            key: 'h1ac6bd75_1f4c_4a45_97ff_06f7796d49df',
            layout: '/admin',
            path: '/third/h1ac6bd75_1f4c_4a45_97ff_06f7796d49df',
            component: <H1AC6BD75_1F4C_4A45_97FF_06F7796D49DF />
          },
          {
            name: 'h1c438b47_05ea_48fd_a91a_577ccb1ee365',
            key: 'h1c438b47_05ea_48fd_a91a_577ccb1ee365',
            layout: '/admin',
            path: '/third/h1c438b47_05ea_48fd_a91a_577ccb1ee365',
            component: <H1C438B47_05EA_48FD_A91A_577CCB1EE365 />
          },
          {
            name: 'h3be7b759_7303_40d8_b988_ec50fd02964b',
            key: 'h3be7b759_7303_40d8_b988_ec50fd02964b',
            layout: '/admin',
            path: '/third/h3be7b759_7303_40d8_b988_ec50fd02964b',
            component: <H3BE7B759_7303_40D8_B988_EC50FD02964B />
          },
          {
            name: 'h4c4aae13_c697_45aa_a120_78d2c742d6d6',
            key: 'h4c4aae13_c697_45aa_a120_78d2c742d6d6',
            layout: '/admin',
            path: '/third/h4c4aae13_c697_45aa_a120_78d2c742d6d6',
            component: <H4C4AAE13_C697_45AA_A120_78D2C742D6D6 />
          },
          {
            name: 'h6a2912c3_9a22_416c_b21b_c73428bc829c',
            key: 'h6a2912c3_9a22_416c_b21b_c73428bc829c',
            layout: '/admin',
            path: '/third/h6a2912c3_9a22_416c_b21b_c73428bc829c',
            component: <H6A2912C3_9A22_416C_B21B_C73428BC829C />
          },
          {
            name: 'h6b69b441_87ea_4ef7_a97c_f665622d6e64',
            key: 'h6b69b441_87ea_4ef7_a97c_f665622d6e64',
            layout: '/admin',
            path: '/third/h6b69b441_87ea_4ef7_a97c_f665622d6e64',
            component: <H6B69B441_87EA_4EF7_A97C_F665622D6E64 />
          },
          {
            name: 'h6d0a4bb6_deb9_4a7f_b421_dd2ef0099445',
            key: 'h6d0a4bb6_deb9_4a7f_b421_dd2ef0099445',
            layout: '/admin',
            path: '/third/h6d0a4bb6_deb9_4a7f_b421_dd2ef0099445',
            component: <H6D0A4BB6_DEB9_4A7F_B421_DD2EF0099445 />
          },
          {
            name: 'h7b8cc6a9_9280_4550_8b65_63a0ad8b35b2',
            key: 'h7b8cc6a9_9280_4550_8b65_63a0ad8b35b2',
            layout: '/admin',
            path: '/third/h7b8cc6a9_9280_4550_8b65_63a0ad8b35b2',
            component: <H7B8CC6A9_9280_4550_8B65_63A0AD8B35B2 />
          },
          {
            name: 'h8d1adf8d_d55a_4599_8c83_18140fc74e79',
            key: 'h8d1adf8d_d55a_4599_8c83_18140fc74e79',
            layout: '/admin',
            path: '/third/h8d1adf8d_d55a_4599_8c83_18140fc74e79',
            component: <H8D1ADF8D_D55A_4599_8C83_18140FC74E79 />
          },
          {
            name: 'h8d65343e_7492_48b9_963c_cd4674575037',
            key: 'h8d65343e_7492_48b9_963c_cd4674575037',
            layout: '/admin',
            path: '/third/h8d65343e_7492_48b9_963c_cd4674575037',
            component: <H8D65343E_7492_48B9_963C_CD4674575037 />
          },
          {
            name: 'h8fa7b421_ac36_469e_9435_724466f757f8',
            key: 'h8fa7b421_ac36_469e_9435_724466f757f8',
            layout: '/admin',
            path: '/third/h8fa7b421_ac36_469e_9435_724466f757f8',
            component: <H8FA7B421_AC36_469E_9435_724466F757F8 />
          },
          {
            name: 'h8fe57eeb_684f_449f_b4f8_c30d433ed226',
            key: 'h8fe57eeb_684f_449f_b4f8_c30d433ed226',
            layout: '/admin',
            path: '/third/h8fe57eeb_684f_449f_b4f8_c30d433ed226',
            component: <H8FE57EEB_684F_449F_B4F8_C30D433ED226 />
          },
          {
            name: 'h25a9e00c_3f96_4381_ad48_f1b42924f036',
            key: 'h25a9e00c_3f96_4381_ad48_f1b42924f036',
            layout: '/admin',
            path: '/third/h25a9e00c_3f96_4381_ad48_f1b42924f036',
            component: <H25A9E00C_3F96_4381_AD48_F1B42924F036 />
          },
          {
            name: 'h26ca2283_da0a_4b33_9b1d_a31f8b1b39c0',
            key: 'h26ca2283_da0a_4b33_9b1d_a31f8b1b39c0',
            layout: '/admin',
            path: '/third/h26ca2283_da0a_4b33_9b1d_a31f8b1b39c0',
            component: <H26CA2283_DA0A_4B33_9B1D_A31F8B1B39C0 />
          },
          {
            name: 'h32d2536e_e847_4e8d_9cc1_587a3fa64088',
            key: 'h32d2536e_e847_4e8d_9cc1_587a3fa64088',
            layout: '/admin',
            path: '/third/h32d2536e_e847_4e8d_9cc1_587a3fa64088',
            component: <H32D2536E_E847_4E8D_9CC1_587A3FA64088 />
          },
          {
            name: 'h342fb936_5de4_4644_9d36_c29c3427430b',
            key: 'h342fb936_5de4_4644_9d36_c29c3427430b',
            layout: '/admin',
            path: '/third/h342fb936_5de4_4644_9d36_c29c3427430b',
            component: <H342FB936_5DE4_4644_9D36_C29C3427430B />
          },
          {
            name: 'h897c8df5_a0bc_4640_a4d7_898dcb3b2912',
            key: 'h897c8df5_a0bc_4640_a4d7_898dcb3b2912',
            layout: '/admin',
            path: '/third/h897c8df5_a0bc_4640_a4d7_898dcb3b2912',
            component: <H897C8DF5_A0BC_4640_A4D7_898DCB3B2912 />
          },
          {
            name: 'h931d43c4_9696_4f9c_ae49_ac5033eb53f1',
            key: 'h931d43c4_9696_4f9c_ae49_ac5033eb53f1',
            layout: '/admin',
            path: '/third/h931d43c4_9696_4f9c_ae49_ac5033eb53f1',
            component: <H931D43C4_9696_4F9C_AE49_AC5033EB53F1 />
          },
          {
            name: 'h1259df13_46d9_4237_a2f1_6c7ae95ac2e6',
            key: 'h1259df13_46d9_4237_a2f1_6c7ae95ac2e6',
            layout: '/admin',
            path: '/third/h1259df13_46d9_4237_a2f1_6c7ae95ac2e6',
            component: <H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6 />
          },
          {
            name: 'h4899d10e_569e_4e3b_99b1_71718c8cdb60',
            key: 'h4899d10e_569e_4e3b_99b1_71718c8cdb60',
            layout: '/admin',
            path: '/third/h4899d10e_569e_4e3b_99b1_71718c8cdb60',
            component: <H4899D10E_569E_4E3B_99B1_71718C8CDB60 />
          },
          {
            name: 'h8406aa84_31fa_4b27_b9b4_3c3098fb0823',
            key: 'h8406aa84_31fa_4b27_b9b4_3c3098fb0823',
            layout: '/admin',
            path: '/third/h8406aa84_31fa_4b27_b9b4_3c3098fb0823',
            component: <H8406AA84_31FA_4B27_B9B4_3C3098FB0823 />
          },
          {
            name: 'h12624d35_410c_4ba9_99bc_a54aa016edb6',
            key: 'h12624d35_410c_4ba9_99bc_a54aa016edb6',
            layout: '/admin',
            path: '/third/h12624d35_410c_4ba9_99bc_a54aa016edb6',
            component: <H12624D35_410C_4BA9_99BC_A54AA016EDB6 />
          },
          {
            name: 'h64070ca9_be63_4560_a54a_0be25e69ba1f',
            key: 'h64070ca9_be63_4560_a54a_0be25e69ba1f',
            layout: '/admin',
            path: '/third/h64070ca9_be63_4560_a54a_0be25e69ba1f',
            component: <H64070CA9_BE63_4560_A54A_0BE25E69BA1F />
          },
          {
            name: 'h609272c7_1aec_4859_bb46_7b110fe435c2',
            key: 'h609272c7_1aec_4859_bb46_7b110fe435c2',
            layout: '/admin',
            path: '/third/h609272c7_1aec_4859_bb46_7b110fe435c2',
            component: <H609272C7_1AEC_4859_BB46_7B110FE435C2 />
          },
          {
            name: 'h747949b5_4fbc_435e_a6ce_39f7efb5b25d',
            key: 'h747949b5_4fbc_435e_a6ce_39f7efb5b25d',
            layout: '/admin',
            path: '/third/h747949b5_4fbc_435e_a6ce_39f7efb5b25d',
            component: <H747949B5_4FBC_435E_A6CE_39F7EFB5B25D />
          },
          {
            name: 'h5067710d_d661_4210_a253_42fcbdd8e25f',
            key: 'h5067710d_d661_4210_a253_42fcbdd8e25f',
            layout: '/admin',
            path: '/third/h5067710d_d661_4210_a253_42fcbdd8e25f',
            component: <H5067710D_D661_4210_A253_42FCBDD8E25F />
          },
          {
            name: 'h17416671_228b_426d_97d6_70df2a41497d',
            key: 'h17416671_228b_426d_97d6_70df2a41497d',
            layout: '/admin',
            path: '/third/h17416671_228b_426d_97d6_70df2a41497d',
            component: <H17416671_228B_426D_97D6_70DF2A41497D />
          },
          {
            name: 'had765ed8_5433_41d4_8409_fbd45b5ca60a',
            key: 'had765ed8_5433_41d4_8409_fbd45b5ca60a',
            layout: '/admin',
            path: '/third/had765ed8_5433_41d4_8409_fbd45b5ca60a',
            component: <HAD765ED8_5433_41D4_8409_FBD45B5CA60A />
          },
          {
            name: 'hb2ac74ea_b70a_4a7d_829e_163c2ee7ac87',
            key: 'hb2ac74ea_b70a_4a7d_829e_163c2ee7ac87',
            layout: '/admin',
            path: '/third/hb2ac74ea_b70a_4a7d_829e_163c2ee7ac87',
            component: <HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87 />
          },
          {
            name: 'GoogleDrive',
            key: 'google-drive',
            layout: '/admin',
            path: '/third/hb2ac74ea_b70a_4a7d_829e_163c2ee7ac87/drive',
            component: <GoogleDrive />
          },

          {
            name: 'h269a23c5_ab35_4f6c_9adf_5b1c30285a66',
            key: 'h269a23c5_ab35_4f6c_9adf_5b1c30285a66',
            layout: '/admin',
            path: '/third/h269a23c5_ab35_4f6c_9adf_5b1c30285a66',
            component: <H269A23C5_AB35_4F6C_9ADF_5B1C30285A66 />
          },
          {
            name: 'Documentation',
            key: 'Documentation',
            layout: '/admin',
            path: '/third/h269a23c5_ab35_4f6c_9adf_5b1c30285a66/Documentation',
            component: <Documentation />
          },
          {
            name: 'hb87d7fd6_db15_464c_94c8_7ae2155fc0a7',
            key: 'hb87d7fd6_db15_464c_94c8_7ae2155fc0a7',
            layout: '/admin',
            path: '/third/hb87d7fd6_db15_464c_94c8_7ae2155fc0a7',
            component: <HB87D7FD6_DB15_464C_94C8_7AE2155FC0A7 />
          },
          {
            name: 'hc5e92791_b9e7_4d7e_ae22_90cce4ec0b43',
            key: 'hc5e92791_b9e7_4d7e_ae22_90cce4ec0b43',
            layout: '/admin',
            path: '/third/hc5e92791_b9e7_4d7e_ae22_90cce4ec0b43',
            component: <HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43 />
          },
          {
            name: 'GitHubRepositories',
            key: 'GitHubRepositories',
            layout: '/admin',
            path: '/third/hc5e92791_b9e7_4d7e_ae22_90cce4ec0b43/GitHubRepositories',
            component: <GitHubRepositories />
          },
          {
            name: 'GitHubIssues',
            key: 'GitHubIssues',
            layout: '/admin',
            path: '/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Issues',
            component: <GitHubIssues />
          },
          {
            name: 'GitHubIssues',
            key: 'GitHubIssues',
            layout: '/admin',
            path: '/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Labels',
            component: <GitHubLabels />
          },
          {
            name: 'GitHubWebhook',
            key: 'GitHubWebhook',
            layout: '/admin',
            path: '/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubWebhook',
            component: <GitHubWebhook />
          },
          {
            name: 'GitHubRules',
            key: 'GitHubRules',
            layout: '/admin',
            path: '/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRules',
            component: <GitHubRules />
          },
          {
            name: 'hc9302aa7_5b3f_43d3_8b1d_085cd9eaf310',
            key: 'hc9302aa7_5b3f_43d3_8b1d_085cd9eaf310',
            layout: '/admin',
            path: '/third/hc9302aa7_5b3f_43d3_8b1d_085cd9eaf310',
            component: <HC9302AA7_5B3F_43D3_8B1D_085CD9EAF310 />
          },
          {
            name: 'Chat Configuration',
            key: 'hd8ba1a8e_8733_47bb_a932_62f86e459f66',
            layout: '/admin',
            path: '/third/hd8ba1a8e_8733_47bb_a932_62f86e459f66',
            component: <HD8BA1A8E_8733_47BB_A932_62F86E459F66 />
          },
          {
            name: 'Chat Demo',
            key: 'demo',
            layout: '/admin',
            path: '/third/HD8BA1A8E_8733_47BB_A932_62F86E459F66/Demo',
            component: <Demo />
          },
          {
            name: 'hdcc44ce8_cfe8_43fc_ab72_2eacde0c97b3',
            key: 'hdcc44ce8_cfe8_43fc_ab72_2eacde0c97b3',
            layout: '/admin',
            path: '/third/hdcc44ce8_cfe8_43fc_ab72_2eacde0c97b3',
            component: <HDCC44CE8_CFE8_43FC_AB72_2EACDE0C97B3 />
          },
          {
            name: 'he59d1ba9_5b71_4f5a_9205_9ce82cbf2dfc',
            key: 'he59d1ba9_5b71_4f5a_9205_9ce82cbf2dfc',
            layout: '/admin',
            path: '/third/he59d1ba9_5b71_4f5a_9205_9ce82cbf2dfc',
            component: <HE59D1BA9_5B71_4F5A_9205_9CE82CBF2DFC />
          },
          {
            name: 'he0360d10_8fa4_46e8_aec9_63a8cbff65c6',
            key: 'he0360d10_8fa4_46e8_aec9_63a8cbff65c6',
            layout: '/admin',
            path: '/third/he0360d10_8fa4_46e8_aec9_63a8cbff65c6',
            component: <HE0360D10_8FA4_46E8_AEC9_63A8CBFF65C6 />
          },
          {
            name: 'he705c3ce_510b_49c4_9a82_c99cd8f045c5',
            key: 'he705c3ce_510b_49c4_9a82_c99cd8f045c5',
            layout: '/admin',
            path: '/third/he705c3ce_510b_49c4_9a82_c99cd8f045c5',
            component: <HE705C3CE_510B_49C4_9A82_C99CD8F045C5 />
          },
          {
            name: 'hea87c0be_6ceb_44d6_a04a_f95b3b8b5e03',
            key: 'hea87c0be_6ceb_44d6_a04a_f95b3b8b5e03',
            layout: '/admin',
            path: '/third/hea87c0be_6ceb_44d6_a04a_f95b3b8b5e03',
            component: <HEA87C0BE_6CEB_44D6_A04A_F95B3B8B5E03 />
          },
          {
            name: 'heab48696_f75f_4482_bfea_e25d786e9376',
            key: 'heab48696_f75f_4482_bfea_e25d786e9376',
            layout: '/admin',
            path: '/third/heab48696_f75f_4482_bfea_e25d786e9376',
            component: <HEAB48696_F75F_4482_BFEA_E25D786E9376 />
          },
          {
            name: 'hed099c5d_b5a8_4c09_8e72_c9e7ebc640fc',
            key: 'hed099c5d_b5a8_4c09_8e72_c9e7ebc640fc',
            layout: '/admin',
            path: '/third/hed099c5d_b5a8_4c09_8e72_c9e7ebc640fc',
            component: <HED099C5D_B5A8_4C09_8E72_C9E7EBC640FC />
          },
          {
            name: 'hf27c5991_e4c2_4331_b2c1_d50e479e6338',
            key: 'hf27c5991_e4c2_4331_b2c1_d50e479e6338',
            layout: '/admin',
            path: '/third/hf27c5991_e4c2_4331_b2c1_d50e479e6338',
            component: <HF27C5991_E4C2_4331_B2C1_D50E479E6338 />
          },
          {
            name: 'hffffffff_ffff_ffff_ffff_ffffffffffff',
            key: 'hffffffff_ffff_ffff_ffff_ffffffffffff',
            layout: '/admin',
            path: '/third/hffffffff_ffff_ffff_ffff_ffffffffffff',
            component: <HFFFFFFFF_FFFF_FFFF_FFFF_FFFFFFFFFFFF />
          },
          {
            name: 'h4fed2823_7fdb_41f8_adad_a562f4a2e709',
            key: 'h4fed2823_7fdb_41f8_adad_a562f4a2e709',
            layout: '/admin',
            path: '/third/h4fed2823_7fdb_41f8_adad_a562f4a2e709',
            component: <H4FED2823_7FDB_41F8_ADAD_A562F4A2E709 />
          },

          {
            name: 'SosIntegration',
            key: 'sos-integration',
            layout: '/admin',
            path: '/third/sos/integration',
            component: <SosIntegration />
          },
          {
            name: 'SosIntegrationRequirements',
            key: 'sos-integration-requirements',
            layout: '/admin',
            path: '/third/sos/integration/requirements',
            component: <SosIntegrationRequirements />
          },
          {
            name: 'SosPolicies',
            key: 'sos-policies',
            layout: '/admin',
            path: '/third/sos/policies',
            component: <SosPolicies />
          },
          // SosEvidence
          {
            name: 'SosEvidence',
            key: 'sos-evidence',
            layout: '/admin',
            path: '/third/sos/evidence',
            component: <SosEvidence />
          },
          {
            name: 'SosPoliciesRequirements',
            key: 'sos-policies-requirements',
            layout: '/admin',
            path: '/third/sos/policies/requirements',
            component: <SosPoliciesRequirements />
          },
          {
            name: 'SosReporting',
            key: 'sos-reporting',
            layout: '/admin',
            path: '/third/sos/reporting',
            component: <SosReporting />
          },
          {
            name: 'SosReportingRequirements',
            key: 'sos-reporting-requirements',
            layout: '/admin',
            path: '/third/sos/reporting/requirements',
            component: <SosReportingRequirements />
          },

          {
            name: 'SosRiskManagement',
            key: 'sos-risk-management',
            layout: '/admin',
            path: '/third/sos/risk-management',
            component: <SosRiskManagement />
          },
          {
            name: 'SosRiskManagementRequirements',
            key: 'sos-risk-management-requirements',
            layout: '/admin',
            path: '/third/sos/risk-management/requirements',
            component: <SosRiskManagementRequirements />
          },
          {
            name: 'SosDashboard',
            key: 'sos-dashboard',
            layout: '/admin',
            path: '/third/sos/dashboard',
            component: <SosDashboard />
          },
          {
            name: 'SosDashboard',
            key: 'sos-dashboard',
            layout: '/admin',
            path: '/third/sos/dashboard/:sessionId',
            component: <SosDashboard />
          },
          {
            name: 'SosTraining',
            key: 'sos-training',
            layout: '/admin',
            path: '/third/sos/training',
            component: <SosTraining />
          },
          {
            name: 'SosTrainingRequirements',
            key: 'sos-training-requirements',
            layout: '/admin',
            path: '/third/sos/training/requirements',
            component: <SosTrainingRequirements />
          },

          {
            name: 'SosWorkflow',
            key: 'sos-workflow',
            layout: '/admin',
            path: '/third/sos/workflow',
            component: <SosWorkflow />
          },
          {
            name: 'SosWorkflowRequirements',
            key: 'sos-workflow-requirements',
            layout: '/admin',
            path: '/third/sos/workflow/requirements',
            component: <SosWorkflowRequirements />
          },
          {
            name: 'SosTasks',
            key: 'sos-tasks',
            layout: '/admin',
            path: '/third/sos/tasks',
            component: <SosTasks />
          },
          {
            name: 'SosEvents',
            key: 'sos-events',
            layout: '/admin',
            path: '/third/sos/events',
            component: <SosEvents />
          },
          {
            name: 'SosAudits',
            key: 'sos-audit',
            layout: '/admin',
            path: '/third/sos/audit',
            component: <SosAudits />
          },
          {
            name: 'SosVendors',
            key: 'sos-vendor',
            layout: '/admin',
            path: '/third/sos/vendor',
            component: <SosVendors />
          },
          {
            name: 'SosVendorInboundQuestionnaire',
            key: 'sos-vendor-questionnaire-inbound',
            layout: '/admin',
            path: '/third/sos/questions/inbound',
            component: <SosVendorInboundQuestionnaire />
          },
          {
            name: 'SosVendorOutboundQuestionnaire',
            key: 'sos-vendor-questionnaire-outbound',
            layout: '/admin',
            path: '/third/sos/questions/outbound',
            component: <SosVendorOutboundQuestionnaire />
          },
          {
            name: 'SosVendorPolicies',
            key: 'sos-vendor-policies',
            layout: '/admin',
            path: '/third/sos/vendor/policies',
            component: <SosVendorPolicies />
          },
          {
            name: 'SosVendorChat',
            key: 'sos-thirteen',
            layout: '/admin',
            path: '/third/sos/tasks',
            component: <SosVendorChat />
          },
          {
            name: 'SosDiscovery',
            key: 'sos-discovery',
            layout: '/admin',
            path: '/third/sos/discovery',
            component: <SosDiscovery />
          },
          {
            name: 'SosAssets',
            key: 'sos-assets',
            layout: '/admin',
            path: '/third/sos/assets',
            component: <SosAssets />
          },
          {
            name: 'SosEmployees',
            key: 'sos-employees',
            layout: '/admin',
            path: '/third/sos/employees',
            component: <SosEmployees />
          },
          {
            name: 'SosContractors',
            key: 'sos-contractors',
            layout: '/admin',
            path: '/third/sos/contractors',
            component: <SosContractors />
          },
          {
            name: 'SosAccess',
            key: 'sos-access',
            layout: '/admin',
            path: '/third/sos/access',
            component: <SosAccess />
          },
          {
            name: 'SosTags',
            key: 'sos-tags',
            layout: '/admin',
            path: '/third/sos/tags',
            component: <SosTags />
          },
          {
            name: 'SosVulnerabilities',
            key: 'sos-vulnerabilities',
            layout: '/admin',
            path: '/third/sos/vulnerabilities',
            component: <SosVulnerabilities />
          },
          {
            name: 'SosWhistleBlowers',
            key:  'sos-whistle-blower',
            layout: '/admin',
            path: '/third/sos/whistle-blower',
            component: <SosWhistleBlowers />
          }
        ],
      },
      {
        name: 'New Integration',
        key: 'new-integration',
        layout: '/admin',
        path: '/integrations/new',
        component: <Default />, // Replace with your actual component
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Administration',
    key: 'administration',
    icon: <IoBuild size="15px" color="inherit" />,
    collapse: [
      {
        name: 'My User Account',
        key: 'user',
        layout: '/admin',
        path: '/administration/user',
        component: <User />,
      },
      {
        name: 'HitchDef',
        key: 'hitch-def',
        layout: '/admin',
        path: '/administration/hitch',
        component: <HitchDef />
      },
      {
        name: 'Organization',
        key: 'organization',
        layout: '/admin',
        path: '/administration/organization',
        component: <Organization />,
      },
      {
        name: 'Connections',
        key: 'connections',
        layout: '/admin',
        path: '/administration/connections',
        component: <Connections />
      },
      {
        name: 'Subscription',
        key: 'subscription',
        layout: '/admin',
        path: '/administration/subscription',
        component: <Default />, // Replace with your actual component
      },
      {
        name: 'Billing',
        key: 'billing',
        layout: '/admin',
        path: '/administration/billing',
        component: <Default />, // Replace with your actual component
      },
      {
        name: 'Preferences',
        key: 'preferences',
        layout: '/admin',
        path: '/administration/preferences',
        component: <Default />, // Replace with your actual component
      },
      {
        name: 'Change Organization',
        key: 'changeorg',
        layout: '/admin',
        path: '/administration/change-org',
        component: <ChangeOrg />,
      }
    ],
  },
  {
    type: 'collapse',
    name: 'Hitch Admin',
    key: 'hitch-admin',
    icon: <IoShieldHalf size="15px" color="inherit" />,
    collapse: [
      {
        name: 'HitchAdminDashboard',
        key: 'admin-dashboard',
        layout: '/admin',
        path: '/hitch-admin',
        component: <HitchAdminDashboard />,
      },
      {
        name: 'ADns',
        key: 'adns',
        layout: '/admin',
        path: '/hitch-admin/adns',
        component: <ADns />,
      },
      {
        name: 'Cluster',
        key: 'cluster-admin',
        layout: '/admin',
        path: '/hitch-admin/cluster',
        component: <Cluster />,
      },
      {
        name: 'Services',
        key: 'service-admin',
        layout: '/admin',
        path: '/hitch-admin/services',
        component: <Services />,
      },
      {
        name: 'Ui Components',
        key: 'uicomponents',
        layout: '/admin',
        path: '/hitch-admin/uicomponents',
        component: <UiComponents />,
      },
      {
        name: 'Web Hooks',
        key: 'webhooks',
        layout: '/admin',
        path: '/hitch-admin/webhooks',
        component: <WebHooks />
      },
      {
        name: 'Icons',
        key: 'icons',
        layout: '/admin',
        path: '/hitch-admin/icons',
        component: <Icons />,
      },
      {
        name: 'Menu Items',
        key: 'menuitems',
        layout: '/admin',
        path: '/hitch-admin/menu-items',
        component: <MenuItems />,
      },
      {
        name: 'Accounts',
        key: 'account-admin',
        layout: '/admin',
        path: '/hitch-admin/accounts',
        component: <Accounts />,
      },
      {
        name: 'Users',
        key: 'user-admin',
        layout: '/admin',
        path: '/hitch-admin/users',
        component: <Users />,
      },
      {
        name: 'Routes',
        key: 'route-lookup',
        layout: '/admin',
        path: '/hitch-admin/routes',
        component: <Routes />,
      },
      {
        name: 'Nodes',
        key: 'node-admin',
        layout: '/admin',
        path: '/hitch-admin/nodes',
        component: <Nodes />,
      },
    ],
  },
  // Keeping the example pages
  {
    type: 'collapse',
    name: 'Dashboards',
    key: 'dashboards',
    icon: <IoHome size="15px" color="inherit" />,
    collapse: [
      {
        name: 'Main Dashboard',
        layout: '/admin',
        path: '/dashboards/default',
        component: <Default />,
      },
      {
        name: 'CRM',
        key: 'crm',
        layout: '/admin',
        path: '/dashboards/crm',
        component: <CRM />,
      },
    ],
  },
  { type: 'title', title: 'Pages', key: 'title-pages' },
  {
    type: 'collapse',
    name: 'Pages',
    key: 'pages',
    icon: <IoDocument size="15px" color="inherit" />,
    collapse: [
      {
        name: 'Profile',
        key: 'profile',
        collapse: [
          {
            name: 'Profile Overview',
            key: 'profile-overview',
            layout: '/admin',
            path: '/pages/profile/profile-overview',
            component: <ProfileOverview />,
          },
          {
            name: 'Teams',
            key: 'teams',
            layout: '/admin',
            path: '/pages/profile/teams',
            component: <Teams />,
          },
          {
            name: 'All Projects',
            key: 'all-projects',
            layout: '/admin',
            path: '/pages/profile/all-projects',
            component: <AllProjects />,
          },
        ],
      },
      {
        name: 'Users',
        key: 'users',
        collapse: [
          {
            name: 'Reports',
            key: 'reports',
            layout: '/admin',
            path: '/pages/users/reports',
            component: <Reports />,
          },
          {
            name: 'New User',
            key: 'new-user',
            layout: '/admin',
            path: '/pages/users/new-user',
            component: <NewUser />,
          },
        ],
      },
      {
        name: 'Account',
        key: 'account',
        collapse: [
          {
            name: 'Settings',
            key: 'settings',
            layout: '/admin',
            path: '/pages/account/settings',
            component: <Settings />,
          },
          {
            name: 'Billing',
            key: 'billing',
            layout: '/admin',
            path: '/pages/account/billing',
            component: <Billing />,
          },
          {
            name: 'Invoice',
            key: 'invoice',
            layout: '/admin',
            path: '/pages/account/invoice',
            component: <Invoice />,
          },
        ],
      },
      {
        name: 'Projects',
        key: 'projects',
        collapse: [
          {
            name: 'General',
            key: 'general',
            layout: '/admin',
            path: '/pages/projects/general',
            component: <General />,
          },
          {
            name: 'Timeline',
            key: 'timeline',
            layout: '/admin',
            path: '/pages/projects/timeline',
            component: <Timeline />,
          },
        ],
      },
      {
        name: 'Pricing Page',
        key: 'pricing-page',
        layout: '/admin',
        path: '/pages/pricing-page',
        component: <PricingPage />,
      },
      {
        name: 'RTL',
        key: 'rtl',
        layout: '/admin',
        path: '/pages/rtl',
        component: <RTL />,
      },
      {
        name: 'Widgets',
        key: 'widgets',
        layout: '/admin',
        path: '/pages/widgets',
        component: <Widgets />,
      },
      {
        name: 'Charts',
        key: 'charts',
        layout: '/admin',
        path: '/pages/charts',
        component: <Charts />,
      },
      {
        name: 'Alerts',
        key: 'alerts',
        layout: '/admin',
        path: '/pages/alerts',
        component: <Alerts />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Applications',
    key: 'applications',
    icon: <IoBuild size="15px" color="inherit" />,
    collapse: [
      {
        name: 'Kanban',
        key: 'kanban',
        layout: '/admin',
        path: '/applications/kanban',
        component: <Kanban />,
      },
      {
        name: 'Wizard',
        key: 'wizard',
        layout: '/admin',
        path: '/applications/wizard',
        component: <Wizard />,
      },
      {
        name: 'Data Tables',
        key: 'data-tables',
        layout: '/admin',
        path: '/applications/data-tables',
        component: <DataTables />,
      },
      {
        name: 'Calendar',
        key: 'calendar',
        layout: '/admin',
        path: '/applications/calendar',
        component: <Calendar />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Ecommerce',
    key: 'ecommerce',
    icon: <FaShoppingCart size="15px" color="inherit" />,
    collapse: [
      {
        name: 'Products',
        key: 'products',
        collapse: [
          {
            name: 'New Product',
            key: 'new-product',
            layout: '/admin',
            path: '/ecommerce/products/new-product',
            component: <NewProduct />,
          },
          {
            name: 'Edit Product',
            key: 'edit-product',
            layout: '/admin',
            path: '/ecommerce/products/edit-product',
            component: <EditProduct />,
          },
          {
            name: 'Product Page',
            key: 'product-page',
            layout: '/admin',
            path: '/ecommerce/products/product-page',
            component: <ProductPage />,
          },
        ],
      },
      {
        name: 'Orders',
        key: 'orders',
        collapse: [
          {
            name: 'Order List',
            key: 'order-list',
            layout: '/admin',
            path: '/ecommerce/orders/order-list',
            component: <OrderList />,
          },
          {
            name: 'Order Details',
            key: 'order-details',
            layout: '/admin',
            path: '/ecommerce/orders/order-details',
            component: <OrderDetails />,
          },
        ],
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Authentication',
    key: 'authentication',
    icon: <IoDocuments size="15px" color="inherit" />,
    collapse: [
      {
        name: 'Sign In',
        key: 'sign-in',
        collapse: [
          {
            name: 'Basic',
            key: 'basic',
            layout: '/admin',
            path: '/authentication/sign-in/basic',
            component: <Login />,
          },
          {
            name: 'Cover',
            key: 'cover',
            layout: '/admin',
            path: '/authentication/sign-in/cover',
            component: <SignInCover />,
          },
          {
            name: 'Illustration',
            key: 'illustration',
            layout: '/admin',
            path: '/authentication/sign-in/illustration',
            component: <SignInIllustration />,
          },
        ],
      },
      {
        name: 'Sign Up',
        key: 'sign-up',
        collapse: [
          {
            name: 'Basic',
            key: 'basic',
            layout: '/admin',
            path: '/authentication/sign-up/basic',
            component: <SignUpBasic />,
          },
          {
            name: 'Cover',
            key: 'cover',
            layout: '/admin',
            path: '/authentication/sign-up/cover',
            component: <SignUpCover />,
          },
          {
            name: 'Illustration',
            key: 'illustration',
            layout: '/admin',
            path: '/authentication/sign-up/illustration',
            component: <SignUpIllustration />,
          },
        ],
      },
    ],
  },
];

export default routes;
