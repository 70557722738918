
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";

// Vision UI Dashboard PRO React example components
import DefaultItem from "examples/Items/DefaultItem";

// icons
import { IoMdNotifications } from "react-icons/io";
import { IoWallet } from "react-icons/io5";

function UpcomingEvents() {
  return (
    <Card sx={{ height: "100%", pt: "28px" }}>
      <HitchBox display="flex" flexDirection="column" lineHeight={1} mb="24px">
        <HitchTypography variant="lg" color="white" fontWeight="bold">
          Upcoming events
        </HitchTypography>
        <HitchTypography variant="button" color="text" fontWeight="regular">
          Joined
        </HitchTypography>
      </HitchBox>
      <HitchBox>
        <DefaultItem
          color="info"
          icon={<IoWallet color="white" size="22px" />}
          title="Cyber Week"
          description="27 March 2020, at 12:30 PM"
        />
        <HitchBox mt={3.5}>
          <DefaultItem
            color="info"
            icon={<IoMdNotifications color="white" size="22px" />}
            title="Meeting with Marry"
            description="24 March 2020, at 10:00 PM"
          />
        </HitchBox>
      </HitchBox>
    </Card>
  );
}

export default UpcomingEvents;
