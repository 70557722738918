
// FILE: /UI\hitch-ai-ui-2\src\examples\LayoutContainers\PageLayout\index.js

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import HitchBox from "components/HitchBox";
import { useHitchAIUIController, setLayout } from "context";

function PageLayout({ background = "default", children }) {
  const [, dispatch] = useHitchAIUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <HitchBox
      width="100vw"
      maxWidth="100%"
      height="100%"
      minHeight="100vh"
      sx={({ functions: { tripleLinearGradient }, palette: { gradients } }) => ({
        overflowX: "hidden",
        background: background
          ? `${background} !important`
          : ` ${tripleLinearGradient(
              gradients.cover.main,
              gradients.cover.state,
              gradients.cover.stateSecondary,
              gradients.cover.deg
            )} !important`,
        backgroundSize: "cover",
        position: "relative",
      })}
    >
      {children}
    </HitchBox>
  );
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
