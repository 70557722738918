
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE:/UI/hitch-ai-ui-2/src/hitch/administration/organization/Users/index.js

import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DataTable from "examples/Tables/DataTable";
import HitchButton from "components/HitchButton";
import HitchModal from "components/HitchModal";
import DefaultCell from "layouts/ecommerce/products/product-page/components/DefaultCell";
import SearchUsersModal from "hitch/SearchUsersModal";
import { useEffect, useState } from "react";
import { useHubConnections } from "context/HubConnectionsProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function Users() {
    const [dataTable, setDataTable] = useState([]);
    const { hitchUser, account, dashboardConnection, hitchDashCommand, isConnectionReady, errorSnack, infoSnack, successSnack } = useHubConnections();
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showUserModal, setShowUserModal] = useState(false);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady && account) {
            dashboardConnection.on('ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5', (data) => {
                if (data.StackTraceString) {
                    errorSnack(`Error: ${data.Message}`);
                } else {
                    setDataTable(data);
                }
            });

            function callForAccountDetails() {
                hitchDashCommand('account', 'role', 'user-list', {
                    theAccountId: account.id,
                });
            }

            callForAccountDetails();

            return () => {
                if (dashboardConnection) {
                    dashboardConnection.off("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5");
                }
            };
        }
    }, [dashboardConnection, isConnectionReady, account]);

    const handleRemoveUser = (row) => {
        setSelectedUser({ userId: row.id, roleName: row.roleName });
        setShowModal(true);
    };

    const handleModalYes = () => {
        if (selectedUser) {
            removeUser(selectedUser.userId, selectedUser.roleName);
        }
        setShowModal(false);
    };

    const handleModalNo = () => {
        setShowModal(false);
    };

    const closeUserSearch = () => {
        setShowUserModal(false);
    };

    const selectUserRole = (users, role) => {
        if (dashboardConnection && isConnectionReady) {
            users.forEach((userId) => {
                hitchDashCommand('account', 'role', 'assign', {
                    theUserId: userId,
                    roleId: role
                });
            });
        }
    };

    const newUser = () => {
        setShowUserModal(true);
    };

    const removeUser = (userId, roleName) => {
        if (dashboardConnection && isConnectionReady) {
            hitchDashCommand('account', 'role', 'revoke', {
                theUserId: userId,
                roleName: roleName            
            });
        }
    };

    function refreshUsers() {
        hitchDashCommand('account', 'role', 'user-list', {
            theAccountId: account.id,
        });
    }

    const columns = [
        { Header: "USER", accessor: "name", width: "40%" },
        { Header: "ROLE", accessor: "roleName", width: "40%" },
        { Header: "ACTIONS", accessor: "actions", align: "start", width: "20%" },
    ];

    // Map over dataTable and pass key directly to TableRow
    const rows = dataTable.map((row) => ({
        name: <DefaultCell key={`name-${row.id}`}>{`${row.name} (${row.email})`}</DefaultCell>,
        roleName: <DefaultCell key={`role-${row.id}`}>{row.roleName || 'N/A'}</DefaultCell>,
        key: row.id,
        actions: (
            <HitchButton
                key={`actions-${row.id}`}
                variant="contained"
                color="error"
                onClick={() => handleRemoveUser(row)}
            >
                <FontAwesomeIcon icon={faTrash} />
            </HitchButton>
        ),
    }));

    return (
        <>
            <HitchBox display="flex" justifyContent="space-between" alignItems="center" mb="30px">
                <HitchTypography variant="h6" fontWeight="medium" color="white">
                    Users
                </HitchTypography>
                <HitchButton
                    variant="outlined"
                    color="white"
                    onClick={refreshUsers}
                    sx={{ minWidth: '120px' }}
                >
                    Refresh
                </HitchButton>
                <HitchButton
                    variant="outlined"
                    color="white"
                    onClick={newUser}
                    sx={{ minWidth: '120px' }}
                >
                    Assign User
                </HitchButton>
            </HitchBox>
            <HitchBox mt={2}>
                <DataTable
                    key="user-table"
                    table={{ columns, rows }}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                />
            </HitchBox>
            <HitchModal
                open={showModal}
                onClose={handleModalNo}
                title="Confirm Removal"
                question="Are you sure you want to remove this user?"
                yesLabel="Yes"
                noLabel="No"
                onYes={handleModalYes}
                onNo={handleModalNo}
                dismissOnClickOutside={false}
            />
            <SearchUsersModal
                open={showUserModal}
                onClose={closeUserSearch}
                onAssign={selectUserRole}
            />
        </>
    );
}

export default Users;
