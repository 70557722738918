
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/bot/index.js

import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HitchButton from "components/HitchButton";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import HitchFormField from "components/HitchFormField";
import HitchSFOSelector from "components/HitchSFOSelector";
import HitchSelect from "components/HitchSelect";
import HitchSwitch from "components/HitchSwitch";
import HitchFormTextArea from "components/HitchFormTextArea";

function BotAltConfig() {
    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser,
    } = useHubConnections();

    const chatMode = '70e9d37e-b0ae-47fa-8ba7-2a781f64dc4c';
    const serviceMode = 'a0408a6e-8530-4806-b801-d3df85e31e41';

    const [config, setConfig] = useState({});
    const navigate = useNavigate();
    const { configId, botId } = useParams();
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');

                dashboardConnection.on("4dd09265-a061-4c29-9af6-f4c9a951d3e4", (data) => {
                    console.log("4dd09265-a061-4c29-9af6-f4c9a951d3e4", data);
                    if (data && data !== "An Error has occurred") {
                        setConfig(data);
                    }
                });
                dashboardConnection.on("d0211952-9a16-4a9e-b82c-4ac6ea4552ef", (data) => {
                    console.log("d0211952-9a16-4a9e-b82c-4ac6ea4552ef", data);
                    setConfig(data);
                })
            };

            subscribeToEvents();

            console.log('getting config');

            newConfig();

            hitchDashCommand('chat', 'bot', 'get-config', { botId: botId, configurationId: configId });


            return () => {
                dashboardConnection.off("4dd09265-a061-4c29-9af6-f4c9a951d3e4");
                dashboardConnection.off("d0211952-9a16-4a9e-b82c-4ac6ea4552ef");
            };
        }
    }, [dashboardConnection]);

    function onClose() {
        setEditMode(false);
    }

    function goBack() {
        navigate(`/deployment/my-bots/${botId}`);
    }

    function newConfig() {
        setConfig(
            {
                id: configId,
                accountId: hitchUser?.accountId,
                name: 'new configuration',
                botId: botId,
                modeId: chatMode,
                welcomeMessage: '',
                instructions: '',
                outputJson: false,
                outputJsonSchema: '',
                outputMapToField: '',
                outputService: '',
                outputFeature: '',
                outputOperation: '',
                outputTemp: false
            }
        )
    }

    function onSave(values) {
        console.log('save', values);

        const theThing = {
            theConfiguration: {
                id: config.id,
                accountId: config.accountId,
                created: config.created,
                createdBy: config.createdBy,
                updated: config.updated,
                updatedBy: config.updatedBy,
                name: values.name,
                botId: values.botId,
                modeId: values.modeId,
                welcomeMessage: values.welcomeMessage,
                instructions: values.instructions,
                outputJson: values.outputJson,
                outputJsonSchema: values.outputJsonSchema,
                outputMapToField: values.outputMapToField,
                outputService: values.outputService,
                outputFeature: values.outputFeature,
                outputOperation: values.outputOperation,
                outputTemp: values.outputTemp
            }
        };

        console.log('Saving the following Configuration Data', theThing);
        hitchDashCommand('chat', 'bot', 'update-config', theThing, () => {
            setEditMode(false);
        });
    }



    const modes = [
        { label: 'Chat Mode', value: chatMode },
        { label: 'Service Mode', value: serviceMode }
    ]

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Bot Alt Config
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Management of Bot Alternative Configuration
                            </HitchTypography>

                        </HitchBox>

                        {!editMode && (

                            <HitchBox mb={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <HitchButton
                                        type="submit"
                                        variant="outlined"
                                        color="white"
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit
                                    </HitchButton>
                                    <HitchButton
                                        variant="contained"
                                        color="error"
                                        onClick={() => goBack()}
                                    >
                                        Go Back
                                    </HitchButton>
                                </Grid>

                                <HitchDisplayField
                                    label="Name"
                                    value={config?.name}
                                />

                                <HitchDisplayField
                                    label="Bot Id"
                                    value={config?.botId}
                                />

                                <HitchDisplayField
                                    label="Mode Id"
                                    value={config?.modeId}
                                />

                                <HitchDisplayField
                                    label="Welcome Message"
                                    value={config?.welcomeMessage}
                                />
                                <HitchDisplayField
                                    label="Instructions"
                                    value={config?.instructions}
                                />

                                {config?.modeId === serviceMode && (
                                    <HitchDisplayField
                                        label="Output as Json"
                                        value={config?.outputJson ? 'true' : 'false'}
                                    />
                                )}

                                {config?.modeId === serviceMode && (
                                    <HitchDisplayField
                                        label="Output To Temp File"
                                        value={config?.outputTemp ? 'true' : 'false'}
                                    />
                                )}

                                {config?.modeId === serviceMode && config?.outputJson && (
                                    <HitchDisplayField
                                        label="Output Json Schema"
                                        value={config?.outputJsonSchema}
                                    />
                                )}

                                {config?.modeId === serviceMode && (
                                    <HitchDisplayField
                                        label="Service"
                                        value={config?.outputService}
                                    />
                                )}

                                {config?.modeId === serviceMode && (
                                    <HitchDisplayField
                                        label="Feature"
                                        value={config?.outputFeature}
                                    />
                                )}

                                {config?.modeId === serviceMode && (
                                    <HitchDisplayField
                                        label="Operation"
                                        value={config?.outputOperation}
                                    />
                                )}

                                {config?.modeId === serviceMode && !config?.outputJson && (
                                    <HitchDisplayField
                                        label="Output Map To Field"
                                        value={config?.outputMapToField}
                                    />
                                )}

                            </HitchBox>

                        )}

                        {editMode && (
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    name: config?.name || "",
                                    botId: config?.botId || "",
                                    modeId: config?.modeId || "",
                                    welcomeMessage: config?.welcomeMessage || "",
                                    instructions: config?.instructions || "",
                                    outputJson: config?.outputJson || false,
                                    outputJsonSchema: config?.outputJsonSchema || "",
                                    outputMapToField: config?.outputMapToField || "",
                                    outputService: config?.outputService || "",
                                    outputFeature: config?.outputFeature || "",
                                    outputOperation: config?.outputOperation || "",
                                    outputTemp: config?.outputTemp || false,
                                }}
                                onSubmit={(values) => {
                                    console.log('Submitted values', values);
                                    console.log('Config before save', config);
                                    onSave({ ...config, ...values });
                                }}
                            >
                                {({ values, handleChange, setFieldValue, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <HitchBox mb={2} m={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                md={5}
                                                mb={2}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <HitchButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="success"
                                                >
                                                    Save
                                                </HitchButton>
                                                <HitchButton
                                                    variant="contained"
                                                    color="error"
                                                    onClick={onClose}
                                                >
                                                    Cancel
                                                </HitchButton>
                                            </Grid>

                                            <HitchFormField
                                                name="name"
                                                label="Name"
                                                fullWidth
                                                editMode={editMode}
                                            />

                                            <HitchFormField
                                                label="Bot Id"
                                                name="botId"
                                                fullWidth
                                                editMode={editMode}
                                            />

                                            <HitchFormField
                                                label="Mode Id"
                                                name="modeId"
                                                fullWidth
                                                editMode={editMode}
                                            />

                                            <HitchSelect
                                                name="modeId"
                                                label="Mode"
                                                options={modes}
                                                value={values?.modeId}
                                                placeholder="Select Mode"
                                                // onChange={handleFeatureChange}
                                                editMode={true}
                                            />

                                            <HitchFormField
                                                label="Welcome Message"
                                                name="welcomeMessage"
                                                fullWidth
                                                editMode={editMode}
                                                multiline
                                                rows={3}
                                            />

                                            <HitchFormField
                                                label="Instructions"
                                                name="instructions"
                                                fullWidth
                                                editMode={editMode}
                                                multiline
                                                rows={3}
                                            />

                                            {values?.modeId === serviceMode && (

                                                <HitchSwitch
                                                    label="Output as Json"
                                                    name="outputJson"
                                                    onLabel="Yes"
                                                    offLabel="No"
                                                    fullWidth
                                                    editMode={editMode}
                                                />

                                            )}

                                            {values?.modeId === serviceMode && (

                                                <HitchSwitch
                                                    label="Output To Temp File"
                                                    name="outputTemp"
                                                    onLabel="Yes"
                                                    offLabel="No"
                                                    fullWidth
                                                    editMode={editMode}
                                                />

                                            )}

                                            {values?.modeId === serviceMode && values?.outputJson && (

                                                <HitchFormTextArea
                                                    editMode={editMode}
                                                    fullWidth
                                                    label="Output Json Schema"
                                                    name="outputJsonSchema"
                                                />

                                            )}

                                            {values?.modeId === serviceMode
                                                &&
                                                (
                                                    <HitchSFOSelector
                                                        value={{
                                                            serviceId: values.outputService,
                                                            featureId: values.outputFeature,
                                                            operationId: values.outputOperation,
                                                        }}
                                                        onChange={(updatedValues) => {
                                                            console.log('updatedValues from HitchSFOSelector', updatedValues);
                                                            setFieldValue("outputService", updatedValues.serviceId || "");
                                                            setFieldValue("outputFeature", updatedValues.featureId || "");
                                                            setFieldValue("outputOperation", updatedValues.operationId || "");
                                                        }}
                                                        useFormik={true}
                                                        readOnly={!editMode}
                                                        editMode={editMode}
                                                        serviceIdField="outputService"
                                                        featureIdField="outputFeature"
                                                        operationIdField="outputOperation"
                                                    />


                                                )}

                                            {values?.modeId === serviceMode && !values?.outputJson && (

                                                <HitchFormField
                                                    label="Output Map To Field"
                                                    name="outputMapToField"
                                                    fullWidth
                                                    editMode={editMode}
                                                />

                                            )}

                                        </HitchBox>
                                    </Form>
                                )}
                            </Formik>
                        )}

                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default BotAltConfig;