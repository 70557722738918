
// FILE: /UI/hitch-ai-ui-2/src/examples/Cards/ControllerCard/index.js

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import VuiSwitch from "components/VuiSwitch";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";

function ControllerCard({ color = "info", state = false, icon, title, description = "", onChange }) {
  return (
    <Card sx={{ height: "100%", minHeight: "220px" }}>
      <HitchBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <HitchBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <HitchTypography variant="body2" color={state ? "white" : "text"}>
            {state ? "On" : "Off"}
          </HitchTypography>
          <HitchBox mr={1}>
            <VuiSwitch color="info" checked={state} onChange={onChange} />
          </HitchBox>
        </HitchBox>
        {icon}
        <HitchBox lineHeight={1}>
          <HitchTypography
            fontSize={16}
            color={state ? "white" : "text"}
            textTransform="capitalize"
            fontWeight="bold"
          >
            {title}
          </HitchTypography>
          {description ? (
            <HitchTypography variant="caption" color={state ? "white" : "text"}>
              {description}
            </HitchTypography>
          ) : null}
        </HitchBox>
      </HitchBox>
    </Card>
  );
}

// Typechecking props for the ControllerCard
ControllerCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  state: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ControllerCard;
