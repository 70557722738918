
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchButton from "components/HitchButton";
import HitchDataTable from "components/HitchDataTable";
import { v4 as uuidv4 } from "uuid";
import GitHubRuleAction from "../GitHubRuleAction";

function GitHubRuleActions({actionList, setActionList, accountId, ruleId}) {
    const cols = [
        { accessor: "id", Header: "ID", width: "25%" },
        { accessor: "name", Header: "Action Name", width: "35%" }
    ];

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: [],
    });
    const [currentAction, setCurrentAction] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEditAction = (action) => {
        setCurrentAction({ ...action });
        setIsModalOpen(true);
    };

    const handleAddAction = () => {
        const newAction = {
            id: uuidv4(),
            accountId: accountId,
            ruleId: ruleId,
            name: ""
        };

        setActionList((prev) => [...prev, newAction]);
        setCurrentAction(newAction);
        setIsModalOpen(true);
    };

    const handleSaveAction = (updatedAction) => {
        
        console.log('updated action', updatedAction);
        
        setActionList((prevActions) =>
            prevActions.map((act) =>
                act.id === updatedAction.id ? updatedAction : act
            )
        );
        console.log("Updated actionList:", actionList);
        setIsModalOpen(false);
    };


    useEffect(() => {
        setDataTableData((prevState) => ({
            ...prevState,
            rows: actionList,
        }));
    }, [actionList]);

    return (
        <HitchBox mb={3}>
            <Card>
                <HitchBox p={3}>
                    <HitchTypography variant="h6" color="white">
                        Actions
                    </HitchTypography>
                    <HitchButton onClick={handleAddAction} variant="contained" color="info">
                        Add Action
                    </HitchButton>
                    <HitchDataTable
                        table={dataTableData}
                        canSearch
                        onRowClick={(row) => handleEditAction(row)}
                    />
                    {isModalOpen && currentAction && (
                        <GitHubRuleAction
                            action={currentAction}
                            onChange={setCurrentAction} // Pass down local editing handler
                            onSave={handleSaveAction} // Pass save handler
                            onClose={() => setIsModalOpen(false)} // Close modal
                        />
                    )}
                </HitchBox>
            </Card>
        </HitchBox>
    );
}

export default GitHubRuleActions;