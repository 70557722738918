import { Card } from "@mui/material";
import HitchTitle from "components/HitchTitle";
import HitchBox from "components/HitchBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";

function HitchPage({ title, subTitle, icon, tooltip, action, children }) {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        {/* Title Section */}
                        <HitchBox p={3} pb={0}> 
                            <HitchTitle
                                title={title}
                                subTitle={subTitle}
                                icon={icon}
                                action={action}
                                tooltip={tooltip}
                            />
                        </HitchBox>

                        {/* Content Section */}
                        <HitchBox p={3}>{children}</HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default HitchPage;
