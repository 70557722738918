import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiDropzone from "components/VuiDropzone";

function Media() {
  return (
    <HitchBox>
      <HitchTypography variant="h5" color="white">
        Media
      </HitchTypography>
      <HitchBox mt={3}>
        <HitchBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <HitchTypography component="label" variant="caption" fontWeight="bold" color="white">
            Product Image
          </HitchTypography>
        </HitchBox>
        <VuiDropzone options={{ addRemoveLinks: true }} />
      </HitchBox>
    </HitchBox>
  );
}

export default Media;
