
import HitchBox from "components/HitchBox";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AccountForm from "./AccountForm";
import AccountConnections from "./AccountConnections";
import AccountBilling from "./AccountBilling";
import AccountRoles from "./AccountRoles";

function AccountDetails() {
    const navigate = useNavigate();
    const { accountId } = useParams();
    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();
    const [theAccount, setTheAccount] = useState({});
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                // dashboardConnection.on("0f84a216-e3d0-475c-ac15-70880ba4c0c3", (data) => {
                //     setTheAccount(data);
                // });
            };

            subscribeToEvents();
            // hitchDashCommand('admin', 'account', 'get', { theAccountId: accountId });

            return () => {
                // dashboardConnection.off('0f84a216-e3d0-475c-ac15-70880ba4c0c3');
            };
        }
    }, [dashboardConnection, accountId]);


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox py={3}>
                <AccountForm />
            </HitchBox>
            <HitchBox py={3}>
                <AccountConnections />
            </HitchBox>
            <HitchBox py={3}>
                <AccountRoles />
            </HitchBox>
            <HitchBox py={3}>
                <AccountBilling />
            </HitchBox>
        </DashboardLayout>
    );

}

export default AccountDetails;