// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/examples/Sidenav/index.js


import { useEffect, useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import HitchBox from 'components/HitchBox';
import HitchTypography from 'components/HitchTypography';
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
import SidenavList from 'examples/Sidenav/SidenavList';
import SidenavItem from 'examples/Sidenav/SidenavItem';
import SidenavCard from 'examples/Sidenav/SidenavCard';
import SidenavRoot from 'examples/Sidenav/SidenavRoot';
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { renderThumb, renderTrack, renderTrackRTL, renderView, renderViewRTL } from 'examples/Scrollbar';
import { useHitchAIUIController, setMiniSidenav, setTransparentSidenav } from 'context';
import { useHubConnections } from 'context/HubConnectionsProvider';
import VuiAvatar from 'components/VuiAvatar';
import { resolveComponent } from "services/componentResolver";
import { resolveIcon } from "services/iconResolver";
import { Badge } from '@mui/material';

function Sidenav({ color = 'info', brand = '', brandName, routes, ...rest }) {
	const [openCollapse, setOpenCollapse] = useState(false);
	const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
	const [controller, dispatch] = useHitchAIUIController();
	const { miniSidenav, transparentSidenav } = controller;
	const location = useLocation();
	const { pathname } = location;
	const collapseName = pathname.split('/').slice(1)[0];
	const itemName = pathname.split('/').slice(1)[1];
	const [theRoutes, setTheRoutes] = useState([]);

	const {
		account,
		dashboardConnection,
		forceReconnect,
		hitchDashCommand,
		hitchUser,
		isConnectionReady,
		users
	} = useHubConnections();

	const closeSidenav = () => setMiniSidenav(dispatch, true);

	useEffect(() => {
		// A function that sets the mini state of the sidenav.
		function handleMiniSidenav() {
			setMiniSidenav(dispatch, window.innerWidth < 1200);
		}

		// The event listener that's calling the handleMiniSidenav function when resizing the window.
		window.addEventListener('resize', handleMiniSidenav);

		// Call the handleMiniSidenav function to set the state with the initial value.
		handleMiniSidenav();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleMiniSidenav);
	}, [dispatch, location]);

	useEffect(() => {
		if (window.innerWidth < 1440) {
			setTransparentSidenav(dispatch, false);
		}
	}, []);

	useEffect(() => {
		if (dashboardConnection && isConnectionReady && hitchUser) {
			const subscribeToEvents = () => {
				dashboardConnection.on("cc367b2f-fc4a-4c2d-8bf0-3beba4af0e69", (data) => {
					if(data === "An Error Has Occurred") {
						console.error("An Error Has Occurred.");
						return;
					}
					setTheRoutes(data);
				});
				hitchDashCommand('account', 'menu', 'user-menu', {});
			};

			subscribeToEvents();
			return () => {
				dashboardConnection.off('cc367b2f-fc4a-4c2d-8bf0-3beba4af0e69');
			};
		}
	}, [dashboardConnection, isConnectionReady, hitchUser]);

	// Render nested collapse items with icon and component resolution
	const renderNestedCollapse = (collapse) => {
		return collapse.map(({ name, path, key, href, collapse: nestedCollapse, componentId }) => {
			const Component = resolveComponent(componentId);

			if (nestedCollapse && nestedCollapse.length > 0) {
				return (
					<SidenavItem
						color={color}
						key={key}
						name={name}
						active={path === pathname}
						nested
						open={openNestedCollapse === name}
						onClick={() =>
							openNestedCollapse === name ? setOpenNestedCollapse(false) : setOpenNestedCollapse(name)
						}>
						{renderNestedCollapse(nestedCollapse)}
					</SidenavItem>
				);
			}

			return href ? (
				<Link key={key} href={href} target='_blank' rel='noreferrer' sx={{ textDecoration: 'none' }}>
					<SidenavItem color={color} name={name} nested />
				</Link>
			) : (
				<NavLink to={path} key={key} sx={{ textDecoration: 'none' }}>
					<SidenavItem color={color} name={name} active={path === pathname} nested />
				</NavLink>
			);
		});
	};


	// Render collapses from the resolved routes with icons and components
	const renderCollapse = (collapses) =>
		collapses.map(({ name, collapse, path, href, key, iconId }) => {
			const IconComponent = resolveIcon(iconId);
			let returnValue;

			if (collapse && collapse.length > 0) {
				returnValue = (
					<SidenavItem
						color={color}
						key={key}
						name={name}
						icon={IconComponent}
						active={key === itemName}
						open={openNestedCollapse === name}
						onClick={() =>
							openNestedCollapse === name ? setOpenNestedCollapse(false) : setOpenNestedCollapse(name)
						}>
						{renderNestedCollapse(collapse)}
					</SidenavItem>
				);
			} else {
				returnValue = href ? (
					<Link href={href} key={key} target='_blank' rel='noreferrer' sx={{ textDecoration: 'none' }}>
						<SidenavItem color={color} name={name} icon={IconComponent} active={key === itemName} />
					</Link>
				) : (
					<NavLink to={path} key={key} sx={{ textDecoration: 'none' }}>
						<SidenavItem color={color} name={name} icon={IconComponent} active={key === itemName} />
					</NavLink>
				);
			}
			return <SidenavList key={`list-${key}`}>{returnValue}</SidenavList>;
		});
	
	// Render all routes with icon and component resolution
	const renderRoutes = theRoutes.map(({ type, name, iconId, title, collapse, noCollapse, key, href, componentId }) => {
		const IconComponent = resolveIcon(iconId);
		let returnValue;

		if (type === 'collapse') {
			returnValue = href ? (
				<Link href={href} key={key} target='_blank' rel='noreferrer' sx={{ textDecoration: 'none' }}>
					<SidenavCollapse
						color={color}
						name={name}
						icon={IconComponent}
						active={key === collapseName}
						noCollapse={noCollapse}
					/>
				</Link>
			) : (
				<SidenavCollapse
					color={color}
					key={key}
					name={name}
					icon={IconComponent}
					active={key === collapseName}
					open={openCollapse === name}
					onClick={() => (openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name))}>
					{collapse ? renderCollapse(collapse) : null}
				</SidenavCollapse>
			);
		} else if (type === 'title') {
			returnValue = (
				<HitchTypography
					key={key}
					display='block'
					variant='caption'
					fontWeight='bold'
					color='white'
					textTransform='uppercase'
					pl={3}
					my={3}
					ml={1}>
					{title}
				</HitchTypography>
			);
		} else if (type === 'divider') {
			returnValue = <Divider key={key} />;
		}

		return returnValue;
	});

	return (
		<SidenavRoot {...rest} variant='permanent' ownerState={{ miniSidenav, transparentSidenav }}>
			<Scrollbars
				autoHide
				autoHideTimeout={1000}
				renderThumbVertical={renderThumb}
				renderTrackVertical={window.location.href.includes('rtl') ? renderTrackRTL : renderTrack}
				renderView={window.location.href.includes('rtl') ? renderViewRTL : renderView}>
				<HitchBox
					display='flex'
					sx={{ flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
					<HitchBox pt={3} pb={1} px={4} textAlign='center'>
						<HitchBox
							display={{ xs: 'block', xl: 'none' }}
							position='absolute'
							top={0}
							right={0}
							p={1.625}
							onClick={closeSidenav}
							sx={{ cursor: 'pointer' }}>
							<HitchTypography variant='h6' color='white'>
								<Icon sx={{ fontWeight: 'bold' }}>close</Icon>
							</HitchTypography>
						</HitchBox>
						<HitchBox component={NavLink} to='/' display='flex' alignItems='center'>
							<HitchBox
								sx={((theme) => sidenavLogoLabel(theme, { miniSidenav }), {
									display: 'flex',
									alignItems: 'center',
									margin: '0 auto'
								})}>
								<Badge
									variant="dot"
									color={isConnectionReady ? "success" : "error"}
									overlap="circular"
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right"
									}}
									onClick={() => {
										forceReconnect();
									}}
								></Badge>
								<VuiAvatar
									src={`${window._env_.HITCH_API}/api/accimage/${account?.id}.png`}
									alt="profile-image"
									variant="rounded"
									size="sm"
									shadow="sm"
								></VuiAvatar>
								<HitchTypography
									variant='button'
									textGradient={true}
									color={color}
									fontSize={14}
									letterSpacing={1}
									fontWeight='medium'
									sx={((theme) => sidenavLogoLabel(theme, { miniSidenav, transparentSidenav }), {
										opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
										maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : '100%',
										margin: '0 auto'
									})}>
									<>{account?.name}</>
								</HitchTypography>
							</HitchBox>
						</HitchBox>
						<Divider light />
					</HitchBox>
					<List sx={{ mb: 'auto' }}>{renderRoutes}</List>
					<HitchBox pt={2} mx={2} mb={2}>
						<SidenavCard />
					</HitchBox>
				</HitchBox>
			</Scrollbars>
		</SidenavRoot>
	);
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
	color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
	brand: PropTypes.string,
	brandName: PropTypes.string.isRequired,
	routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Sidenav;
