import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";

function HitchDashboard({ dashboardId }) {

    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const [dashboardContent, setDashboardContent] = useState(null);

    useEffect(() => {
        if (dashboardConnection && dashboardId && isConnectionReady) {
            dashboardConnection.on("366c2a33-dba5-467d-bda5-84a998d09624", incomingDash);
            dashboardConnection.on("95f15d49-1385-4635-ab2f-e02330c03010", incomingDash);
            dashboardConnection.on("2731635d-6065-45c5-9ede-8cb2c7987eda", widgets);

            hitchDashCommand('account', 'dashboard', 'get', {
                dashboardId: dashboardId
            });

            return (() => {
                dashboardConnection.off("366c2a33-dba5-467d-bda5-84a998d09624", incomingDash);
                dashboardConnection.off("95f15d49-1385-4635-ab2f-e02330c03010", incomingDash);
                dashboardConnection.off("2731635d-6065-45c5-9ede-8cb2c7987eda", widgets);
            })

        }
    }, [dashboardConnection, dashboardId, isConnectionReady]);

    function incomingDash(data) {
        if (data) {
            setDashboardContent(data);
        } else {
            var tmpDashboard = {
                id: dashboardId,
                name: 'Admin Dashboard',
                accountId: hitchUser.accountId,
                userId: hitchUser.userId,
                isDefault: false,
                isDeleted: false
            };
            hitchDashCommand('account', 'dashboard', 'update', {
                theDashboard: tmpDashboard
            });
        }
    }

    function widgets(data) {
        console.log('widgets', data);
    }

    useEffect(() => {
        if (dashboardContent) {
            console.log('DASHBOARD', dashboardContent);
        }
    }, [dashboardContent]);


    return (
        <HitchTypography variant="h3">
            Hitch Dashboard Component.
        </HitchTypography>
    )
}

export default HitchDashboard;