
import { useMemo } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import BasicLineChart from "../BasicLineChart";

function GradientLineChart({ title = "", description = "", height, data, options }) {
  const renderChart = (
    <HitchBox>
      {title || description ? (
        <HitchBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <HitchBox mb={1}>
              <HitchTypography variant="lg" color="white" fontWeight="bold">
                {title}
              </HitchTypography>
            </HitchBox>
          )}
          <HitchBox mb={2}>
            <HitchTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </HitchTypography>
          </HitchBox>
        </HitchBox>
      ) : null}
      <HitchBox sx={{minHeight: "300px"}}>

      {useMemo(
        () => (
          <BasicLineChart lineChartData={data} lineChartOptions={options}/>
        ),
        [data, options]
      )}
      </HitchBox>
    </HitchBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Typechecking props for the GradientLineChart
GradientLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.objectOf(PropTypes.array).isRequired,
  options: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default GradientLineChart;
