
/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React from 'react';
import { Card, Stack } from '@mui/material';
import HitchBox from 'components/HitchBox';
import HitchTypography from 'components/HitchTypography';
import colors from 'assets/theme/base/colors';
import { FaEllipsisH } from 'react-icons/fa';
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';

function ReferralTracking() {
	const { info, gradients } = colors;
	const { cardContent } = gradients;

	return (
		<Card>
			<HitchBox sx={{ width: '100%' }}>
				<HitchBox
					display='flex'
					alignItems='center'
					justifyContent='space-beetween'
					sx={{ width: '100%' }}
					mb='40px'>
					<HitchTypography variant='lg' color='white' mr='auto'>
						تتبع الإحالة
					</HitchTypography>
					<HitchBox
						display='flex'
						justifyContent='center'
						alignItems='center'
						bgColor='#22234B'
						sx={{ width: '37px', height: '37px', cursor: 'pointer', borderRadius: '12px' }}>
						<FaEllipsisH color={info.main} size='18px' />
					</HitchBox>
				</HitchBox>
				<HitchBox
					display='flex'
					sx={({ breakpoints }) => ({
						[breakpoints.up('xs')]: {
							flexDirection: 'column',
							gap: '16px',
							justifyContent: 'center',
							alignItems: 'center'
						},
						[breakpoints.up('md')]: {
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}
					})}>
					<HitchBox
						direction='column'
						spacing='20px'
						width='500px'
						maxWidth='50%'
						sx={({ breakpoints }) => ({
							mr: 'auto',
							[breakpoints.only('md')]: {
								mr: '75px'
							},
							[breakpoints.only('xl')]: {
								width: '500px',
								maxWidth: '38%'
							}
						})}>
						<HitchBox
							display='flex'
							width='220px'
							p='20px 22px'
							flexDirection='column'
							mb='20px'
							sx={({ breakpoints }) => ({
								background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
								borderRadius: '20px',
								[breakpoints.up('xl')]: {
									maxWidth: '110px !important'
								},
								[breakpoints.up('xxl')]: {
									minWidth: '180px',
									maxWidth: '100% !important'
								}
							})}>
							<HitchTypography color='text' variant='button' fontWeight='regular' mb='5px'>
								مدعو
							</HitchTypography>
							<HitchTypography color='white' variant='lg' fontWeight='bold'>
								145 شخصا
							</HitchTypography>
						</HitchBox>
						<HitchBox
							display='flex'
							width='220px'
							p='20px 22px'
							flexDirection='column'
							sx={({ breakpoints }) => ({
								background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
								borderRadius: '20px',
								[breakpoints.up('xl')]: {
									maxWidth: '110px !important'
								},
								[breakpoints.up('xxl')]: {
									minWidth: '180px',
									maxWidth: '100% !important'
								}
							})}>
							<HitchTypography color='text' variant='button' fontWeight='regular' mb='5px'>
								علاوة
							</HitchTypography>
							<HitchTypography color='white' variant='lg' fontWeight='bold'>
								1,465
							</HitchTypography>
						</HitchBox>
					</HitchBox>
					<HitchBox sx={{ position: 'relative', display: 'inline-flex' }}>
						<CircularProgress
							variant='determinate'
							value={70}
							size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
							color='success'
						/>
						<HitchBox
							sx={{
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<HitchBox display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
								<HitchTypography color='text' variant='button' mb='4px'>
									أمان
								</HitchTypography>
								<HitchTypography color='white' variant='d5' fontWeight='bold' mb='4px'>
									9.3
								</HitchTypography>
								<HitchTypography color='text' variant='button'>
									مجموع النقاط
								</HitchTypography>
							</HitchBox>
						</HitchBox>
					</HitchBox>
				</HitchBox>
			</HitchBox>
		</Card>
	);
}

export default ReferralTracking;
