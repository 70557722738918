
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";
import { Link } from "react-router-dom";

function GitHubRuleDetailsHeader({ruleId}) {
    return (
        <HitchBox p={3} pl={0} lineHeight={1}>
        <HitchTypography variant="h5" fontWeight="medium" color="white">
            GitHub Rule Details
        </HitchTypography>
        <HitchTypography variant="button" fontWeight="regular" color="text">
            Rule: {ruleId}
        </HitchTypography>
        <HitchBox mt={2}>
            <HitchButton
                variant="outlined"
                color="white"
                component={Link}
                to="/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRules"
            >
                Back To Rules
            </HitchButton>
        </HitchBox>
    </HitchBox>
    );
}

export default GitHubRuleDetailsHeader;