import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchButton from "components/HitchButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import Footer from "examples/Footer";

// EditProduct page components
import ProductImage from "layouts/ecommerce/products/edit-product/components/ProductImage";
import ProductInfo from "layouts/ecommerce/products/edit-product/components/ProductInfo";
import ProductUpload from "layouts/ecommerce/products/edit-product/components/ProductUpload";
import Socials from "layouts/ecommerce/products/edit-product/components/Socials";
import Pricing from "layouts/ecommerce/products/edit-product/components/Pricing";

function EditProduct() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <HitchBox my={3} mb="30px">
        <HitchBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <HitchTypography variant="h4" fontWeight="medium" color="white">
                Make the changes below
              </HitchTypography>
              <HitchBox mt={1} mb={2}>
                <HitchTypography variant="body2" color="text">
                  We’re constantly trying to express ourselves and actualize our dreams. If you have
                  the opportunity to play.
                </HitchTypography>
              </HitchBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <HitchBox display="flex" justifyContent="flex-end">
                <HitchButton variant="contained" color="info">
                  save
                </HitchButton>
              </HitchBox>
            </Grid>
          </Grid>
        </HitchBox>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid container item xs={12} lg={5} spacing={3} sx={{ height: "100%" }}>
            <Grid item xs={12}>
              <ProductImage />
            </Grid>
            <Grid item xs={12} sx={{ height: "100%" }}>
              <Socials />
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={7} spacing={3}>
            <Grid item xs={12}>
              <ProductInfo />
            </Grid>
            <Grid item xs={12}>
              <Pricing />
            </Grid>
            <Grid item xs={12}>
              <ProductUpload />
            </Grid>
          </Grid>
        </Grid>
      </HitchBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditProduct;
