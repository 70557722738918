import { useState } from "react";
import Container from "@mui/material/Container";
import HitchBox from "components/HitchBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import HomepageHeader from "./HomepageHeader";
import HomepageFooter from "./HomepageFooter";
import HitchTypography from "components/HitchTypography";




function Homepage() {
    const [tabValue, setTabValue] = useState(0);
    const [prices, setPrices] = useState(["59", "89", "99"]);

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);

        if (event.currentTarget.id === "annual") {
            setPrices(["119", "159", "399"]);
        } else {
            setPrices(["59", "89", "99"]);
        }
    };

    return (
        <PageLayout>
            <HomepageHeader tabValue={tabValue} tabHandler={handleSetTabValue} />
            <Container>
                <HitchBox mt={8}>
                    <HitchTypography fontSize={32} color="white" fontWeight="bold">
                        What should we build?
                    </HitchTypography>
                </HitchBox>
                {/* <PricingCards prices={prices} />
          <TrustedBrands /> 
          <Faq />*/}
            </Container>
            <HitchBox mt={8}>
                <HomepageFooter />
            </HitchBox>
        </PageLayout>
    );
}

export default Homepage;