
// FILE: /UI/hitch-ai-ui-2/src/examples/Cards/StatisticsCards/MiniStatisticsCard/index.js

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import colors from "assets/theme/base/colors";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import PropTypes from "prop-types";

function MiniStatisticsCard(
  { 
    bgColor = "white", 
    title = {
      fontWeight: "medium",
      text: "",
    }, 
    count, 
    percentage = {
      color: "success",
      text: "",
    }, 
    icon, 
    direction = "right" 
  }
) {
  const { info } = colors;

  return (
    <Card sx={{ padding: "18px 22px" }}>
      <HitchBox>
        <HitchBox>
          <Grid container alignItems="center">
            {direction === "left" ? (
              <Grid item>
                <HitchBox
                  bgColor={info}
                  color="#fff"
                  width="45px"
                  height="45px"
                  borderRadius="lg"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  {icon}
                </HitchBox>
              </Grid>
            ) : null}
            <Grid item xs={8}>
              <HitchBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <HitchTypography
                  variant="caption"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </HitchTypography>
                <HitchTypography
                  variant="subtitle1"
                  fontWeight="bold"
                  color="white"
                  sx={({ breakpoints }) => ({
                    [breakpoints.only("lg")]: {
                      fontSize: "16px",
                    },
                  })}
                >
                  {count}{" "}
                  <HitchTypography
                    variant="button"
                    color={percentage.color}
                    fontWeight="bold"
                    sx={({ breakpoints }) => ({
                      [breakpoints.only("lg")]: {
                        fontSize: "10px",
                      },
                      [breakpoints.only("xl")]: {
                        fontSize: "12px",
                      },
                    })}
                  >
                    {percentage.text}
                  </HitchTypography>
                </HitchTypography>
              </HitchBox>
            </Grid>
            {direction === "right" ? (
              <Grid item xs={4}>
                <HitchBox
                  bgColor="#00B3B3"
                  color="white"
                  width="45px"
                  height="45px"
                  marginLeft="auto"
                  borderRadius="lg"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  {icon}
                </HitchBox>
              </Grid>
            ) : null}
          </Grid>
        </HitchBox>
      </HitchBox>
    </Card>
  );
}

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default MiniStatisticsCard;
