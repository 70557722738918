
// FILE:/UI/hitch-ai-ui-2/src/hitch/bot/BotFileManagement.js

import React, { useEffect } from 'react';
import { Grid, Divider, FormControlLabel, Card } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import HitchBox from 'components/HitchBox';
import HitchTypography from 'components/HitchTypography';
import VuiSwitch from "components/VuiSwitch";
import VuiSelect from "components/VuiSelect";
import StoreDocumentsGrid from './StoreDocumentsGrid';
import HitchSelectSimple from 'components/HitchSelectSimple';

function BotFileManagement({
    values,
    vectorStores = [],
    setFieldValue,
    handleFileSearchChange,
    handleVectorStoreChange,
    editMode,
    expandedSection,
    setExpandedSection,
}) {
    const theme = useTheme();
    const vectorStoresArray = Array.isArray(vectorStores) ? vectorStores : [];
    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 1051,
            position: 'absolute',
        }),
        control: (provided) => ({
            ...provided,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            borderColor: theme.palette.divider,
            '&:hover': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused': {
                borderColor: theme.palette.primary.main,
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme.palette.text.primary,
        }),
    };

    useEffect(() => {
        if (values) {
            console.log('values', values);
        }
    }, [values]);

    return (
        <HitchBox>

            {/* Header for File Management & Storage Section */}
            <HitchBox p={2}>
                <HitchTypography variant="lg" fontWeight="bold" color="white">
                    File Management & Storage
                </HitchTypography>
            </HitchBox>

            {/* File Management Content */}
            <Grid container spacing={3} p={2}>
                <Grid item xs={12}>
                    {editMode && (
                        <FormControlLabel
                            control={
                                <VuiSwitch
                                    checked={values?.fileSearch}
                                    onChange={(event) => handleFileSearchChange(event, setFieldValue)} // Pass setFieldValue here
                                    name="fileSearch"
                                    color="primary"
                                    sx={{ color: theme.palette.primary.main }}
                                />
                            }
                            label={
                                <HitchTypography variant="body2" color="white">
                                    Enable Hitch Document Storage
                                </HitchTypography>
                            }
                            sx={{ ml: 0, display: 'flex', alignItems: 'center' }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {values?.fileSearch && (
                        <>
                            {editMode ? (
                                <HitchBox mb={2}>
                                    <HitchSelectSimple
                                        value={values?.selectedVectorStore}
                                        label="Select a Hitch Document Storage"
                                        options={vectorStoresArray.map((option) => ({
                                            label: option.name,
                                            value: option.id,
                                        }))}
                                        placeholder="Select Storage"
                                        onChange={(e) => handleVectorStoreChange(e)}
                                        isClearable
                                    />
                                </HitchBox>
                            ) : (
                                <HitchTypography color="white" variant='h5'>
                                    Repository: {values?.selectedVectorStore ? vectorStoresArray.find(store => store.id === values.selectedVectorStore)?.name : '--'}
                                </HitchTypography>
                            )}

                            <StoreDocumentsGrid
                                path={values?.selectedVectorStore}
                                handleUpload={(event) => console.log("Handle upload", event)}
                                handleView={(id) => console.log("Handle view", id)}
                                handleDownload={(id) => console.log("Handle download", id)}
                            />

                        </>

                    )}
                </Grid>
            </Grid>
        </HitchBox>
    );
}

export default BotFileManagement;
