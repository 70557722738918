
// FILE: /UI/hitch-ai-ui-2/src/components/HitchAlert/index.js

import { useState } from "react";
import PropTypes from "prop-types";
import Fade from "@mui/material/Fade";
import HitchBox from "components/HitchBox";
import HitchAlertRoot from "components/HitchAlert/HitchAlertRoot";
import HitchAlertCloseIcon from "components/HitchAlert/HitchAlertCloseIcon";

function HitchAlert({ color = "info", dismissible = false, children, ...rest }) {
  const [alertStatus, setAlertStatus] = useState("mount");

  const handleAlertStatus = () => setAlertStatus("fadeOut");

  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
      <HitchAlertRoot ownerState={{ color }} {...rest}>
        <HitchBox display="flex" alignItems="center" color="white">
          {children}
        </HitchBox>
        {dismissible ? (
          <HitchAlertCloseIcon onClick={mount ? handleAlertStatus : null}>&times;</HitchAlertCloseIcon>
        ) : null}
      </HitchAlertRoot>
    </Fade>
  );

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

// Typechecking props of the HitchAlert
HitchAlert.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  dismissible: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default HitchAlert;
