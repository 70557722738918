
import { Icon, IconButton, Menu } from "@mui/material";
import VuiBadge from "components/VuiBadge";
import NotificationItem from "examples/Items/NotificationItem";
import {
    navbarIconButton,
} from "hitch/dashboard/styles";
import { useEffect, useState } from "react";
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { hitchUser, useHubConnections } from "context/HubConnectionsProvider";
import UserNotificationItem from "./UserNotificationItem";

function UserNotifications() {
    const [notificationList, setNotificationList] = useState([]);

    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser,
        isConnectionReady
    } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("c5bee19d-e171-4a6d-ba62-701d623e6183", onNotificationsReceived);

            hitchDashCommand('user', 'notification', 'active', {});
            // sendTestNotification();

            return () => {
                dashboardConnection.off("c5bee19d-e171-4a6d-ba62-701d623e6183", onNotificationsReceived);
            };
        }
    }, [dashboardConnection, isConnectionReady]);

    function sendTestNotification() {
        hitchDashCommand('user', 'notification', 'issue', {
            theServiceId: hitchUser.userId,
            theUserId: hitchUser.userId,
            theMessage: 'This is a test message.'
        });
    }

    function onDeleteNotification(notificationId) {
        setNotificationList(prevList => prevList.filter(notification => notification.id !== notificationId));
        hitchDashCommand('user', 'notification', 'clear', {
            notificationId: notificationId
        });
    }

    useEffect(() => {
        if (notificationList) {
            console.log('notificationList', notificationList)
        }
    }, [notificationList]);

    const [openMenu, setOpenMenu] = useState(false);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    function onNotificationsReceived(data) {
        console.log('onNotificationsReceived', data);
        if(data === 'An Error has occurred') {
            return;
        }
        setNotificationList(data);
    }

    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >
            {notificationList?.map(notification => {
                const createdDate = new Date(`${notification.created}Z`); // Parse the UTC date
    
                return (
                    <UserNotificationItem
                        key={notification.id}
                        hitchId={notification.serviceId}
                        title={[notification.message]}
                        date={createdDate.toLocaleString()} // Convert to local time
                        onClick={handleCloseMenu}
                        onDelete={() => onDeleteNotification(notification.id)}
                        theLink={notification.link}
                    />
                );
            })}
        </Menu>
    );


    return (
        <>
            <IconButton
                size="small"
                color="white"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
            >
                <VuiBadge
                    color="error"
                    size="xs"
                    badgeContent={notificationList?.length} // Show the number of notifications
                    invisible={notificationList ? notificationList.length === 0 : true} // Hide badge if no notifications
                >
                    <Icon>notifications</Icon>
                </VuiBadge>
            </IconButton>
            {renderMenu()}
        </>
    );
}

export default UserNotifications;