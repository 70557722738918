// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/training/index.js

import Card from "@mui/material/Card"; 
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function SosTraining() {
    const cols = [
        { accessor: 'trainingName', Header: 'Training Name', width: '25%' },
        { accessor: 'trainingId', Header: 'Training ID', width: '10%' },
        { accessor: 'status', Header: 'Status', width: '10%' },
        { accessor: 'assignedTo', Header: 'Assigned To', width: '20%' },
        { accessor: 'completionRate', Header: 'Completion Rate', width: '15%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '20%',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <HitchButton variant="outlined" color="info" size="small">View</HitchButton>
                    <HitchButton 
                        variant="outlined" 
                        color="warning" 
                        size="small" 
                        disabled={row.original.status !== 'Active'}
                    >
                        Edit
                    </HitchButton>
                    <HitchButton 
                        variant="outlined" 
                        color="error" 
                        size="small" 
                        disabled={row.original.status !== 'Active'}
                    >
                        Archive
                    </HitchButton>
                </div>
            )
        }
    ];

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);

    const [searchValue, setSearchValue] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [audienceFilter, setAudienceFilter] = useState('');

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    useEffect(() => {
        const mockTrainings = [
            { trainingName: 'Training A', trainingId: '101', status: 'Active', assignedTo: 'IT Department', completionRate: '85%' },
            { trainingName: 'Training B', trainingId: '102', status: 'Archived', assignedTo: 'All Users', completionRate: '100%' },
            { trainingName: 'Training C', trainingId: '103', status: 'Active', assignedTo: 'HR Department', completionRate: '60%' },
            { trainingName: 'Training D', trainingId: '104', status: 'Active', assignedTo: 'Security Team', completionRate: '45%' }
        ];

        let filtered = [...mockTrainings];

        if (searchValue) {
            filtered = filtered.filter(item =>
                item.trainingName.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.trainingId.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
        if (statusFilter) {
            filtered = filtered.filter(item => item.status === statusFilter);
        }
        if (audienceFilter) {
            filtered = filtered.filter(item => item.assignedTo === audienceFilter);
        }

        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        filtered = filtered.slice(start, end);
        setList(filtered);
    }, [page, perPage, searchValue, statusFilter, audienceFilter]);

    useEffect(() => {
        setDataTableData(prev => ({
            ...prev,
            rows: list
        }));
    }, [list]);

    function onPageChange(newPage, newPerPage) {
        setPage(newPage);
        setPerPage(newPerPage);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Training and Analysis (MOCKED)
                                <HitchButton variant="outlined" color="white" onClick={(e) => navigate('./requirements')}>Requirements</HitchButton>
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Status"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Archived">Archived</MenuItem>
                                </TextField>
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Audience"
                                    value={audienceFilter}
                                    onChange={(e) => setAudienceFilter(e.target.value)}
                                    style={{ width: '200px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="IT Department">IT Department</MenuItem>
                                    <MenuItem value="All Users">All Users</MenuItem>
                                    <MenuItem value="HR Department">HR Department</MenuItem>
                                    <MenuItem value="Security Team">Security Team</MenuItem>
                                </TextField>
                                <HitchButton variant="outlined" color="white">Create Training</HitchButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={dataTableData}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={onPageChange}
                                paginated={true}
                                page={page}
                            />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosTraining;
