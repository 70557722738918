
import { Grid } from "@mui/material";
import CustomTabPanel from "components/CustomTabPanel";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchSwitch from "components/HitchSwitch";
import HitchFormField from "components/HitchFormField";

function BotAiDeployment({
    editMode,
    tab,
    values,
}) {
    return (
        <CustomTabPanel value={tab} index={5}>
            <HitchBox pl={0} lineHeight={1}>
                <HitchTypography variant="h5" fontWeight="medium" color="white">
                    Deployment
                </HitchTypography>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <HitchSwitch
                            label="Allow Anonymous"
                            name="allowAnonymous"
                            onLabel="Allow"
                            offLabel="Disallow"
                            editMode={editMode}
                        />
                    </Grid>
                    {values?.allowAnonymous && (
                        <Grid item xs={12} lg={6}>
                            <HitchFormField label="Deploy Code" name="deployCode" editMode={editMode} />
                        </Grid>
                    )}
                </Grid>
            </HitchBox>
        </CustomTabPanel>
    );
}

export default BotAiDeployment;