
// FILE: /UI/hitch-ai-ui-2/src/components/HitchBox/index.js

import { forwardRef } from "react";
import PropTypes from "prop-types";
import HitchBoxRoot from "components/HitchBox/HitchBoxRoot";

const HitchBox = forwardRef(
  (
    {
      variant = "contained",
      bgColor = "transparent",
      color = "dark",
      opacity = 1,
      borderRadius = "none",
      shadow = "none",
      p =0,
      ...rest
    }, ref) => (
    <HitchBoxRoot
      {...rest}
      ref={ref}
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }}
    />
  )
);

// Typechecking props for the HitchBox
HitchBox.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
};

export default HitchBox;
