
import React, { useState, useEffect } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  FormControl,
} from "@mui/material";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";
import VuiInput from "components/VuiInput";
import VuiSelect from "components/VuiSelect";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useParams } from "react-router-dom";

function VectorSourceSelect({ selectedVectorStore, setSelectedVectorStore }) {
  const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();
  const [open, setOpen] = useState(false);
  const [newVectorStoreName, setNewVectorStoreName] = useState("");
  const [vectorStores, setVectorStores] = useState([]);
  const { repoId } = useParams();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    if (dashboardConnection && isConnectionReady && repoId) {
      dashboardConnection.on("9a60e99d-2ba1-4bd1-9365-1ee82597b483", setVectorStoreList);
      hitchDashCommand("openai", "vector-store", "list", { limit: 20 });
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("9a60e99d-2ba1-4bd1-9365-1ee82597b483", setVectorStoreList);
      }
    };
  }, [dashboardConnection, isConnectionReady, repoId]);

  function setVectorStoreList(inbound) {
    const stores = inbound.data.map((store) => ({
      ...store,
      name: store.name || "Unnamed Vector Store",
    }));
    setVectorStores(stores);
  }

  function handleVectorStoreChange(selectedOption) {
    if (selectedOption && selectedOption.value) {
      const name = vectorStores.find((a) => a.id === selectedOption.value)?.name || "";
      setSelectedVectorStore(selectedOption.value, name);
    }
  }

  function handleOpenNewVectorStoreModal() {
    setOpen(true);
  }

  function handleCloseNewVectorStoreModal() {
    setOpen(false);
    setNewVectorStoreName("");
  }

  function handleNewVectorStoreNameChange(event) {
    setNewVectorStoreName(event.target.value);
  }

  function handleCreateNewVectorStore() {
    if (!newVectorStoreName.trim()) {
      return; // Prevent empty vector store name
    }

    // Logic to create a new vector store
    hitchDashCommand("openai", "vector-store", "create", { name: newVectorStoreName }, () => {
      setNewVectorStoreName(""); // Clear the input field after creation
      setOpen(false);
    });
  }

  const vectorStoreOptions = vectorStores.map((store) => ({
    label: `${store.name} (${store.id})`,
    value: store.id,
  }));

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1051, // Ensure this is higher than the modal
      position: "absolute", // Use absolute positioning to align with the input
    }),
  };

  return (
    <Box mt={2}>
      <HitchBox mb={2}>
        <HitchTypography variant="caption" color="white" fontWeight="bold">
          Vector Store
        </HitchTypography>
        <VuiSelect
          input={<VuiInput />}
          value={vectorStoreOptions.find((option) => option.value === selectedVectorStore)}
          onChange={handleVectorStoreChange}
          placeholder="Select a Vector Store"
          fullWidth
          options={vectorStoreOptions}
          styles={customStyles}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          onBlur={(event) => event.preventDefault()} // Prevent blur events to avoid closing dropdown
        />
      </HitchBox>
      <HitchButton
        variant="contained"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={handleOpenNewVectorStoreModal}
      >
        Add Vector Store
      </HitchButton>
      <Dialog open={open} onClose={handleCloseNewVectorStoreModal}>
        <DialogTitle>Add New Vector Store</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the name for the new vector store.</DialogContentText>
          <HitchBox mt={2}>
            <VuiInput
              autoFocus
              label="Vector Store Name"
              placeholder="Enter vector store name"
              type="text"
              fullWidth
              value={newVectorStoreName}
              onChange={handleNewVectorStoreNameChange}
            />
          </HitchBox>
        </DialogContent>
        <DialogActions>
          <HitchButton onClick={handleCloseNewVectorStoreModal} variant="outlined" color="primary">
            Cancel
          </HitchButton>
          <HitchButton onClick={handleCreateNewVectorStore} variant="contained" color="primary">
            Create
          </HitchButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default VectorSourceSelect;
