
// FILE: C:\Users\drewj\src\Hitch.AI\UI\hitch-ai-ui-2\src\layouts\ecommerce\orders\order-list\components\CustomerCell\index.js

import PropTypes from "prop-types";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiAvatar from "components/VuiAvatar";

function CustomerCell({ image = "", name, color = "dark" }) {
  return (
    <HitchBox display="flex" alignItems="center">
      <HitchBox mr={1}>
        <VuiAvatar bgColor={color} src={image} alt={name} size="xs" />
      </HitchBox>
      <HitchTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
        {name}
      </HitchTypography>
    </HitchBox>
  );
}

// Typechecking props for the CustomerCell
CustomerCell.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
};

export default CustomerCell;
