
/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React from 'react';

import { Card, Stack } from '@mui/material';
import HitchBox from 'components/HitchBox';
import HitchTypography from 'components/HitchTypography';
import CircularProgress from '@mui/material/CircularProgress';
import { IoHappy } from 'react-icons/io5';
import colors from 'assets/theme/base/colors';
import linearGradient from 'assets/theme/functions/linearGradient';

const SatisfactionRate = () => {
	const { info, gradients } = colors;
	const { cardContent } = gradients;

	return (
		<Card sx={{ height: '340px' }}>
			<HitchBox display='flex' flexDirection='column'>
				<HitchTypography variant='lg' color='white' fontWeight='bold' mb='4px'>
					معدل الرضا
				</HitchTypography>
				<HitchTypography variant='button' color='text' fontWeight='regular' mb='20px'>
					من جميع المشاريع
				</HitchTypography>
				<HitchBox sx={{ alignSelf: 'center', justifySelf: 'center', zIndex: '-1' }}>
					<HitchBox sx={{ position: 'relative', display: 'inline-flex' }}>
						<CircularProgress variant='determinate' value={60} size={170} color='info' />
						<HitchBox
							sx={{
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<HitchBox
								sx={{
									background: info.main,
									transform: 'translateY(-50%)',
									width: '50px',
									height: '50px',
									borderRadius: '50%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}>
								<IoHappy size='30px' color='#fff' />
							</HitchBox>
						</HitchBox>
					</HitchBox>
				</HitchBox>
				<Stack
					sx={({ breakpoints }) => ({
						width: '90%',
						padding: '18px 22px',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						height: '82px',
						mx: 'auto',
						borderRadius: '20px',
						background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
						transform: 'translateY(-90%)',
						zIndex: '1000'
					})}>
					<HitchTypography color='text' variant='caption' display='inline-block' fontWeight='regular'>
						0%
					</HitchTypography>
					<HitchBox
						flexDirection='column'
						display='flex'
						justifyContent='center'
						alignItems='center'
						sx={{ minWidth: '80px' }}>
						<HitchTypography color='white' variant='h3'>
							95%
						</HitchTypography>
						<HitchTypography color='text' variant='caption' fontWeight='regular'>
							بناء على الإعجابات
						</HitchTypography>
					</HitchBox>
					<HitchTypography color='text' variant='caption' display='inline-block' fontWeight='regular'>
						100%
					</HitchTypography>
				</Stack>
			</HitchBox>
		</Card>
	);
};

export default SatisfactionRate;
