import PieChart from "examples/Charts/PieChart";
import HitchTypography from "components/HitchTypography";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function SosTaskHeader({ editMode, setEditMode, treeViewClick, theTask, parentId }) {
    const progressPercentage = ((theTask?.score || 0) / (theTask?.maxScore || 1)) * 100;
    const navigate = useNavigate();

    const pieChartData = [theTask?.score || 0, (theTask?.maxScore || 1) - (theTask?.score || 0)];
    const pieChartOptions = {
        labels: ["Compliant", "Non-Compliant"],
        colors: ["#004545", "#222222"],
        legend: {
            show: false,
        },
        tooltip: {
            enabled: false, // Disable the tooltip
        },
    };

    return (
        <HitchBox
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "16px",
                backgroundColor: "rgba(255, 255, 255, 0.05)", // Adjust background if needed
                borderRadius: "8px",
            }}
        >
            {/* Left: Pie Chart */}
            <HitchBox sx={{ width: "20%", minWidth: "150px" }}>
                <PieChart
                    title=""
                    description=""
                    data={pieChartData}
                    options={pieChartOptions}
                />
            </HitchBox>

            {/* Center: Task Details */}
            <HitchBox sx={{ flexGrow: 1, textAlign: "center" }}>
                <HitchTypography variant="h4" fontWeight="medium" color="white">
                    Task: {theTask?.name || "Loading..."}
                </HitchTypography>
                <HitchTypography variant="subtitle1" color="gray">
                    {theTask?.score || 0}/{theTask?.maxScore || 1} - {progressPercentage.toFixed(2)}%
                </HitchTypography>
            </HitchBox>

            {/* Right: Action Buttons */}
            <HitchBox sx={{ display: "flex", gap: 1 }}>
                {!editMode && (
                    <>
                        <HitchButton variant="outlined" color="warning" onClick={() => setEditMode(true)}>
                            Edit
                        </HitchButton>
                        <HitchButton variant="outlined" color="error" onClick={() => console.log("Archive Task")}>
                            Archive
                        </HitchButton>
                    </>
                )}
                {editMode && (
                    <HitchButton variant="outlined" color="error" onClick={() => setEditMode(false)}>
                        Cancel
                    </HitchButton>
                )}

                {parentId && (
                    <HitchButton variant="outlined" color="white" onClick={() => navigate(`/third/sos/tasks/${parentId}`)}>
                        Back
                    </HitchButton>
                )}

                <HitchButton variant="outlined" color="white" onClick={() => treeViewClick()}>
                    TreeView
                </HitchButton>
            </HitchBox>
        </HitchBox>
    );
}

export default SosTaskHeader;
