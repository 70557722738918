
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/services/index.js

import { Card } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function Services() {
    const cols = [
        { accessor: 'name', Header: 'Name', width: '25%' },
        { accessor: 'friendlyName', Header: 'Friendly Name', width: '25%' },
        { accessor: 'serviceDesc', Header: 'Description', width: '50%' }
    ];

    const [list, setList] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        dashboardConnection,
        hitchDashCommand, 
    } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                console.log('subscribing to events!');
                dashboardConnection.on("0394c8d7-6406-425e-8017-fe5ade9d406b", (data) => {
                    console.log('0394c8d7-6406-425e-8017-fe5ade9d406b', data);
                    setList(data);
                  });
                // Add other event subscriptions here
                hitchDashCommand('admin', 'service', 'list', { });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('0394c8d7-6406-425e-8017-fe5ade9d406b');
            };
        }
    }, [dashboardConnection]);

    const navigate = useNavigate();

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    useEffect(() => {
        if(list) {
            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI Services
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Management of Hitch.AI Services on the Hitch Platform.
                            </HitchTypography>
                        </HitchBox>
                        <HitchDataTable table={dataTableData} canSearch onRowClick={handleRowClick} />
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default Services;