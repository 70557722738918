import { useEffect } from "react";
import PropTypes from "prop-types";
import BasicPieChart from "examples/Charts/PieChart/BasicPieChart";
import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";

function PieChart({ title = "", description = "", data, options }) {
  useEffect(() => {
    console.log("Updated PieChart Data:", data);
  }, [data]);

  // Ensure data is an array of numbers
  const validData = Array.isArray(data) && data.every(num => typeof num === 'number') ? data : [0, 0];
  const validOptions = options || {};

  return (
    <Card>
      <HitchBox>
        {title || description ? (
          <HitchBox px={1} pt={1}>
            {title && (
              <HitchBox mb={1}>
                <HitchTypography variant="lg" color="white">
                  {title}
                </HitchTypography>
              </HitchBox>
            )}
            <HitchBox mb={2}>
              <HitchTypography component="div" variant="button" fontWeight="regular" color="text">
                {description}
              </HitchTypography>
            </HitchBox>
          </HitchBox>
        ) : null}
        <HitchBox sx={{ minHeight: "300px" }}>
          <BasicPieChart pieChartData={validData} pieChartOptions={validOptions} />
        </HitchBox>
      </HitchBox>
    </Card>
  );
}

// Typechecking props for the PieChart
PieChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  options: PropTypes.object.isRequired,
};

export default PieChart;
