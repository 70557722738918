
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiEditor from "components/VuiEditor";
import VuiSelect from "components/VuiSelect";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

function ProductInfo() {
  const [editorValue, setEditorValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br>"
  );

  return (
    <HitchBox>
      <HitchTypography variant="h5" color="white">
        Product Information
      </HitchTypography>
      <HitchBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField type="text" label="name" placeholder="eg. Off-White" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField type="text" label="weight" placeholder="eg. 42" />
          </Grid>
        </Grid>
      </HitchBox>
      <HitchBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <HitchBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <HitchTypography component="label" variant="caption" fontWeight="bold" color="white">
                Description&nbsp;&nbsp;
                <HitchTypography variant="caption" fontWeight="regular" color="text">
                  (optional)
                </HitchTypography>
              </HitchTypography>
            </HitchBox>
            <VuiEditor value={editorValue} onChange={setEditorValue} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HitchBox mb={3}>
              <HitchBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <HitchTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  Category
                </HitchTypography>
              </HitchBox>
              <VuiSelect
                defaultValue={{ value: "clothing", label: "Clothing" }}
                options={[
                  { value: "clothing", label: "Clothing" },
                  { value: "electronics", label: "Electronics" },
                  { value: "furniture", label: "Furniture" },
                  { value: "others", label: "Others" },
                  { value: "real estate", label: "Real Estate" },
                ]}
              />
            </HitchBox>
            <HitchBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <HitchTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                Size
              </HitchTypography>
            </HitchBox>
            <VuiSelect
              defaultValue={{ value: "medium", label: "Medium" }}
              options={[
                { value: "extra large", label: "Extra Large" },
                { value: "extra small", label: "Extra Small" },
                { value: "large", label: "Large" },
                { value: "medium", label: "Medium" },
                { value: "small", label: "Small" },
              ]}
            />
          </Grid>
        </Grid>
      </HitchBox>
    </HitchBox>
  );
}

export default ProductInfo;
