
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/bot/BotBasics.js 

import React from 'react';
import { Grid } from "@mui/material";
import CustomTabPanel from "components/CustomTabPanel";
import HitchFormField from "components/HitchFormField";
import HitchSwitch from "components/HitchSwitch";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";

function BotBasics({ editMode, handleChange, setFieldValue, tab, values }) {
    return (
        <CustomTabPanel value={tab} index={0}>
            <HitchBox pl={0} lineHeight={1}>
                <HitchTypography variant="h5" fontWeight="medium" color="white">
                    Bot Basics
                </HitchTypography>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <HitchFormField label="Bot Name" name="name" editMode={editMode} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <HitchSwitch
                            label="Enabled"
                            name="enabled"
                            onLabel="Enabled"
                            offLabel="Disabled"
                            editMode={editMode}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <HitchFormField label="Description" name="description" editMode={editMode} />
                    </Grid>
                </Grid>
            </HitchBox>
        </CustomTabPanel>
    );
}

export default BotBasics;