
import React, { useEffect, useState } from "react";
import HitchDisplayField from "components/HitchDisplayField";
import HitchSelect from "components/HitchSelect";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useFormikContext } from "formik";
import HitchBox from "components/HitchBox";

function HitchSFOSelector({
    value,
    onChange,
    readOnly = false,
    useFormik = false,
    fixedServiceId = null,
    serviceIdField = 'serviceId',
    featureIdField = 'featureId',
    operationIdField = 'operationId'
}) {
    const { user } = useHubConnections();
    const formikContext = useFormik ? useFormikContext() : null;

    const [services, setServices] = useState([]);
    const [features, setFeatures] = useState([]);
    const [operations, setOperations] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [selectedOperation, setSelectedOperation] = useState(null);

    // Fetch data helper
    const fetchData = async (endpoint, setData, mapFunction) => {
        if (!user?.id_token) return;

        try {
            const url = `${window._env_.HITCH_API}/api/routes${endpoint}`;
            const response = await fetch(url, {
                headers: { Authorization: `Bearer ${user.id_token}` },
            });

            if (response.ok) {
                const data = await response.json();
                setData(data.map(mapFunction));
                console.log(`Data fetched from ${endpoint}`, data);
            } else {
                throw new Error(`Failed to fetch data from ${url}`);
            }
        } catch (error) {
            console.error(`Error fetching data from ${endpoint}:`, error);
        }
    };

    // Initialize state based on incoming value or Formik context
    useEffect(() => {
        console.log("Initial value", value);
        console.log("Using Formik?", useFormik);
        console.log("Formik Context Values", formikContext?.values);

        const source = useFormik ? formikContext?.values : value;

        if (source) {
            const serviceId = source.outputService || fixedServiceId || source.serviceId || null;
            const featureId = source.outputFeature || source.featureId || null;
            const operationId = source.outputOperation || source.operationId || null;

            setSelectedService(serviceId);
            setSelectedFeature(featureId);
            setSelectedOperation(operationId);

            console.log("Initialized Service, Feature, Operation:", {
                serviceId,
                featureId,
                operationId,
            });
        }
    }, [value, formikContext?.values, useFormik, fixedServiceId]);

    // Fetch services on mount if not fixed
    useEffect(() => {
        if (user && !fixedServiceId) {
            fetchData("", setServices, (item) => ({
                label: item.system,
                value: item.serviceId,
            }));
        }
    }, [user, fixedServiceId]);

    // Fetch features when service changes
    useEffect(() => {
        if (selectedService) {
            fetchData(
                `/${selectedService}`,
                (newFeatures) => {
                    setFeatures(newFeatures);

                    // Validate existing feature
                    if (!newFeatures.some((f) => f.value === selectedFeature)) {
                        setSelectedFeature(null);
                        setOperations([]);
                    }
                },
                (item) => ({
                    label: item.operationType,
                    value: item.featureId,
                })
            );
        }
    }, [selectedService]);

    // Fetch operations when feature changes
    useEffect(() => {
        if (selectedFeature) {
            fetchData(
                `/${selectedService}/${selectedFeature}`,
                (newOperations) => {
                    setOperations(newOperations);

                    // Validate existing operation
                    if (!newOperations.some((o) => o.value === selectedOperation)) {
                        setSelectedOperation(null);
                    }
                },
                (item) => ({
                    label: item.operationSubtype,
                    value: item.operationId,
                })
            );
        }
    }, [selectedFeature, selectedService]);

    // Handle changes
    const handleServiceChange = (selected) => {
        const serviceId = selected?.value || null;
        setSelectedService(serviceId);
        setFeatures([]);
        setOperations([]);
        setSelectedFeature(null);
        setSelectedOperation(null);

        if (onChange) onChange({ serviceId, featureId: null, operationId: null });
        if (useFormik) {
            formikContext.setFieldValue(serviceIdField, serviceId);
            formikContext.setFieldValue(featureIdField, null);
            formikContext.setFieldValue(operationIdField, null);
        }
    };

    const handleFeatureChange = (selected) => {
        const featureId = selected?.value || null;
        setSelectedFeature(featureId);  
        setOperations([]);
        setSelectedOperation(null);

        if (onChange) onChange({ serviceId: selectedService, featureId, operationId: null });
        if (useFormik) {
            formikContext.setFieldValue(featureIdField, featureId);
            formikContext.setFieldValue(operationIdField, null);
        }
    };

    const handleOperationChange = (selected) => {
        const operationId = selected?.value || null;
        setSelectedOperation(operationId);

        if (onChange) onChange({ serviceId: selectedService, featureId: selectedFeature, operationId });
        if (useFormik) {
            formikContext.setFieldValue(operationIdField, operationId);
        }
    };

    useEffect(() => {
        if (selectedService && features.length > 0) {
            const validFeature = features.find((f) => f.value === selectedFeature);
            if (!validFeature) {
                setSelectedFeature(null);
                setOperations([]);
            }
        }
    }, [features, selectedService]);
    
    useEffect(() => {
        if (selectedFeature && operations.length > 0) {
            const validOperation = operations.find((o) => o.value === selectedOperation);
            if (!validOperation) {
                setSelectedOperation(null);
            }
        }
    }, [operations, selectedFeature]);
    

    return (
        <HitchBox p={3}>
            {readOnly ? (
                <>
                    <HitchDisplayField
                        label="Service"
                        value={services.find((s) => s.value === selectedService)?.label || "N/A"}
                    />
                    <HitchDisplayField
                        label="Feature"
                        value={features.find((f) => f.value === selectedFeature)?.label || "N/A"}
                    />
                    <HitchDisplayField
                        label="Operation"
                        value={operations.find((o) => o.value === selectedOperation)?.label || "N/A"}
                    />
                </>
            ) : (
                <>
                    {fixedServiceId ? (
                        <HitchDisplayField
                            label="Service"
                            value={services.find((s) => s.value === fixedServiceId)?.label || "N/A"}
                        />
                    ) : (
                        <HitchSelect
                            name={serviceIdField}
                            label="Services"
                            options={services}
                            value={services.find((s) => s.value === selectedService) || null}
                            placeholder="Select Service"
                            onChange={handleServiceChange}
                            editMode={true}
                        />
                    )}
                    <HitchSelect
                        name={featureIdField}
                        label="Feature"
                        options={features}
                        value={features.find((f) => f.value === selectedFeature) || null}
                        placeholder="Select Feature"
                        onChange={handleFeatureChange}
                        editMode={true}
                    />
                    <HitchSelect
                        name={operationIdField}
                        label="Operation"
                        options={operations}
                        value={operations.find((o) => o.value === selectedOperation) || null}
                        placeholder="Select Operation"
                        onChange={handleOperationChange}
                        editMode={true}
                    />
                </>
            )}
        </HitchBox>
    );
}

export default HitchSFOSelector;
