// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/administration/organization/Header/index.js


import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchButton from "components/HitchButton";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useState } from "react";
import { Formik, Form } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";
import TextField from '@mui/material/TextField';

function Header() {
    const { account, dashboardConnection, hitchDashCommand, isConnectionReady, errorSnack, infoSnack } = useHubConnections();
    const [editMode, setEditMode] = useState(false);

    function saveToService(values) {
        if (dashboardConnection && isConnectionReady) {
            const updatedAccount = {
                account: {
                    ...account,
                    name: values.name,
                    description: values.description
                }
            };

            hitchDashCommand('account', 'profile', 'update', updatedAccount, () => {
                setEditMode(false);
                infoSnack('Data saved successfully.');
            });
        } else {
            errorSnack('Cannot Save, Something went wrong.');
        }
    }

    function cancelEdit() {
        setEditMode(false);
    }

    return (
        <HitchBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={({ breakpoints }) => ({
                flexDirection: "column",
                alignItems: "start",
                [breakpoints.up("md")]: {
                    flexDirection: "row",
                    alignItems: "center",
                },
            })}
        >
            <HitchBox
                sx={({ breakpoints }) => ({
                    mb: "20px",
                    width: "100%",  // Ensure full width for the container
                    [breakpoints.up("md")]: {
                        mb: "0px",
                    },
                })}
            >
                {!editMode ? (
                    <>
                        <HitchBox mb={1}>
                            <HitchTypography variant="h4" fontWeight="bold" color="white">
                                {account?.name}
                            </HitchTypography>
                        </HitchBox>
                        <HitchTypography
                            component="p"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            display="flex"
                        >
                            Description:
                            <HitchTypography
                                mx="5px"
                                component="p"
                                variant="button"
                                fontWeight="regular"
                                color="white"
                                sx={{ fontWeight: "bold" }}
                            >
                                {account?.description}
                            </HitchTypography>
                        </HitchTypography>
                        <HitchTypography
                            component="p"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ display: "flex" }}
                        >
                            Account Identifier:{" "}
                            <HitchTypography
                                ml="5px"
                                component="p"
                                variant="button"
                                fontWeight="regular"
                                color="white"
                                sx={{ fontWeight: "bold" }}
                            >
                                {" "}
                                {account?.id}
                            </HitchTypography>
                        </HitchTypography>
                    </>
                ) : (
                    <Formik
                        initialValues={{
                            name: account?.name || "",
                            description: account?.description || ""
                        }}
                        onSubmit={(values) => saveToService(values)}
                    >
                        <Form style={{ width: '100%' }}>
                            <HitchBox mb={2}>
                                <FormField
                                    name="name"
                                    label="Organization Name"
                                    as={TextField}
                                    fullWidth
                                    margin="normal"
                                />
                            </HitchBox>
                            <HitchBox mb={2}>
                                <FormField
                                    name="description"
                                    label="Description"
                                    as={TextField}
                                    fullWidth
                                    margin="normal"
                                />
                            </HitchBox>

                            <HitchBox display="flex" justifyContent="flex-end">
                                <HitchButton
                                    type="submit"
                                    variant="outlined"
                                    color="white"
                                    sx={({ palette: { white } }) => ({
                                        border: `1px solid ${white.main}`,
                                        color: white.main,
                                    })}
                                >
                                    Save
                                </HitchButton>
                                <HitchButton
                                    variant="outlined"
                                    color="white"
                                    sx={({ palette: { white } }) => ({
                                        border: `1px solid ${white.main}`,
                                        color: white.main,
                                        ml: 2,
                                    })}
                                    onClick={cancelEdit}
                                >
                                    Cancel
                                </HitchButton>
                            </HitchBox>
                        </Form>
                    </Formik>
                )}
            </HitchBox>

            {!editMode && (
                <HitchButton
                    variant="outlined"
                    color="white"
                    width="100px"
                    sx={({ palette: { white } }) => ({
                        border: `1px solid ${white.main}`,
                        color: white.main,
                    })}
                    onClick={() => setEditMode(true)}
                >
                    edit
                </HitchButton>
            )}
        </HitchBox>
    );
}

export default Header;
