
// FILE: /UI/hitch-ai-ui-2/src/examples/Cards/StatisticsCards/ComplexStatisticsCard/index.js

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import typography from "assets/theme/base/typography";
import whiteCurved from "assets/images/curved-images/reports-card-background.png";

function ComplexStatisticsCard(
  { 
    color = "dark", 
    icon, 
    count, 
    percentage, 
    dropdown = false 
  }
) {
  const { size } = typography;

  return (
    <Card sx={{ background: `url(${whiteCurved})`, backgroundSize: "cover" }}>
      <HitchBox>
        <Grid container>
          <Grid item xs={8}>
            <HitchBox
              width="50px"
              height="50px"
              mb="44px"
              bgColor="info"
              borderRadius="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              shadow="md"
              fontSize={size.xl}
            >
              {icon}
            </HitchBox>
            <HitchBox lineHeight={0}>
              <HitchTypography fontSize={16} fontWeight="bold" color="white">
                {count.number}
              </HitchTypography>
              <HitchTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                {count.label}
              </HitchTypography>
            </HitchBox>
          </Grid>
          <Grid item xs={4}>
            <HitchBox textAlign="right">
              {dropdown && (
                <HitchBox mb={9.5} lineHeight={1} color="white">
                  <Icon fontSize="default" onClick={dropdown.action} sx={{ cursor: "pointer" }}>
                    more_horiz
                  </Icon>
                  {dropdown.menu}
                </HitchBox>
              )}
              <HitchTypography variant="caption" fontWeight="medium" color="white" align="right">
                {percentage}
              </HitchTypography>
            </HitchBox>
          </Grid>
        </Grid>
      </HitchBox>
    </Card>
  );
}

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  count: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  percentage: PropTypes.string.isRequired,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexStatisticsCard;
