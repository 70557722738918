// FILE: /UI/hitch-ai-ui-2/src/hitch/dashboard/DashboardNavbar.js

import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import HitchBox from "components/HitchBox";
import VuiInput from "components/VuiInput";
import Breadcrumbs from "examples/Breadcrumbs";

import { isUserSignedIn } from "services/authService";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "hitch/dashboard/styles";

import {
  useHitchAIUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { debounce } from "@mui/material";
import UserLogin from "./UserLogin";
import UserNotifications from "./UserNotifications";

function DashboardNavbar({ absolute = false, light = false, isMini = false, titleOverride }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useHitchAIUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;

  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    setNavbarType(fixedNavbar ? "sticky" : "static");
  }, [fixedNavbar]);

  const navigate = useNavigate();

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => navigate('/administration/user');

  const formatRouteSegment = (segment) => {
    return segment.replace(/-/g, " ").replace(/^\w/, (c) => c.toUpperCase());
  };

  const checkUserSignIn = async () => {
    const signedIn = await isUserSignedIn();
    if (!signedIn) {
      // callback is current path... 
      navigate('/authentication/sign-in/basic'); // Redirect to login page if not signed in
    }
  };

  useEffect(() => {
    checkUserSignIn();
  }, []);


  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar: controller.transparentNavbar, absolute, light })}>

      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <HitchBox color="inherit" mb={{ xs: 0, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs
            icon="home"
            title={titleOverride ?? (route.length ? formatRouteSegment(route[route.length - 1]) : "Home")}
            route={route}
            light={light}
          />

          <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </HitchBox>
        {isMini ? null : (
          <HitchBox sx={(theme) => navbarRow(theme, { isMini })}>
            <HitchBox pr={1} maxWidth={{ sm: "175px", md: "100%" }}>
              <VuiInput
                placeholder="Type here..."
                icon={{ component: "search", direction: "left" }}
                sx={({ breakpoints }) => ({
                  [breakpoints.down("sm")]: { maxWidth: "80px" },
                  [breakpoints.only("sm")]: { maxWidth: "80px" },
                  backgroundColor: "info.main !important",
                })}
              />
            </HitchBox>
            <HitchBox color={light ? "white" : "inherit"}>
              <UserLogin />
              <IconButton size="small" color="white" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
                <Icon color="white">{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
              <IconButton size="small" color="white" sx={navbarIconButton} onClick={(e) => handleConfiguratorOpen()}>
                <Icon>settings</Icon>
              </IconButton>
              <UserNotifications />
            </HitchBox>
          </HitchBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
