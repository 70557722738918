import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchButton from "components/HitchButton";

function RankingList({ title, date, rankings }) {
  const renderRankings = rankings.map(({ color, icon, name, description, value }, key) => (
    <HitchBox key={name} component="li">
      <HitchBox display="flex" justifyContent="space-between" alignItems="center">
        <HitchBox display="flex" alignItems="center">
          <HitchBox mr={2}>
            <HitchButton
              variant="outlined"
              color={color}
              size="small"
              iconOnly
              circular
              sx={({ functions: { pxToRem } }) => ({
                width: pxToRem(34),
                minWidth: pxToRem(34),
                height: pxToRem(34),
                minHeight: pxToRem(34),
              })}
            >
              <Icon>{icon}</Icon>
            </HitchButton>
          </HitchBox>
          <HitchBox display="flex" flexDirection="column">
            <HitchTypography variant="button" color="white" fontWeight="medium" gutterBottom>
              {name}
            </HitchTypography>
            <HitchTypography variant="caption" color="text">
              {description}
            </HitchTypography>
          </HitchBox>
        </HitchBox>
        <HitchTypography variant="button" color={color} fontWeight="medium">
          {value}
        </HitchTypography>
      </HitchBox>
      {key === rankings.length - 1 ? null : (
        <Divider
          sx={{
            mt: 2,
            mb: 1,
          }}
        />
      )}
    </HitchBox>
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <HitchBox display="flex" justifyContent="space-between" mb="24px" alignItems="center">
        <HitchTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
          {title}
        </HitchTypography>
        <HitchTypography variant="button" color="text" fontWeight="regular" sx={{ display: "flex" }}>
          <Icon
            color="inherit"
            fontSize="small"
            sx={{
              mr: 0.75,
              mt: -0.125,
            }}
          >
            date_range
          </Icon>
          {date}
        </HitchTypography>
      </HitchBox>
      <HitchBox>
        <HitchBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {renderRankings}
        </HitchBox>
      </HitchBox>
    </Card>
  );
}

// Typechecking props for the RankingList
RankingList.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rankings: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RankingList;
