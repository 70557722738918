import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import HitchBox from "components/HitchBox";
import VuiDropzoneRoot from "components/VuiDropzone/VuiDropzoneRoot";
import { useHubConnections } from "context/HubConnectionsProvider";

function VuiDropzone({ options, onFileUploaded, path }) {
  const dropzoneRef = useRef();
  const { jwtToken } = useHubConnections();

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      const dropzone = new Dropzone(dropzoneRef.current, {
        ...options,
        headers: {
          Authorization: `Bearer ${jwtToken}`, // Set the Bearer token in the headers
        },
        init: function () {
          this.on("success", async (file, response) => {
            // Assuming response contains the GUID from the upload
            onFileUploaded(response, file); // Call the callback with the GUID
          });
          this.on("error", (file, errorMessage) => {
            console.error("Upload error:", errorMessage); // Log the error message
          });
          this.on("addedfile", (file) => {
            console.log("File added:", file);
          });
        },
      });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }

    // Create Dropzone only when jwtToken is available
    if (jwtToken) {
      createDropzone();
    } else {
      console.log("JWT Token not available yet.");
    }

    return () => removeDropzone();
  }, [options, onFileUploaded, path, jwtToken]);

  return (
    <VuiDropzoneRoot
      component="form"
      action={path}
      ref={dropzoneRef}
      className="form-control dropzone"
    >
      <HitchBox className="fallback">
        <HitchBox component="input" name="file" type="file" multiple />
      </HitchBox>
    </VuiDropzoneRoot>
  );
}

// Typechecking props for the VuiDropzone
VuiDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  onFileUploaded: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired, // Ensure path is required
};

export default VuiDropzone;