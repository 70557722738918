import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

function HitchTitle({ title, subTitle, icon, tooltip, action }) {
    return (
        <HitchBox display="flex" alignItems="center" justifyContent="space-between" p={3} pl={0} lineHeight={1}>
            {/* Left Section - Title & Subtitle */}
            <HitchBox>
                <HitchTypography variant="h5" fontWeight="medium" color="white">
                    {title}
                </HitchTypography>
                <HitchTypography variant="button" fontWeight="regular" color="text">
                    {subTitle}
                </HitchTypography>
            </HitchBox>

            {/* Right Section - Icon/Tooltip/Action (if provided) */}
            {icon && (
                <Tooltip title={tooltip || ""}>
                    <IconButton onClick={action} color="white">
                        {icon}
                    </IconButton>
                </Tooltip>
            )}
        </HitchBox>
    );
}

export default HitchTitle;
