import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";

// Vision UI Dashboard PRO React example components
import DefaultItem from "examples/Items/DefaultItem";

// React icons
import { FaWallet } from "react-icons/fa";
import { IoNotifications } from "react-icons/io5";

function NextEvents() {
  return (
    <Card sx={{ height: "100%" }}>
      <HitchBox pt={2} px={2}>
        <HitchTypography variant="h6" fontWeight="medium" color="white">
          Next events
        </HitchTypography>
      </HitchBox>
      <HitchBox p={2}>
        <DefaultItem
          icon={<FaWallet size="16px" color="white" />}
          title="Cyber Week"
          description="27 March 2020, at 12:30 PM"
        />
        <HitchBox mt={1.8}>
          <DefaultItem
            icon={<IoNotifications size="18px" color="white" />}
            color="primary"
            title="Meeting with Marry"
            description="24 March 2020, at 10:00 PM"
          />
        </HitchBox>
        <HitchBox mt={1.8}>
          <DefaultItem
            icon={<FaWallet size="16px" color="white" />}
            title="Cyber Week"
            description="27 March 2020, at 12:30 PM"
          />
        </HitchBox>
        <HitchBox mt={1.8}>
          <DefaultItem
            icon={<IoNotifications size="18px" color="white" />}
            color="primary"
            title="Meeting with Marry"
            description="24 March 2020, at 10:00 PM"
          />
        </HitchBox>
      </HitchBox>
    </Card>
  );
}

export default NextEvents;
