import { Grid } from "@mui/material";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";

function PolicySaveButtons({ setEditMode, editMode }) {

    return (
        <Grid item xs={12}>
            {editMode && (
                <HitchBox display="flex" gap={2}>
                    <HitchButton type="submit" variant="outlined" color="white">Save</HitchButton>
                    <HitchButton variant="contained" color="white" onClick={() => setEditMode(false)}>Cancel</HitchButton>
                </HitchBox>
            )}
        </Grid>
    );
}

export default PolicySaveButtons;