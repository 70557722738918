
import { Grid } from "@mui/material";
import HitchModal from "components/HitchModal";
import HitchButton from "components/HitchButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function HitchEditButtons({ deleteAction, editAction, parentLabel, parentRoute, editMode = false, cancelAction = () => {} }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    return (
        <Grid
            item
            xs={12}
            md={5}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
            }}
        >
            {!editMode && (
                <HitchButton
                    variant="outlined"
                    color="white"
                    onClick={editAction}
                >
                    Edit
                </HitchButton>
            )}

            {editMode && (
                <HitchButton
                    variant="outlined"
                    color="white"
                    onClick={cancelAction}
                >
                    Cancel
                </HitchButton>
            )}
            <HitchButton
                variant="contained"
                color="error"
                onClick={() => setOpen(true)}
            >
                Delete
            </HitchButton>
            <HitchButton
                variant="outlined"
                color="white"
                onClick={() => navigate(parentRoute)}
            >
                {parentLabel}
            </HitchButton>
            <HitchModal
                open={open}
                onClose={() => { }}
                title="Delete Warning"
                question="You're about to delete this.  Proceed?"
                yesLabel="Yes"
                noLabel="No"
                onYes={() => {
                    deleteAction();
                    setOpen(false);
                }}
                onNo={() => setOpen(false)}
            />
        </Grid>
    );
}

export default HitchEditButtons;