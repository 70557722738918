// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/examples/Sidenav/SidenavCard.js


import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import { AiFillStar } from "react-icons/ai";
import HitchButton from "components/HitchButton";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { card, cardContent, cardIconBox, cardIcon } from "examples/Sidenav/styles/sidenavCard";
import { useHitchAIUIController } from "context";
import colors from "assets/theme/base/colors";

function SidenavCard() {
  const [controller] = useHitchAIUIController();
  const { miniSidenav, sidenavColor, transparentSidenav } = controller;
  const { info } = colors;

  return (
    <Card sx={(theme) => card(theme, { miniSidenav })}>
      <CardContent sx={(theme) => cardContent(theme, { sidenavColor, transparentSidenav })}>
        <HitchBox
          bgColor="white"
          width="2rem"
          height="2rem"
          borderRadius="md"
          shadow="md"
          mb={2}
          sx={cardIconBox}
        >
          <AiFillStar color={info.main} />
        </HitchBox>
        <HitchBox lineHeight={1}>
          <HitchTypography variant="h6" color="white">
            Need help?
          </HitchTypography>
          <HitchBox mb={1.825} mt={-1}>
            <HitchTypography variant="caption" color="white" fontWeight="regular">
              Check out our support chat
            </HitchTypography>
          </HitchBox>
          <HitchButton
            component={Link}
            href="https://hitchsoftware.com"
            target="_blank"
            rel="noreferrer"
            size="small"
            sx={({ palette: { gradients, white }, functions: { linearGradient } }) => ({
              color: `${white.main} !important`,
              background: linearGradient(
                gradients.cardDark.main,
                gradients.cardDark.state,
                gradients.cardDark.deg
              ),
              "&:hover": {
                background: linearGradient(
                  gradients.cardDark.main,
                  gradients.cardDark.state,
                  gradients.cardDark.deg
                ),
              },
            })}
            fullWidth
          >
            Support Chat
          </HitchButton>
        </HitchBox>
      </CardContent>
    </Card>
  );
}

export default SidenavCard;
