import { Card, Grid, Stepper, Step, StepLabel } from "@mui/material";
import HitchTypography from "components/HitchTypography";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useState } from "react";

import Basics from "./basics";
import Organization from "./organization";
import Subscription from "./subscription";

function getSteps() {
    return ["Basics", "Organization", "Subscription"];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <Basics />;
        case 1:
            return <Organization />;
        case 2:
            return <Subscription />;
        default:
            return null;
    }
}

function Registration() {
    
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;

    const [registration, setRegistration] = useState({});

    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);


    return (
        <PageLayout>
            <HitchBox pt={3} pb={8}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <HitchBox mt={6} mb={1} textAlign="center">
                            <HitchBox mb={1}>
                                <HitchTypography variant="h3" fontWeight="bold" color="white">
                                    Build Your Profile
                                </HitchTypography>
                            </HitchBox>
                            <HitchTypography variant="button" fontWeight="regular" color="white">
                                This information will let us know more about you.
                            </HitchTypography>
                        </HitchBox>

                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Card>
                            <HitchBox p={2}>
                                <HitchBox>
                                    {getStepContent(activeStep)}
                                    <HitchBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                        {activeStep === 0 ? (
                                            <HitchBox />
                                        ) : (
                                            <HitchButton variant="gradient" color="light" onClick={handleBack}>
                                                back
                                            </HitchButton>
                                        )}
                                        <HitchButton
                                            variant="contained"
                                            color="info"
                                            onClick={!isLastStep ? handleNext : undefined}
                                        >
                                            {isLastStep ? "send" : "next"}
                                        </HitchButton>
                                    </HitchBox>
                                </HitchBox>
                            </HitchBox>
                        </Card>
                    </Grid>
                </Grid>
            </HitchBox>
        </PageLayout>
    );
}

export default Registration;