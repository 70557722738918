import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import { useHubConnections } from "context/HubConnectionsProvider";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function SosAudits() {
    const cols = [
        { accessor: 'auditName', Header: 'Audit Name', width: '25%' },
        { accessor: 'auditStatus', Header: 'Status', width: '20%' },
        { accessor: 'scheduledDate', Header: 'Scheduled Date', width: '20%' },
        { accessor: 'owner', Header: 'Owner', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '15%',
            Cell: ({ row }) => (
                <HitchButton variant="outlined" color="info" size="small">View Details</HitchButton>
            )
        }
    ];

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const { dashboardConnection } = useHubConnections();

    useEffect(() => {
        const mockAudits = [
            { auditName: 'ISO 27001 Audit', auditStatus: 'Scheduled', scheduledDate: '2025-03-15', owner: 'Compliance Team' },
            { auditName: 'SOC 2 Type II', auditStatus: 'Completed', scheduledDate: '2025-01-10', owner: 'Security Team' },
            { auditName: 'Internal Security Review', auditStatus: 'In Progress', scheduledDate: '2025-02-20', owner: 'IT Department' },
        ];

        let filtered = mockAudits;
        if (searchValue) {
            filtered = filtered.filter(item => item.auditName.toLowerCase().includes(searchValue.toLowerCase()));
        }

        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        setList(filtered.slice(start, end));
    }, [page, perPage, searchValue]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                SOS Audits Management
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <HitchButton variant="outlined" color="white">Start New Audit</HitchButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={{ columns: cols, rows: list }}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={(newPage, newPerPage) => { setPage(newPage); setPerPage(newPerPage); }}
                                paginated={true}
                                page={page}
                            />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosAudits;
