
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Images
import team1 from "assets/images/avatar1.png";
import team2 from "assets/images/avatar2.png";
import team4 from "assets/images/avatar4.png";
import team5 from "assets/images/avatar5.png";
import bruceMars from "assets/images/avatar7.png";
import team6 from "assets/images/avatar8.png";
import workSpace from "assets/images/teams-image.png";
import VuiAvatar from "components/VuiAvatar";
// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import VuiInput from "components/VuiInput";
import HitchTypography from "components/HitchTypography";
import { useState } from "react";

function Post() {
  const [comments] = useState([
    {
      image: bruceMars,
      name: "michael lewis",
      text: "I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves!",
      like: 3,
      share: 2,
    },
    {
      image: team6,
      name: "jessica stones",
      text: "Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy.",
      like: 10,
      share: 1,
    },
  ]);

  const renderComments = comments.map(({ image, name, text, like, share }, key) => (
    <HitchBox key={name} display="flex" mt={key === 0 ? 0 : 3}>
      <HitchBox flexShrink={0}>
        <VuiAvatar src={image} alt={name} />
      </HitchBox>
      <HitchBox flexGrow={1} ml={2}>
        <HitchTypography
          variant="button"
          fontWeight="medium"
          color="white"
          textTransform="capitalize"
        >
          {name}
        </HitchTypography>
        <HitchBox mt={1} mb={2} lineHeight={0.75}>
          <HitchTypography variant="button" fontWeight="regular" color="text">
            {text}
          </HitchTypography>
        </HitchBox>
        <HitchBox display="flex" flexWrap="wrap" alignItems="center">
          <HitchBox display="flex" alignItems="center" mr={1}>
            <HitchTypography component="a" href="#" variant="body2" color="text">
              <Icon>thumb_up</Icon>&nbsp;
            </HitchTypography>
            <HitchTypography variant="button" fontWeight="regular" color="text">
              {like} likes
            </HitchTypography>
          </HitchBox>
          <HitchBox display="flex" alignItems="center">
            <HitchTypography component="a" href="#" variant="body2" color="text">
              <Icon>share</Icon>&nbsp;
            </HitchTypography>
            <HitchTypography variant="button" fontWeight="regular" color="text">
              {share} shares
            </HitchTypography>
          </HitchBox>
        </HitchBox>
      </HitchBox>
    </HitchBox>
  ));

  return (
    <Card>
      <HitchBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <VuiAvatar src={team4} alt="profile-image" variant="rounded" />
        <HitchBox mx={2} lineHeight={1}>
          <HitchTypography component="a" href="#" variant="button" color="white" fontWeight="regular">
            Esthera Jackson
          </HitchTypography>
          <HitchTypography component="div" variant="button" color="text" fontWeight="regular">
            3 days ago
          </HitchTypography>
        </HitchBox>
        <HitchBox ml={{ xs: 0, sm: "auto" }}>
          <HitchButton
            color="info"
            size="small"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp;
            <HitchTypography color="white" fontSize={10} textTransform="capitalise" fontWeight="bold">
              follow
            </HitchTypography>
          </HitchButton>
        </HitchBox>
      </HitchBox>
      <Divider light sx={{ my: "22px" }} />
      <HitchBox>
        <HitchBox mb={3}>
          <HitchTypography fontSize={14} fontWeight="regular" color="text">
            Personal profiles are the perfect way for you to grab their attention and persuade
            recruiters to continue reading your CV because you’re telling them from the off exactly
            why they should hire you.
          </HitchTypography>
        </HitchBox>
        <HitchBox
          component="img"
          src={workSpace}
          backgroundSize="cover"
          borderRadius="12px"
          width="100%"
        />
        <HitchBox mt={3} mb={1} px={1}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={6}>
              <HitchBox display="flex" alignItems="center">
                <HitchBox display="flex" alignItems="center" mr={2}>
                  <HitchTypography component="a" href="#" variant="body2" color="text">
                    <Icon color="info">thumb_up</Icon>&nbsp;
                  </HitchTypography>
                  <HitchTypography variant="button" fontWeight="regular" color="text">
                    150
                  </HitchTypography>
                </HitchBox>
                <HitchBox display="flex" alignItems="center" mr={2}>
                  <HitchTypography component="a" href="#" variant="body2" color="text">
                    <Icon>mode_comment</Icon>&nbsp;
                  </HitchTypography>
                  <HitchTypography variant="button" fontWeight="regular" color="text">
                    36
                  </HitchTypography>
                </HitchBox>
                <HitchBox display="flex" alignItems="center" mr={2}>
                  <HitchTypography component="a" href="#" variant="body2" color="text">
                    <Icon>share</Icon>&nbsp;
                  </HitchTypography>
                  <HitchTypography variant="button" fontWeight="regular" color="text">
                    12
                  </HitchTypography>
                </HitchBox>
              </HitchBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <HitchBox
                display={{ xs: "none", sm: "flex" }}
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
              >
                <VuiAvatar src={team5} alt="person 1" size="xs" />
                <VuiAvatar src={team2} alt="person 2" size="xs" />
                <VuiAvatar src={team1} alt="person 3" size="xs" />
                <HitchBox pl={1}>
                  <HitchTypography variant="caption" color="text" fontWeight="medium">
                    and 30+ more
                  </HitchTypography>
                </HitchBox>
              </HitchBox>
            </Grid>
          </Grid>
          <Divider light />
          {renderComments}
          <HitchBox display="flex" alignItems="center" mt={3}>
            <HitchBox flexShrink={0} mr={2}>
              <VuiAvatar src={team4} alt="profile picture" />
            </HitchBox>
            <HitchBox flexGrow={1}>
              <VuiInput placeholder="Write your comment..." size="large" />
            </HitchBox>
          </HitchBox>
        </HitchBox>
      </HitchBox>
    </Card>
  );
}

export default Post;
