
// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Webhook/GitHubEventViewer.js

import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { Card, CardContent, Divider } from "@mui/material";
import { useEffect } from "react";
import GitHubCheckRunEventViewer from "./GitHubCheckRunEventViewer";
import GitHubPush from "./GitHubPush";
import HitchButton from "components/HitchButton";
import { useHubConnections } from "context/HubConnectionsProvider";

function GitHubEventViewer({ data }) {
    if (!data) return null; // Handle empty data gracefully

    const {
        dashboardConnection,
        hitchDashCommand,
        isConnectionReady,
        hitchUser
    } = useHubConnections();

    // Extract x-github-event from headers
    let githubEvent = null;
    try {
        const headers = JSON.parse(data.headers || "{}");
        githubEvent = headers["x-github-event"];
    } catch (error) {
        console.error("Failed to parse headers:", error);
    }

    function reprocess(data) {
        console.log('reprocess', data);
        if(isConnectionReady) {
            hitchDashCommand('github', 'webhook', 'rerun', {
                webhookEventLogId: data.id
            });
        }
    }

    // Determine which component to render
    const renderEventViewer = () => {
        switch (githubEvent) {
            case "check_run":
                return <GitHubCheckRunEventViewer data={data} />;
            case "push":
                return <GitHubPush data={data} />;
            default:
                return (
                    <>
                        <HitchTypography variant="body1" fontWeight="medium" color="red">
                            Unsupported GitHub Event: {githubEvent || "Unknown"}
                        </HitchTypography>
                        <HitchButton variant="outlined" color="white" onClick={() => reprocess(data)}>
                            Reprocess
                        </HitchButton>
                    </>
                );
        }
    };

    useEffect(() => {
        if (data) {
            console.log("GitHub Event Viewer Data:", data);
        }
    }, [data]);

    return (
        <HitchBox>
            {/* Event Viewer */}
            {renderEventViewer()}


        </HitchBox>
    );
}

export default GitHubEventViewer;
