
// FILE: /UI/hitch-ai-ui-2/src/hitch/plugins/documentRepository/SourceSelector.jsx

import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Avatar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHubConnections } from "context/HubConnectionsProvider"; // Adjust the path if necessary
import HitchBox from "components/HitchBox";

function SourceSelector({ repoId, setSelectedSource }) {
  const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();
  // Initialize with an empty array
  const [connectedSources, setConnectedSources] = useState([]);

  useEffect(() => {
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("d9ebd6e0-4d16-4a03-8e4d-1d1056385562", handleSources);
      hitchDashCommand('document', 'repo-source', 'list', { RepositoryId: repoId });
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("d9ebd6e0-4d16-4a03-8e4d-1d1056385562", handleSources);
      }
    };
  }, [dashboardConnection, isConnectionReady, repoId]);

  function handleSources(data) {
    // Ensure data is an array before setting it
    if (Array.isArray(data)) {
      setConnectedSources(data);
    } else {
     
      // If the data is not an array, set an empty array to avoid errors
      setConnectedSources([]);
      console.error("Expected an array but received:", data);
    }
  }

  const handleSourceClick = (source) => {
    setSelectedSource(source);
  };

  const connectNewSource = () => {
    setSelectedSource('new');
  };

  return (
    <HitchBox maxHeight={200} overflow="auto">
      <List>
        <ListItem button onClick={connectNewSource}>
          <ListItemAvatar>
            <Avatar>
              <FontAwesomeIcon icon={faPlus} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Connect Source" />
        </ListItem>
        
        {connectedSources?.map((source) => (
          <ListItem button key={source.id} onClick={() => handleSourceClick(source)}>
            <ListItemAvatar>
              <Avatar src={`${window._env_.HITCH_API}/api/accimage/${source.serviceId}.png`} />
            </ListItemAvatar>
            <ListItemText primary={source.name} />
          </ListItem>
        ))}
      </List>
    </HitchBox>
  );
}

export default SourceSelector;
