// FILE: /UI/hitch-ai-ui-2/src/examples/Charts/BarCharts/HorizontalBarChart/index.js


import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import BarChart from "examples/Charts/BarCharts/BasicBarChart";
import PropTypes from "prop-types";
import { useMemo } from "react";

function HorizontalBarChart({ title = "", description = "", data, options }) {
  const renderChart = (
    <HitchBox>
      {title || description ? (
        <HitchBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <HitchBox mb={1}>
              <HitchTypography variant="lg" color="white">
                {title}
              </HitchTypography>
            </HitchBox>
          )}
          <HitchBox mb={2}>
            <HitchTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </HitchTypography>
          </HitchBox>
        </HitchBox>
      ) : null}
      {useMemo(
        () => (
          <HitchBox sx={{ minHeight: "300px" }}>
            <BarChart barChartData={data} barChartOptions={options} />
          </HitchBox>
        ),
        [data, options]
      )}
    </HitchBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Typechecking props for the HorizontalBarChart
HorizontalBarChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.objectOf(PropTypes.array).isRequired,
  options: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default HorizontalBarChart;
