
// FILE: /UI/hitch-ai-ui-2/src/layouts/pages/users/new-user/components/FormField/index.js

import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiInput from "components/VuiInput";

function FormField({ label, name, ...rest }) {
  return (
    <HitchBox mb={1.5}>
      <HitchBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <HitchTypography
          component="label"
          variant="caption"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </HitchTypography>
      </HitchBox>
      <Field {...rest} name={name} as={VuiInput} />
      <HitchBox mt={0.75}>
        <HitchTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </HitchTypography>
      </HitchBox>
    </HitchBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
