// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/users/UserDetail.js


import HitchBox from "components/HitchBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import UserProfileHeader from "./UserProfileHeader";
import { useParams } from "react-router-dom";
import { useAdminHubConnections } from "context/HubAdminConnectionProvider";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import UserRole from "./UserRole";
import LeadDetail from "./LeadDetail"; // Import LeadDetail component
import IdentityRecordDetail from "./IdentityRecordDetail"; // Import IdentityRecordDetail component
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchButton from "components/HitchButton";
import HitchModal from "components/HitchModal";
import HitchSelectSimple from "components/HitchSelectSimple";
import { useHubConnections } from "context/HubConnectionsProvider";

function UserDetails() {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [lead, setLead] = useState(null); // Initialize lead state
  const [idRecords, setIdRecords] = useState([]); // Initialize identity records state
  const [userRoles, setUserRoles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem('selectedAccount') || null);
  const [selectedRole, setSelectedRole] = useState(localStorage.getItem('selectedRole') || null);

  const {
    adminConnection,
    hitchUser,
    isConnectionReady,
  } = useAdminHubConnections();

  const {
    hitchDashCommand,
    dashboardConnection
  }
    = useHubConnections();

  useEffect(() => {
    if (dashboardConnection) {
      const subscribeToEvents = () => {
        dashboardConnection.on("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
        dashboardConnection.on("f1483793-2e84-4771-8ec5-7bb827d9d264", setRoles);
        dashboardConnection.on("d8b0f4cf-2579-4d95-bbae-28ed99caa81a", (data) => {
          console.log("d8b0f4cf-2579-4d95-bbae-28ed99caa81a", data);
        })
        hitchDashCommand('admin', 'account', 'list', {});
      };

      subscribeToEvents();
      return () => {
        dashboardConnection.off("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
        dashboardConnection.off("f1483793-2e84-4771-8ec5-7bb827d9d264", setRoles);
        dashboardConnection.off("d8b0f4cf-2579-4d95-bbae-28ed99caa81a");
      };
    }
  }, [dashboardConnection]);

  useEffect(() => {
    if (adminConnection && userId) {
      const subscribeToEvents = () => {
        adminConnection.on("User", (data) => {
          console.log("User", data);
          setUser(data);
        });

        adminConnection.on("AccountUserRoles", (data) => {
          console.log("AccountUserRoles", data);
          setUserRoles(data);
        });

        adminConnection.on("IdentityRecords", (data) => {
          console.log("IdentityRecords", data);
          setIdRecords(data); // Update identity records state with received data
        });

        adminConnection.on("Leads", (data) => {
          console.log("Leads", data);
          setLead(data); // Update lead state with received data
        });
      };

      subscribeToEvents();

      adminConnection
        .invoke("GetUser", hitchUser?.userId, userId)
        .then(() => {
          console.log("i asked for user");
        })
        .catch((error) => console.error("Error sending message:", error));

      adminConnection
        .invoke("GetAccountUserRoles", hitchUser?.userId, userId)
        .then(() => {
          console.log("i asked for user roles");
        })
        .catch((error) => console.error("Error sending message:", error));

      adminConnection
        .invoke("GetUserIdentityRecords", hitchUser?.userId, userId)
        .then(() => {
          console.log("i asked for id records");
        })
        .catch((error) => console.error("Error sending message:", error));

      adminConnection
        .invoke("GetUserLeadRecords", hitchUser?.userId, userId)
        .then(() => {
          console.log("i asked for lead records");
        })
        .catch((error) => console.error("Error sending message:", error));

      return () => {
        adminConnection.off("User");
        adminConnection.off("AccountUserRoles");
        adminConnection.off("IdentityRecords");
        adminConnection.off("Leads");
      };
    }
  }, [adminConnection, userId]);

  // Group roles by accountName
  const groupedRoles = userRoles.reduce((acc, role) => {
    const { accountName } = role;
    if (!acc[accountName]) {
      acc[accountName] = [];
    }
    acc[accountName].push(role);
    return acc;
  }, {});

  function addRole() {
    console.log('adding role');
    setModalOpen(true);
  }

  function onYes() {
    var thePayload = {
      theAccountId: selectedAccount,
      theRoleId: selectedRole,
      theUserId: user.id
    };

    if (dashboardConnection) {
      hitchDashCommand('admin', 'user', 'assign-role', thePayload);
    }

    console.log('onYes', thePayload);
  }

  function onNo() {
    setModalOpen(false);
  }

  function handleSelectAccount(data) {
    console.log('handleSelectAccount', data);
  }

  useEffect(() => {
    if (selectedAccount) {
      localStorage.setItem('selectedAccount', selectedAccount);
      if (dashboardConnection) {
        hitchDashCommand('admin', 'account-role', 'list', {
          theAccountId: selectedAccount
        });
      }
    } else {
      setRoles([]);
      setSelectedRole(null);
    }
  }, [selectedAccount, dashboardConnection]);

  useEffect(() => {
    if (selectedRole) {
      localStorage.setItem('selectedRole', selectedRole);
    } else {
      localStorage.removeItem('selectedRole');
    }
  }, [selectedRole]);

  const sortedAccounts = [...accounts].sort((a, b) => a.name.localeCompare(b.name));
  const sortedRoles = [...roles].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <DashboardLayout>
      <DashboardNavbar light />
      <HitchBox pt={6} pb={3}>
        <UserProfileHeader user={user} />

        <HitchBox pt={4} pb={3}> {/* Adjust padding for proper spacing */}
          <Grid container spacing={3} mb="30px">
            <Grid item xs={12}>
              <HitchButton
                variant="outlined"
                color="white"
                onClick={(e) => addRole()}>
                Assign Role
              </HitchButton>
            </Grid>
            {/* Render LeadDetail if lead data exists */}
            {lead && (
              <Grid item xs={12} xl={4}>
                <LeadDetail lead={lead} />
              </Grid>
            )}
            {/* Render each IdentityRecordDetail as separate boxes */}
            {idRecords.length > 0 && idRecords.map((record, index) => (
              <Grid item xs={12} xl={4} key={record.id}>
                <IdentityRecordDetail record={record} indexNumber={index} />
              </Grid>
            ))}
            {Object.entries(groupedRoles).map(([accountName, roles]) => (
              <Grid key={accountName} item xs={12} xl={4}>
                <UserRole accountName={accountName} roles={roles} />
              </Grid>
            ))}
          </Grid>
        </HitchBox>
        <Footer />
      </HitchBox>
      <HitchModal
        open={modalOpen}
        title="Assign User"
        question="Select Role Assignment"
        yesLabel="Assign"
        noLabel="Cancel"
        onYes={(e) => onYes()}
        onNo={(e) => onNo()}
      >
        <HitchBox>
          <HitchSelectSimple
            value={selectedAccount}
            label="Select an Account"
            options={sortedAccounts.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            placeholder="Select Account"
            onChange={(e) => setSelectedAccount(e)}
            isClearable
          />
          <HitchSelectSimple
            value={selectedRole}
            label="Select a Role"
            options={sortedRoles.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            placeholder="Select Role"
            onChange={(e) => setSelectedRole(e)}
            isClearable
          />
        </HitchBox>
      </HitchModal>
    </DashboardLayout>
  );
}

export default UserDetails;
