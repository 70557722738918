import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

function Address() {
  return (
    <HitchBox>
      <HitchBox width="80%" textAlign="center" mx="auto" mb={4}>
        <HitchBox mb={1}>
          <HitchTypography variant="lg" fontWeight="bold" color="white">
            Are you living in a nice area?
          </HitchTypography>
        </HitchBox>
        <HitchTypography variant="button" fontWeight="regular" color="text">
          One thing I love about the later sunsets is the chance to go for a walk through the
          neighborhood woods before dinner
        </HitchTypography>
      </HitchBox>
      <HitchBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField type="text" label="Address 1" placeholder="eg. Street 120" />
          </Grid>
          <Grid item xs={12}>
            <FormField type="text" label="Address 2" placeholder="eg. Street 220" />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField type="text" label="City" placeholder="eg. Tokyo" />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField type="text" label="State" placeholder="..." />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField type="text" label="ZIP" placeholder="7 letters" />
          </Grid>
        </Grid>
      </HitchBox>
    </HitchBox>
  );
}

export default Address;
