import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function SosWhistleBlowers() {
    const cols = [
        { accessor: 'reportId', Header: 'Report ID', width: '20%' },
        { accessor: 'submittedDate', Header: 'Submitted Date', width: '20%' },
        { accessor: 'status', Header: 'Status', width: '20%' },
        { accessor: 'priority', Header: 'Priority', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '20%',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <HitchButton variant="outlined" color="info" size="small">Review</HitchButton>
                    <HitchButton variant="outlined" color="warning" size="small">Escalate</HitchButton>
                </div>
            )
        }
    ];

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        const mockReports = [
            { reportId: 'WB-2025-001', submittedDate: '2025-02-10', status: 'Under Review', priority: 'High' },
            { reportId: 'WB-2025-002', submittedDate: '2025-02-09', status: 'Escalated', priority: 'Critical' },
            { reportId: 'WB-2025-003', submittedDate: '2025-02-08', status: 'Resolved', priority: 'Medium' },
        ];

        let filtered = mockReports;
        if (searchValue) {
            filtered = filtered.filter(item => item.reportId.toLowerCase().includes(searchValue.toLowerCase()));
        }

        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        setList(filtered.slice(start, end));
    }, [page, perPage, searchValue]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Whistle Blower Reports
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search Reports"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <HitchButton variant="outlined" color="white">Refresh</HitchButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={{ columns: cols, rows: list }}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={(newPage, newPerPage) => { setPage(newPage); setPerPage(newPerPage); }}
                                paginated={true}
                                page={page}
                            />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosWhistleBlowers;
