
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/menu-items/AccountConnections/index.js

import { Card } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function AccountConnections() {
    const { accountId } = useParams();
    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();
    
    const [connectionList, setConnectionList] = useState([]);
    const [serviceList, setServiceList] = useState([]);

    const cols = [
        { accessor: 'name', Header: 'Connection Name', width: '50%' },
        { accessor: 'serviceName', Header: 'Service Name', width: '50%' },
    ];

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("5f1db337-e854-4391-93e4-0361d16bdbe5", (data) => {
                    console.log('5f1db337-e854-4391-93e4-0361d16bdbe5', data);
                    setConnectionList(data);
                });
                dashboardConnection.on("0394c8d7-6406-425e-8017-fe5ade9d406b", (data) => {
                    console.log("0394c8d7-6406-425e-8017-fe5ade9d406b", data);
                    setServiceList(data);
                });
            };

            subscribeToEvents();
            hitchDashCommand('admin', 'service', 'list', {});
            hitchDashCommand('admin', 'account-connection', 'list', { theAccountId: accountId });

            return () => {
                dashboardConnection.off('5f1db337-e854-4391-93e4-0361d16bdbe5');
                dashboardConnection.off("0394c8d7-6406-425e-8017-fe5ade9d406b");
            };
        }
    }, [dashboardConnection, accountId]);

    // Map connectionList to include service friendlyName
    useEffect(() => {
        if (connectionList && serviceList) {
            const mappedConnections = connectionList.map(connection => {
                const service = serviceList.find(service => service.id === connection.serviceId);
                return {
                    ...connection,
                    serviceName: service ? service.friendlyName : "Unknown Service"
                };
            });
            setDataTableData((prevState) => ({
                ...prevState,
                rows: mappedConnections
            }));
        }
    }, [connectionList, serviceList]);

    function handleRowClick(obj) {
        console.log('Row clicked:', obj);
    }

    return (
        <Card
            sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                overflow: "visible",
                background: linearGradient(
                    gradients.cardDark.main,
                    gradients.cardDark.state,
                    gradients.cardDark.deg
                ),
            })}
        >
            <HitchBox p={3}>
                <HitchBox mb={5}>
                    <HitchTypography variant="lg" fontWeight="medium" color="white">
                        Service Connections
                    </HitchTypography>
                </HitchBox>
                <HitchBox mb={2}>
                    <HitchDataTable
                        table={dataTableData}
                        canSearch
                        onRowClick={handleRowClick} />
                </HitchBox>
            </HitchBox>
        </Card>
    );
}

export default AccountConnections;
