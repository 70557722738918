import { Grid } from "@mui/material";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchButton from "components/HitchButton";
import HitchAvatar from "components/HitchAvatar";

function Module({ design, handleSet, hitchId, label }) {

    const customButtonStyles = ({
        functions: { pxToRem },
        borders: { borderWidth },
        palette: { transparent, info },
    }) => ({
        width: pxToRem(150),
        height: pxToRem(150),
        borderWidth: borderWidth[2],
        mb: 1,
        ml: 0.5,

        "&.MuiButton-contained, &.MuiButton-contained:hover": {
            boxShadow: "none",
            border: `${borderWidth[2]} solid ${transparent.main}`,
        },

        "&:hover": {
            backgroundColor: `${info.main} !important`,
            border: `${borderWidth[2]} solid ${info.main} !important`,
        },
    });

    return (
        <Grid item xs={12} md={2.5}>
            <HitchBox textAlign="center">
                <HitchButton
                    color="info"
                    variant={design ? "contained" : "outlined"}
                    onClick={() => handleSet(hitchId)}
                    sx={customButtonStyles}
                >
                    <HitchAvatar
                        hitchId={hitchId} />

                </HitchButton>
                <HitchTypography variant="h6" color="white">
                    {label}
                </HitchTypography>
            </HitchBox>
        </Grid>
    );
}

export default Module;