// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/integration/index.js 

import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function SosIntegration() {
    const cols = [
        { accessor: 'name', Header: 'Integration Name', width: '30%' },
        { accessor: 'type', Header: 'Type', width: '15%' },
        { accessor: 'status', Header: 'Status', width: '10%' },
        { accessor: 'lastSynced', Header: 'Last Synced', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '25%',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <HitchButton variant="outlined" color="info" size="small">View</HitchButton>
                    <HitchButton variant="outlined" color="warning" size="small">Edit</HitchButton>
                    <HitchButton variant="outlined" color="error" size="small">Delete</HitchButton>
                </div>
            )
        }
    ];
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);

    const [searchValue, setSearchValue] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('');

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    useEffect(() => {
        const mockIntegrations = [
            { name: 'SIEM Log Tool', type: 'SIEM', status: 'Active', lastSynced: '2024-01-15' },
            { name: 'HR System', type: 'ERP', status: 'Inactive', lastSynced: '2023-12-10' },
            { name: 'Custom API', type: 'API', status: 'Active', lastSynced: '2024-02-01' },
            { name: 'Ticketing System', type: 'Custom', status: 'Active', lastSynced: '2024-01-20' }
        ];

        let filtered = [...mockIntegrations];

        if (searchValue) {
            const val = searchValue.toLowerCase();
            filtered = filtered.filter(item =>
                item.name.toLowerCase().includes(val) ||
                item.type.toLowerCase().includes(val)
            );
        }
        if (statusFilter) {
            filtered = filtered.filter(item => item.status === statusFilter);
        }
        if (typeFilter) {
            filtered = filtered.filter(item => item.type === typeFilter);
        }

        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        filtered = filtered.slice(start, end);
        setList(filtered);
    }, [page, perPage, searchValue, statusFilter, typeFilter]);

    useEffect(() => {
        setDataTableData(prev => ({
            ...prev,
            rows: list
        }));
    }, [list]);

    function onPageChange(newPage, newPerPage) {
        setPage(newPage);
        setPerPage(newPerPage);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Integration Apis (MOCKED)
                                <HitchButton variant="outlined" color="white" onClick={(e) => navigate('./requirements')}>Requirements</HitchButton>
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Status"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Inactive">Inactive</MenuItem>
                                </TextField>
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Type"
                                    value={typeFilter}
                                    onChange={(e) => setTypeFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="SIEM">SIEM</MenuItem>
                                    <MenuItem value="ERP">ERP</MenuItem>
                                    <MenuItem value="API">API</MenuItem>
                                    <MenuItem value="Custom">Custom</MenuItem>
                                </TextField>
                                <HitchButton variant="outlined" color="white">Add Integration</HitchButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={dataTableData}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={onPageChange}
                                paginated={true}
                                page={page}
                            />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosIntegration;
