// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/training/requirements.js

import Card from "@mui/material/Card"; 
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MarkdownRenderer from "hitch/chat/MarkdownRenderer";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import theMarkdown from "./theMarkdown";

function SosTrainingRequirements() {

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Training and Analysis (REQUIREMENTS)
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <MarkdownRenderer
                                content={theMarkdown} />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosTrainingRequirements;