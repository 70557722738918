
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/administration/organization/OrganizationAvatar/index.js

import Grid from "@mui/material/Grid";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchButton from "components/HitchButton";
import VuiBadge from "components/VuiBadge";
import { useHubConnections } from "context/HubConnectionsProvider";
import HitchImageUploader from 'hitch/image-uploader';
import { useState } from "react";
import { Formik, Field, Form } from "formik";
import TextField from '@mui/material/TextField';
import FormField from "layouts/pages/users/new-user/components/FormField";

function OrganizationAvatar() {
    const { hitchUser, account, dashboardConnection, hitchDashCommand, isConnectionReady, errorSnack, infoSnack, successSnack } = useHubConnections();

    const [editMode, setEditMode] = useState(false);

    function saveToService(values) {
        console.log("saveToService called with values:", values);
        if (dashboardConnection && isConnectionReady) {
            const theAccount = {
                account: {
                    id: account.id,
                    name: account.name,
                    description: account.description,
                    billingAddress1: values.billingAddress1,
                    billingAddress2: values.billingAddress2,
                    billingAddress3: values.billingAddress3,
                    billingCity: values.billingCity,
                    billingState: values.billingState,
                    billingZipCode: values.billingZipCode,
                    subscriptionId: account.subscriptionId,
                    lastBillingDate: account.lastBillingDate,
                    created: account.created,
                    createdBy: account.createdBy,
                    updated: account.updated,
                    updatedBy: account.updatedBy,
                    squareUp_Customer_Id: account.squareUp_Customer_Id,
                    quickbooks_Customer_Id: account.quickbooks_Customer_Id,
                    active: account.active,
                    agreedToTerms: account.agreedToTerms
                }
            };

            console.log("Saving the following account data:", theAccount);
            hitchDashCommand('account', 'profile', 'update', theAccount, () => {
                setEditMode(false);
            });
            infoSnack('Saving data.');
        } else {
            console.error("Dashboard connection not available");
            errorSnack('Cannot Save, Something went wrong.');
        }
    }

    function cancelEdit() {
        setEditMode(false);
    }

    return (
        <Grid container spacing={2} alignItems="center">
            {!editMode ? (
                <>
                    {/* Read Mode */}
                    <Grid item xs={12} md={7}>
                        <HitchBox
                            display="flex"
                            alignItems="center"
                            sx={({ breakpoints }) => ({
                                flexDirection: "column",
                                [breakpoints.up("md")]: {
                                    flexDirection: "row",
                                },
                            })}
                        >
                            <HitchBox mr={2} mb={{ xs: "18px", md: "0px" }}>
                                <HitchImageUploader guid={account?.id} />
                            </HitchBox>
                            <HitchBox
                                lineHeight={1}
                                display="flex"
                                sx={({ breakpoints }) => ({
                                    flexDirection: "column",
                                    alignItems: "center",
                                    [breakpoints.up("md")]: {
                                        flexDirection: "column",
                                        alignItems: "start",
                                    },
                                })}
                            >
                                {account?.billingAddress1 && (
                                    <HitchTypography variant="md" fontWeight="medium" color="white">
                                        {account.billingAddress1}
                                    </HitchTypography>
                                )}
                                {account?.billingAddress2 && (
                                    <HitchTypography variant="md" fontWeight="medium" color="white">
                                        {account.billingAddress2}
                                    </HitchTypography>
                                )}
                                {account?.billingAddress3 && (
                                    <HitchTypography variant="md" fontWeight="medium" color="white">
                                        {account.billingAddress3}
                                    </HitchTypography>
                                )}
                                {account && (
                                    <HitchTypography variant="md" fontWeight="medium" color="white">
                                        {account.billingCity}, {account.billingState} {account.billingZipCode}
                                    </HitchTypography>
                                )}
                                <HitchBox mt={{ xs: "6px", md: "0px" }} mb={2}>
                                    <HitchTypography variant="button" fontWeight="regular" color="text">
                                        Next Billing Date: 11/01/2024
                                    </HitchTypography>
                                </HitchBox>
                                <VuiBadge
                                    variant="contained"
                                    color="success"
                                    size="md"
                                    badgeContent="subscribed"
                                    container
                                />
                            </HitchBox>
                        </HitchBox>
                    </Grid>

                    {/* Buttons in Read Mode */}
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        
                        <HitchButton 
                            variant="outlined" 
                            color="white"                 
                            sx={({ palette: { white } }) => ({
                                border: `1px solid ${white.main}`,
                                color: white.main,
                            })} 
                            onClick={() => setEditMode(true)}>
                            edit
                        </HitchButton>
                        <HitchBox mt={0.5} ml={2}>
                            <HitchButton color="white">
                                Open Support Ticket
                            </HitchButton>
                        </HitchBox>
                    </Grid>
                </>
            ) : (
                <>
                    {/* Edit Mode */}
                    <Formik
                        initialValues={{
                            billingAddress1: account?.billingAddress1 || "",
                            billingAddress2: account?.billingAddress2 || "",
                            billingAddress3: account?.billingAddress3 || "",
                            billingCity: account?.billingCity || "",
                            billingState: account?.billingState || "",
                            billingZipCode: account?.billingZipCode || ""
                        }}
                        onSubmit={(values) => saveToService(values)}
                    >
                        <Form style={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7}>
                                    <HitchBox
                                        display="flex"
                                        alignItems="center"
                                        sx={({ breakpoints }) => ({
                                            flexDirection: "column",
                                            [breakpoints.up("md")]: {
                                                flexDirection: "row",
                                            },
                                        })}
                                    >
                                        <HitchBox mr={2} mb={{ xs: "18px", md: "0px" }}>
                                            <HitchImageUploader guid={account?.id} />
                                        </HitchBox>
                                        <HitchBox
                                            lineHeight={1}
                                            display="flex"
                                            sx={({ breakpoints }) => ({
                                                flexDirection: "column",
                                                alignItems: "center",
                                                [breakpoints.up("md")]: {
                                                    flexDirection: "column",
                                                    alignItems: "start",
                                                },
                                            })}
                                        >
                                            <FormField
                                                name="billingAddress1"
                                                label="Billing Address 1"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingAddress2"
                                                label="Billing Address 2"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingAddress3"
                                                label="Billing Address 3"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingCity"
                                                label="Billing City"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingState"
                                                label="Billing State"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingZipCode"
                                                label="Billing Zip Code"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </HitchBox>
                                    </HitchBox>
                                </Grid>

                                {/* Buttons in Edit Mode */}
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <HitchButton 
                                        type="submit" 
                                        variant="outlined" 
                                        color="white" 
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })} >
                                        Save
                                    </HitchButton>
                                    <HitchButton 
                                        variant="outlined" 
                                        color="white"                 
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })} 
                                        onClick={cancelEdit}>
                                        cancel
                                    </HitchButton>
                                    <HitchBox mt={0.5} ml={2}>
                                        <HitchButton>
                                            Open Support Ticket
                                        </HitchButton>
                                    </HitchBox>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </>
            )}
        </Grid>
    );
}

export default OrganizationAvatar;
