
import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import { Grid } from "@mui/material";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiSelect from "components/VuiSelect";

function HitchSelect({
  editMode,
  label,
  name,
  options,
  placeholder,
  isClearable,
  onChange,
  displayValue,
  ...rest
}) {
  const [field, meta, helpers] = useField(name);

  // Handle selected value gracefully if options are loaded asynchronously
  const selectedOption = options.find((option) => option.value === field.value) || null;

  return (
    <Grid container spacing={1} alignItems="center" mb={1.5}>
      {/* Label */}
      <Grid item xs={5}>
        <HitchTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}:
        </HitchTypography>
      </Grid>

      {/* Select or Read-Only Value */}
      <Grid item xs={7}>
        {editMode ? (
          <VuiSelect
            {...rest}
            name={name}
            value={selectedOption} // Use matched option
            onChange={(selectedOption) => {
              helpers.setValue(selectedOption ? selectedOption.value : "");
              if (onChange) onChange(selectedOption);
            }}
            options={options}
            placeholder={placeholder}
            isClearable={isClearable}
          />
        ) : (
          <HitchTypography variant="body2" color="white" fontWeight="medium">
            {displayValue || selectedOption?.label || "N/A"}
          </HitchTypography>
        )}
      </Grid>

      {/* Validation Error Message */}
      <Grid item xs={12}>
        <HitchTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </HitchTypography>
      </Grid>
    </Grid>
  );
}

// Typechecking props for HitchSelect
HitchSelect.propTypes = {
  editMode: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  displayValue: PropTypes.string,
};

export default HitchSelect;
