
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/administration/organization/index.js

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import HitchBox from "components/HitchBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "hitch/administration/organization/Header";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import OrganizationAvatar from "hitch/administration/organization/OrganizationAvatar";
import Users from "hitch/administration/organization/Users";
import Roles from "./Roles";

function Organization() {
  const { hitchUser, account, setAccount, dashboardConnection, hitchDashCommand, isConnectionReady, errorSnack, infoSnack, successSnack } = useHubConnections();
  const [selectedData, setSelectedData] = useState(account);
  const [editedData, setEditedData] = useState({});
  const [isEditMode, setEditMode] = useState(false);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (dashboardConnection && isConnectionReady && account) {
      dashboardConnection.on("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", (data) => {
        if (data.StackTraceString) {
          errorSnack(`Error: ${data.Message}`)
        } else {
          setContacts(data);
          //setEditMode(false);
          //successSnack('Organization has been updated.'); BUG: when this is here, this repeatedly shows when loads
        }
      });
      dashboardConnection.on("53b23a27-7373-4c92-b5b5-a674b89a1231", (data) => {
        if (data.StackTraceString) {
          errorSnack(`Error: ${data.Message}`)
        } else {
          setSelectedData(data);
          successSnack('Organization has been updated.');
          setAccount(data);
        }
      })

      function callForAccountDetails() {
        hitchDashCommand('account', 'role', 'user-list', {
          theAccountId: account.id
        });
      }

      callForAccountDetails();

      return () => {
        if (dashboardConnection) {
          dashboardConnection.off("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5");
          dashboardConnection.off("53b23a27-7373-4c92-b5b5-a674b89a1231");
        }
      };
    }
  }, [dashboardConnection, isConnectionReady, account]);
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <HitchBox my={7}>
        <HitchBox mx="auto" display="flex" justifyContent="center" maxWidth="780px">
          <Card sx={{ padding: "20px" }}>
            <HitchBox pt={2} px={2}>
              <Header />
            </HitchBox>
            <Divider light />
            <HitchBox pt={1} pb={3} px={2}>
              <HitchBox mb={3}>
                <OrganizationAvatar />
              </HitchBox>
              <Divider light />
              <HitchBox mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Users />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Roles />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                  </Grid>
                </Grid>
              </HitchBox>
            </HitchBox>
          </Card>
        </HitchBox>
      </HitchBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Organization;
