
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/uicomponents/IconDetail/index.js

import { Card, Grid } from "@mui/material";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { Form, Formik } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function IconDetail() {

    const { iconId } = useParams();
    const [icon, setIcon] = useState({});
    const [editMode, setEditMode] = useState(false);
    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    var navigate = useNavigate();

    function saveIcon(values) {
        console.log('save', values);

        const theIcon = {
            theIcon: {
                id: icon.id,
                created: icon.created,
                createdBy: icon.createdBy,
                updated: icon.updated,
                updatedBy: icon.updatedBy,
                name: values.name,
                size: values.size,
                color: values.color
            }
        };

        console.log('Saving the following Icon Data', theIcon);
        hitchDashCommand('admin', 'icon', 'update', theIcon, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection && iconId) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');

                dashboardConnection.on("a170f139-3666-43d9-9824-be8831283f71", (data) => {
                    
                    if (!data || data === "An Error has occurred.") {
                        setEditMode(true);
                        setComponent({
                            id: iconId
                        });
                    } else {
                        console.log('a170f139-3666-43d9-9824-be8831283f71', data);
                        setIcon(data);
                    }
                    // setService(data);
                });
                // Add other event subscriptions here
                console.log('iconId', iconId);
                hitchDashCommand('admin', 'icon', 'get', { iconId: iconId });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('a170f139-3666-43d9-9824-be8831283f71');
            };
        }
    }, [dashboardConnection, iconId]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox py={3}>
                <Card
                    sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                        overflow: "visible",
                        background: linearGradient(
                            gradients.cardDark.main,
                            gradients.cardDark.state,
                            gradients.cardDark.deg
                        ),
                    })}
                >
                    <HitchBox p={3}>
                        <HitchBox mb={5}>
                            <HitchTypography variant="lg" fontWeight="medium" color="white">
                                Icon: {icon?.name}
                            </HitchTypography>
                        </HitchBox>

                        {!editMode ? (
                            <HitchBox mb={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <HitchButton
                                        variant="outlined"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })}
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit
                                    </HitchButton>
                                    <HitchButton
                                        variant="contained"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                        })}
                                        onClick={() => navigate('/hitch-admin/icons')}
                                    >
                                        Back to Icons
                                    </HitchButton>
                                </Grid>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <HitchTypography variant="button" fontWeight="regular" color="text">
                                            Icon Name:
                                        </HitchTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <HitchTypography variant="body2" color="white" fontWeight="medium">
                                            {icon?.name || "N/A"}
                                        </HitchTypography>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <HitchTypography variant="button" fontWeight="regular" color="text">
                                            Size:
                                        </HitchTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <HitchTypography variant="body2" color="white" fontWeight="medium">
                                            {icon?.size || "N/A"}
                                        </HitchTypography>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <HitchTypography variant="button" fontWeight="regular" color="text">
                                            Color:
                                        </HitchTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <HitchTypography variant="body2" color="white" fontWeight="medium">
                                            {icon?.color || "N/A"}
                                        </HitchTypography>
                                    </Grid>
                                </Grid>
                            </HitchBox>
                        ) : (
                            <>
                                <Formik
                                    initialValues={{
                                        name: icon?.name || "",
                                        path: icon?.size || "",
                                        sourcePath: icon?.color || ""
                                    }}
                                    onSubmit={(values) => saveIcon(values)}
                                >
                                    {({ values, setFieldValue, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <HitchBox>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={5}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <HitchButton
                                                        type="submit"
                                                        variant="contained"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                        })}
                                                    >
                                                        save
                                                    </HitchButton>
                                                    <HitchButton
                                                        variant="outlined"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                            color: white.main,
                                                        })}
                                                        onClick={() => setEditMode(false)}
                                                    >
                                                        cancel
                                                    </HitchButton>
                                                </Grid>
                                                <FormField
                                                    name="name"
                                                    label="Component Name"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormField
                                                    name="size"
                                                    label="Size"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormField
                                                    name="color"
                                                    label="Color"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                
                                            </HitchBox>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        )}
                    </HitchBox>
                </Card>
            </HitchBox>
        </DashboardLayout>
    );
}

export default IconDetail;