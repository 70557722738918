
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/token-manager/index.js

import { Card } from "@mui/material";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { PiPlugsConnectedThin, PiPlugsThin } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

function TokenManager({featureName='auth', getConfigHitchId, isLoggedInHitchId, serviceName, startAuthFlowHitchId}) {
    const [config, setConfig] = useState({});
    const [users, setUsers] = useState([]);
    const [authUrl, setAuthUrl] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser
    } = useHubConnections();
    const navigate = useNavigate();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on(getConfigHitchId, setConfig);
                dashboardConnection.on("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", setUsers);
                dashboardConnection.on(isLoggedInHitchId, setIsLoggedIn);
                dashboardConnection.on(startAuthFlowHitchId, (e) => setAuthUrl(JSON.parse(e)));
                hitchDashCommand(serviceName, 'config', 'get', {});
                hitchDashCommand('account', 'role', 'user-list', {
                    theAccountId: hitchUser.accountId
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off(getConfigHitchId, setConfig);
                dashboardConnection.off("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", setUsers);
                dashboardConnection.off(isLoggedInHitchId, setIsLoggedIn);
                dashboardConnection.off(startAuthFlowHitchId);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (config && hitchUser) {
            if (config.integrationUserId) {
                hitchDashCommand(serviceName, featureName, 'logged-in', {
                    theUserId: config.integrationUserId
                });
            } else {
                if (hitchUser.userId) {
                    hitchDashCommand(serviceName, featureName, 'logged-in', {
                        theUserId: hitchUser.userId
                    });
                } else {
                    hitchDashCommand(serviceName, featureName, 'logged-in', {
                        theUserId: hitchUser.id
                    });
                }
            }
        }
    }, [hitchUser, config]);

    useEffect(() => {
        console.log('isLoggedIn', isLoggedIn);
    }, [isLoggedIn]);

    const getUserName = (userId) => {
        const retUser = users.find((usr) => usr.id === userId);
        return retUser ? retUser.name : "N/A";
    };

    useEffect(() => {
        if(authUrl && authUrl.Url) {
            console.log('authUrl', authUrl.Url);
            window.location.href = authUrl.Url; // Use for external navigation
        }
    }, [authUrl]);

    function startLogin() { 
        console.log('starting login');
        hitchDashCommand(serviceName, featureName, 'auth-start', {});
    }

    return (
        <HitchBox>
            <Card>
                <HitchBox p={3} pl={0} lineHeight={1}>
                    <HitchTypography variant="h5" fontWeight="medium" color="white">
                        Token Manager
                    </HitchTypography>
                    <HitchTypography variant="button" fontWeight="regular" color="text">
                        User: {getUserName(config?.integrationUserId)}
                    </HitchTypography>
                </HitchBox>
                <HitchBox p={3}>
                    <HitchTypography variant="h2">
                        {isLoggedIn && (
                            <div><PiPlugsConnectedThin /> CONNECTED!</div>
                        )}
                        {!isLoggedIn && (
                            <div> 
                                <HitchButton
                                   variant="outlined"
                                   color="white"
                                   sx={({ palette: { white } }) => ({
                                       border: `1px solid ${white.main}`,
                                       color: white.main,
                                   })}
                                   onClick={() => startLogin()}>
                                    <PiPlugsThin size="30px" />&nbsp; CONNECT
                                </HitchButton></div>
                        )}
                    </HitchTypography>
                </HitchBox>
            </Card>
        </HitchBox>
    )
}

export default TokenManager;