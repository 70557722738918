
import { Grid } from "@mui/material";
import CustomTabPanel from "components/CustomTabPanel";
import HitchFormTextArea from "components/HitchFormTextArea";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import BotFileManagement from "./BotFileManagement";

function BotAiKnowledge({ editMode, handleChange, handleFileSearchChange, handleVectorStoreChange, models, setFieldValue, tab, updateBotField, values, vectorStores }) {
    return (
        <CustomTabPanel value={tab} index={3}>
            <HitchBox pl={0} lineHeight={1}>
                <HitchTypography variant="h5" fontWeight="medium" color="white">
                    Knowledge
                </HitchTypography>
                <Grid container>
                    <BotFileManagement
                        values={values}
                        handleChange={handleChange}
                        vectorStores={vectorStores}
                        // setFieldValue={(field, value) => {
                        //     setFieldValue(field, value);
                        //     updateBotField(field, value);
                        // }}
                        handleFileSearchChange={(event) => handleFileSearchChange(event, setFieldValue)}
                        handleVectorStoreChange={(value) => handleVectorStoreChange(value, setFieldValue)}
                        editMode={editMode}
                        // expandedSection={expandedSection}
                        // setExpandedSection={setExpandedSection}
                    />
                </Grid>
            </HitchBox>
        </CustomTabPanel>
    );
}

export default BotAiKnowledge;