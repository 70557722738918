
import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import PropTypes from "prop-types";

function MiniGradientLineChart({ title = "", description = [""], chart, height = "6.25rem" }) {
  const renderChart = (
    <>
      {title || description ? (
        <HitchBox p="12px 12px 0px 12px" display="flex" flexDirection="column">
          {title && (
            <HitchTypography
              variant="button"
              color="white"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {title}
            </HitchTypography>
          )}
          {description}
        </HitchBox>
      ) : null}
      <HitchBox sx={{ maxHeight: "150px" }}>{chart}</HitchBox>
    </>
  );

  return title || description ? <Card sx={{ padding: "8px" }}>{renderChart}</Card> : renderChart;
}

// Typechecking props for the MiniGradientLineChart
MiniGradientLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  chart: PropTypes.oneOfType([
    PropTypes.node, // For React components or elements
    PropTypes.element, // For React elements specifically
  ]),
};

export default MiniGradientLineChart;
