
// AI INSTRUCTION: Always Keep this file header when making modifications to this document. 
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary. 
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/ChatMessageInput.js 

import React from 'react';
import PropTypes from 'prop-types';
import VuiInput from 'components/VuiInput';

function ChatMessageInput({ disabled, placeholder, newMessage, setNewMessage, submitChat }) {
  const isMessageEmpty = !newMessage.content;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('key');
      if (e.shiftKey) {
        // Allow new line
        return;
      } else {
        // Prevent default behavior and submit
        e.preventDefault();
        submitChat(e);
      }
    }
  };

  const handleChange = (e) => {
    const newMessageObject = {
      content: e.target.value,
      contentType: 'string',
    };
    setNewMessage(newMessageObject);
  };

  return (
    <VuiInput
      component="textarea"
      placeholder={placeholder}
      value={newMessage?.content}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      fullWidth
      multiline
      minRows={1}
      maxRows={3}
      variant="outlined"
      size="large"
      disabled={disabled}
      sx={{
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        overflowY: 'auto',
        '& textarea': {
          resize: 'none',
        },
      }}
      color="white"
    />
  );
}

ChatMessageInput.propTypes = {
  placeholder: PropTypes.string,
  newMessage: PropTypes.object,
  setNewMessage: PropTypes.func.isRequired,
};

export default ChatMessageInput;