import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function SosAccess() {
    const cols = [
        { accessor: 'userName', Header: 'User Name', width: '30%' },
        { accessor: 'role', Header: 'Role', width: '20%' },
        { accessor: 'accessLevel', Header: 'Access Level', width: '20%' },
        { accessor: 'lastActivity', Header: 'Last Activity', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '10%',
            Cell: ({ row }) => (
                <HitchButton variant="outlined" color="info" size="small">Manage Access</HitchButton>
            )
        }
    ];

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        const mockUsers = [
            { userName: 'Alice Brown', role: 'Admin', accessLevel: 'Full', lastActivity: '2025-02-10' },
            { userName: 'Bob Green', role: 'Developer', accessLevel: 'Limited', lastActivity: '2025-02-09' },
            { userName: 'Charlie White', role: 'Auditor', accessLevel: 'Read-Only', lastActivity: '2025-02-08' },
        ];

        let filtered = mockUsers;
        if (searchValue) {
            filtered = filtered.filter(item => item.userName.toLowerCase().includes(searchValue.toLowerCase()));
        }

        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        setList(filtered.slice(start, end));
    }, [page, perPage, searchValue]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Access Management
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search Users"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <HitchButton variant="outlined" color="white">Refresh</HitchButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={{ columns: cols, rows: list }}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={(newPage, newPerPage) => { setPage(newPage); setPerPage(newPerPage); }}
                                paginated={true}
                                page={page}
                            />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosAccess;
