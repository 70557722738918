import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchButton from "components/HitchButton";
import VuiAvatar from "components/VuiAvatar";
import VuiBadge from "components/VuiBadge";

// Vision UI Dashboard PRO images
import orderImage from "assets/images/order-details.png";

// Images
function OrderInfo() {
  return (
    <Grid container spacing={{ xs: 1, lg: 2 }} alignItems="center">
      <Grid item xs={12} md={7}>
        <HitchBox
          display="flex"
          alignItems="center"
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            [breakpoints.up("md")]: {
              flexDirection: "row",
            },
          })}
        >
          <HitchBox mr={2} mb={{ xs: "18px", md: "0px" }}>
            <VuiAvatar variant="rounded" size="xxl" src={orderImage} alt="Gold Glasses" />
          </HitchBox>
          <HitchBox
            lineHeight={1}
            display="flex"
            sx={({ breakpoints }) => ({
              flexDirection: "column",
              alignItems: "center",
              [breakpoints.up("md")]: {
                flexDirection: "column",
                alignItems: "start",
              },
            })}
          >
            <HitchTypography variant="lg" fontWeight="medium" color="white">
              Modern Luxury Sofa
            </HitchTypography>
            <HitchBox mt={{ xs: "6px", md: "0px" }} mb={2}>
              <HitchTypography variant="button" fontWeight="regular" color="text">
                Order was delivered 2 days ago.
              </HitchTypography>
            </HitchBox>
            <VuiBadge
              variant="contained"
              color="success"
              size="md"
              badgeContent="delivered"
              container
            />
          </HitchBox>
        </HitchBox>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={({ breakpoints }) => ({
          mt: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          [breakpoints.up("md")]: {
            mt: "0px",
            alignItems: "end",
          },
        })}
      >
        <HitchButton variant="contained" color="info">
          contact us
        </HitchButton>
        <HitchBox mt={0.5}>
          <HitchTypography variant="caption" fontWeight="regular" color="text">
            Do you like the product? Leave us a review{" "}
            <HitchTypography
              component="a"
              href="#"
              variant="button"
              fontWeight="regular"
              color="text"
            >
              here
            </HitchTypography>
            .
          </HitchTypography>
        </HitchBox>
      </Grid>
    </Grid>
  );
}

export default OrderInfo;
