import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiAvatar from "components/VuiAvatar";
import HitchButton from "components/HitchButton";

// Image
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";

function Header() {
  const avatarStyles = {
    border: ({ borders: { borderWidth }, palette: { grey } }) =>
      `${borderWidth[2]} solid ${grey[700]}`,
    cursor: "pointer",
    position: "relative",
    ml: -1.5,

    "&:hover, &:focus": {
      zIndex: "10",
    },
  };

  return (
    <HitchBox display="flex" alignItems="center">
      <HitchBox mt={0.5} pr={1}>
        <HitchBox mb={1} lineHeight={0}>
          <HitchTypography variant="caption" fontWeight="medium" color="white">
            Team members:
          </HitchTypography>
        </HitchBox>
        <HitchBox display="flex">
          <Tooltip title="Jessica Rowland" placement="top">
            <VuiAvatar src={team1} alt="team-1" size="sm" sx={avatarStyles} />
          </Tooltip>
          <Tooltip title="Audrey Love" placement="top">
            <VuiAvatar src={team2} alt="team-1" size="sm" sx={avatarStyles} />
          </Tooltip>
          <Tooltip title="Michael Lewis" placement="top">
            <VuiAvatar src={team3} alt="team-1" size="sm" sx={avatarStyles} />
          </Tooltip>
          <Tooltip title="Lucia Linda" placement="top">
            <VuiAvatar src={team4} alt="team-1" size="sm" sx={avatarStyles} />
          </Tooltip>
          <Tooltip title="Ronald Miller" placement="top">
            <VuiAvatar src={team5} alt="team-1" size="sm" sx={avatarStyles} />
          </Tooltip>
        </HitchBox>
      </HitchBox>
      <HitchBox height="75%" alignSelf="flex-end">
        <Divider orientation="vertical" light />
      </HitchBox>
      <HitchBox pl={1}>
        <HitchButton variant="contained" color="info" iconOnly>
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
        </HitchButton>
      </HitchBox>
    </HitchBox>
  );
}

export default Header;
