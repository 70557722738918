import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import HitchButton from "components/HitchButton";

// Images
import image from "assets/images/product-page.png";
function ProductImage() {
  return (
    <Card sx={{ height: "100%", p: "10px" }}>
      <HitchBox p={3}>
        <HitchTypography variant="h5" fontWeight="bold" color="white">
          Product Image
        </HitchTypography>
        <HitchBox
          component="img"
          src={image}
          alt="Product Image"
          borderRadius="lg"
          shadow="lg"
          width="100%"
          my={3}
        />
        <HitchBox display="flex">
          <HitchButton variant="contained" color="info" size="small" sx={{ mr: "8px" }}>
            edit
          </HitchButton>
          <HitchButton variant="outlined" color="white" size="small">
            remove
          </HitchButton>
        </HitchBox>
      </HitchBox>
    </Card>
  );
}

export default ProductImage;
