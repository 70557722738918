import HitchBox from "components/HitchBox";
import HitchDashboard from "components/HitchDashboard";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";

function HitchAdminDashboard() {
    const [dashboardId, setDashboardId] = useState(null);
    const [connection, setConnection] = useState(null);

    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection && isConnectionReady && hitchUser && hitchUser?.accountId) {
            dashboardConnection.on("ee7b5c3b-3359-4870-8a12-31e3b03901d8", setConnection);

            console.log('getting connection');
            hitchDashCommand('account', 'connection', 'get', {
                theServiceId: '4899d10e-569e-4e3b-99b1-71718c8cdb60',
                theAccountId: hitchUser.accountId
            });

            return (() => {
                dashboardConnection.off("ee7b5c3b-3359-4870-8a12-31e3b03901d8", setConnection);
            })

        }
    }, [dashboardConnection, isConnectionReady, hitchUser, hitchUser?.accountId]);

    useEffect(() => {
        if(connection) {
            setDashboardId(connection.id);
        }
    }, [connection]);



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox>
                <HitchTypography variant="h2">HELLO!</HitchTypography>
                <HitchDashboard
                    dashboardId={dashboardId} />
            </HitchBox>
        </DashboardLayout>

    )
}

export default HitchAdminDashboard;