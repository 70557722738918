import React, { useEffect, useState } from "react";
import HitchTitle from "components/HitchTitle";
import HitchBox from "components/HitchBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import { useHubConnections } from "context/HubConnectionsProvider";
import HitchSelectSimple from "components/HitchSelectSimple";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function HitchDef() {

    const { dashboardConnection, hitchDashCommand, hitchUser } = useHubConnections();
    const navigate = useNavigate();
    const [data, setData] = useState([
        { service: "Account", feature: "Menu", operation: "Get", action: "Create" },
        { service: "Account", feature: "Menu", operation: "List", action: "Edit" },
        { service: "Account", feature: "Menu", operation: "Delete", action: "Create" },
    ]);

    function onActionClick(opId, defId) {
        if(!defId) {
            // WE NEED TO IN THE BACKGROUND CREATE A DEF ID AND ATTACH.
            console.log(`TODO: SERVICE CALL TO CREATE A HITCH OFF OF THIS OPERATION!  ${opId}`);
            return;
        }

        console.log(`TODO: REDIRECT TO NEW (NOT CREATED) PAGE FOR DEF ID ${defId}`);
        navigate(`./${defId}`);
    }

    const cols = [
        { accessor: 'service', Header: 'Service', width: '25%' },
        { accessor: 'feature', Header: 'Feature', width: '25%' },
        { accessor: 'operation', Header: 'Operation', width: '25%' },
        {
            accessor: 'action',
            Header: 'Action',
            width: '25%',
            Cell: ({ row }) => {
                const defId = row.original.defId; // Access the defId from the row data
                return (
                    <>
                    <HitchButton variant={defId ? "contained" : "outlined"} color={defId ? "primary" : "white"} onClick={(e) => onActionClick(row.original.id, defId)}>
                        {defId ? "Edit" : "Create"}
                    </HitchButton>
                    </>
                );
            }
        }
    ];

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: [],
    });
    const [selectedServiceId, SetSelectedServiceId] = useState(localStorage.getItem('selectedService') || null);
    const [selectedFeatureId, SetSelectedFeatureId] = useState(localStorage.getItem('selectedFeature') || null);
    const [list, setList] = useState([]);
    const [services, setServices] = useState([]);
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        if (dashboardConnection) {
            dashboardConnection.on("bfa55cb1-b780-4782-bfd6-97a357d87cc0", setData);
            dashboardConnection.on("0b0d33ca-ebfe-4221-b489-bf416ce260a0", setList);

            UpdateList();

            return () => {
                dashboardConnection.off("bfa55cb1-b780-4782-bfd6-97a357d87cc0", setData);
                dashboardConnection.off("0b0d33ca-ebfe-4221-b489-bf416ce260a0", setList);
            }
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (dashboardConnection) {
            if (hitchUser && !selectedServiceId && !selectedFeatureId) {
                console.log(`Getting Routes for Org ${hitchUser.accountId}`);
                UpdateList();
                hitchDashCommand('admin', 'routes', 'list', {
                    theAccountId: hitchUser.accountId
                });
                return;
            }

            if (hitchUser && selectedServiceId && !selectedFeatureId) {
                console.log(`Getting Routes for Org ${hitchUser.accountId} / Service ${selectedServiceId}`);
                UpdateList();
                hitchDashCommand('admin', 'routes', 'list', {
                    theAccountId: hitchUser.accountId,
                    theServiceId: selectedServiceId
                });
                return;
            }

            if (hitchUser && selectedServiceId && selectedFeatureId) {
                console.log(`Getting Routes for Org ${hitchUser.accountId} / Service ${selectedServiceId} / Feature ${selectedFeatureId}`);
                UpdateList();
                hitchDashCommand('admin', 'routes', 'list', {
                    theAccountId: hitchUser.accountId,
                    theServiceId: selectedServiceId,
                    theFeatureId: selectedFeatureId
                });
                return;
            }

        }
    }, [dashboardConnection, hitchUser, selectedServiceId, selectedFeatureId]);


    function UpdateList() {
        console.log(`serviceId: ${selectedServiceId} / featureId: ${selectedFeatureId}`);
        hitchDashCommand('account', 'hitch-definition', 'list', {
            serviceId: selectedServiceId,
            featureId: selectedFeatureId
        });
    }

    useEffect(() => {
        if (list) {
            // Extract unique services using a Map to avoid duplicates
            const uniqueServicesMap = new Map();
            list.forEach(item => {
                if (!uniqueServicesMap.has(item.serviceId)) {
                    uniqueServicesMap.set(item.serviceId, { id: item.serviceId, name: item.system });
                }
            });
            const uniqueServices = Array.from(uniqueServicesMap.values());

            // Extract unique features using a Map to avoid duplicates
            const uniqueFeaturesMap = new Map();
            list.forEach(item => {
                if (!uniqueFeaturesMap.has(item.featureId)) {
                    uniqueFeaturesMap.set(item.featureId, { id: item.featureId, name: item.operationType });
                }
            });
            const uniqueFeatures = Array.from(uniqueFeaturesMap.values());

            setServices(uniqueServices);
            setFeatures(uniqueFeatures);
        }
    }, [list]);

    useEffect(() => {
        setDataTableData((prevState) => ({
            ...prevState,
            rows: data,
        }));
    }, [data]);

    function handleRowClick(row) {
       // DO NOTHING
    }

    const sortedServices = [...services].sort((a, b) => a.name.localeCompare(b.name));
    const sortedFeatures = [...features].sort((a, b) => a.name.localeCompare(b.name));

    function onServiceChange(service) {
        localStorage.setItem('selectedService', service);
        SetSelectedServiceId(service);
        console.log('onServiceChange', service);
    }

    function onFeatureChange(feature) {
        localStorage.setItem('selectedFeature', feature);
        SetSelectedFeatureId(feature);
        console.log('onFeatureChange', feature);
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox m={2}>
                <HitchTitle
                    title="Hitch Definitions"
                    subTitle="Isn't this pretty bad ass?" />

                <HitchBox display="flex" justifyContent="space-between" mt={2}>
                    <HitchBox>
                        <HitchSelectSimple
                            value={selectedServiceId}
                            label="Service"
                            options={sortedServices.map((option) => ({
                                label: option.name,
                                value: option.id,
                            }))}
                            placeholder="Select Service"
                            onChange={(e) => onServiceChange(e)}
                            isClearable
                        />

                        <HitchSelectSimple
                            value={selectedFeatureId}
                            label="Feature"
                            options={sortedFeatures.map((option) => ({
                                label: option.name,
                                value: option.id,
                            }))}
                            placeholder="Select Feature"
                            onChange={(e) => onFeatureChange(e)}
                            isClearable
                        />
                    </HitchBox>
                </HitchBox>

                <HitchDataTable
                    table={dataTableData}
                    canSearch
                    onRowClick={handleRowClick}
                />
            </HitchBox>
        </DashboardLayout>
    );
}

export default HitchDef;