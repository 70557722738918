import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiAvatar from "components/VuiAvatar";
import HitchButton from "components/HitchButton";
import FormField from "layouts/applications/wizard/components/FormField";
import avatar from "assets/images/hitch.png";

function Basics() {
  return (
    <HitchBox>
      <HitchBox width="80%" textAlign="center" mx="auto" mb={4}>
        <HitchBox mb={1}>
          <HitchTypography variant="h5" fontWeight="regular" color="white">
            Let&apos;s start with the basic information
          </HitchTypography>
        </HitchBox>
        <HitchTypography variant="body2" fontWeight="regular" color="text">
          Let us know your name and email address. Use an address you don&apos;t mind other users
          contacting you at
        </HitchTypography>
      </HitchBox>
      <HitchBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} container justifyContent="center">
            <HitchBox position="relative" height="max-content" mx="auto">
              <VuiAvatar
                src={avatar}
                alt="profile picture"
                sx={{ width: "150px", height: "150px" }}
                variant="rounded"
              />
              <HitchBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <HitchButton
                  variant="contained"
                  color="info"
                  sx={{ borderRadius: "12px", width: "35px", height: "35px" }}
                  iconOnly
                >
                  <Icon>edit</Icon>
                </HitchButton>
              </HitchBox>
            </HitchBox>
          </Grid>
          <Grid item xs={12} md={8}>
            <HitchBox mb={2}>
              <FormField type="text" label="first name" placeholder="Eg. Michael" />
            </HitchBox>
            <HitchBox mb={2}>
              <FormField type="text" label="last name" placeholder="Eg. Tomson" />
            </HitchBox>
            <HitchBox>
              <FormField type="text" label="email address" placeholder="Eg. vision@dashboard.com" />
            </HitchBox>
          </Grid>
        </Grid>
      </HitchBox>
    </HitchBox>
  );
}

export default Basics;
