import { debounce } from 'lodash'; // Ensure lodash is installed
import { useState, useEffect, useCallback } from 'react';
import { Route, useLocation, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Sidenav from 'examples/Sidenav';
import theme from 'assets/theme';
import routes from './routes';
import {
  useHitchAIUIController,
  setMiniSidenav,
  setTransparentNavbar,
} from 'context';
import 'assets/theme/base/plugins.css';
import { HubConnectionsProvider } from 'context/HubConnectionsProvider';
import { ChatConnectionsProvider } from 'context/ChatConnectionProvider';
import { HubAdminConnectionsProvider } from 'context/HubAdminConnectionProvider';
import ErrorBoundary from 'ErrorBoundary';
import GeneralChat from 'hitch/chat/general';
import SigninOidc from 'hitch/login/SigninOidc';
import UserDetail from 'hitch/administration/user/UserDetail';
import BotDetail from 'hitch/bot/BotDetail';
import ServiceDetail from 'hitch/hitch-admin/services/ServiceDetail';
import ServiceFeatureDetail from 'hitch/hitch-admin/services/ServiceFeatureDetail';
import UiComponentDetail from 'hitch/hitch-admin/uicomponents/UiComponentDetail';
import MenuItemDetail from 'hitch/hitch-admin/menu-items/MenuItemDetail';
import IconDetail from 'hitch/hitch-admin/icons/IconDetail';
import AccountDetails from 'hitch/hitch-admin/accounts/AccountDetails';
import DocumentRepositoryDetails from 'hitch/plugins/documentRepository/DocumentRepositoryDetails';
import GitHubRepository from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories/GitHubRepository';
import GitHubIssue from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Issues/GitHubIssue';
import GitHubLabel from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Labels/GitHubLabel';
import GitHubRuleDetails from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Webhook/GitHubRuleDetails';
import HoganSmithIntake from 'pages/HoganSmithIntake';
import GitHubRuleCondition from 'third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Webhook/GitHubRuleCondition';
import NodeDetails from 'hitch/hitch-admin/nodes/NodeDetails';
import Homepage from 'homepage';
import UserDetails from 'hitch/hitch-admin/users/UserDetails';
import SosPolicyDetails from 'third/sos/policies/PolicyDetails';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import BotAltConfig from 'hitch/bot/BotAltConfig';
import HitchMainDashboard from 'hitch/HitchMainDashboard';
import SosTaskDetails from 'third/sos/tasks/SosTaskDetails';
import HitchDefDetails from 'hitch/administration/hitchdef/HitchDefDetails';
import { OrgConnectionProvider } from 'context/OrgConnectionProvider';
import Registration from 'pages/Registration';

export default function App() {
  const [controller, dispatch] = useHitchAIUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollY = Math.max(0, window.scrollY || 0); // Ensure non-negative value
      const shouldBeTransparent = scrollY <= 100; // Threshold for transparency

      // Update state only when necessary
      if (controller.transparentNavbar !== shouldBeTransparent) {
        setTransparentNavbar(dispatch, controller, shouldBeTransparent);
      }
    }, 200);

    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch, controller]);




  // Mouse enter and leave logic
  const handleOnMouseEnter = useCallback(() => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  }, [miniSidenav, onMouseEnter, dispatch]);

  const handleOnMouseLeave = useCallback(() => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  }, [onMouseEnter, dispatch]);

  // Direction and routing effects
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  const getRoutes = useCallback((routes) => {
    return routes.map((route, key) => {
      if (route.layout === '/admin') {
        return <Route path={`${route.path}`} element={route.component} key={key} />;
      }
      if (route.collapse) {
        return getRoutes(route.collapse);
      } else {
        return null;
      }
    });
  }, []);


  return (
    <HubConnectionsProvider>
      <OrgConnectionProvider>
        <ChatConnectionsProvider>
          <HubAdminConnectionsProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {layout === 'dashboard' && (
                <Sidenav
                  color={sidenavColor}
                  brandName="Hitch.AI"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              )}
              <ErrorBoundary>
                <Routes>
                  {getRoutes(routes)}
                  <Route path="/" element={<Homepage />} />
                  <Route path="/app" element={<HitchMainDashboard />} />
                  <Route path="/registration" element={<Registration />} />
                  <Route path="/chat/general/:chatId" element={<GeneralChat />} />
                  <Route exact path="/callback" element={<SigninOidc />} />
                  <Route path="/hitch-admin/users/:userId" element={<UserDetails />} />
                  <Route path="/hitch-admin/cluster/:nodeId" element={<Node />} />
                  <Route path="/deployment/my-bots/:botId" element={<BotDetail />} />
                  <Route path="/deployment/my-bots/:botId/:configId" element={<BotAltConfig />} />
                  <Route path="/hitch-admin/services/:serviceId" element={<ServiceDetail />} />
                  <Route path="/hitch-admin/services/:serviceId/:featureId" element={<ServiceFeatureDetail />} />
                  <Route path="/hitch-admin/uicomponents/:componentId" element={<UiComponentDetail />} />
                  <Route path="/hitch-admin/menu-items/:menuItemId" element={<MenuItemDetail />} />
                  <Route path="/hitch-admin/icons/:iconId" element={<IconDetail />} />
                  <Route path="/hitch-admin/accounts/:accountId" element={<AccountDetails />} />
                  <Route path="/hitch-admin/nodes/:nodeId" element={<NodeDetails />} />
                  <Route path="/third/sos/policies/:policyId" element={<SosPolicyDetails />} />
                  <Route path="/integrations/hitch/document-repository/:repoId" element={<DocumentRepositoryDetails />} />
                  <Route
                    path="/third/hc5e92791_b9e7_4d7e_ae22_90cce4ec0b43/GitHubRepositories/:repoId"
                    element={<GitHubRepository />}
                  />
                  <Route
                    path="/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories/:repoId/Issues/:issueId"
                    element={<GitHubIssue />}
                  />
                  <Route
                    path="/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories/:repoId/Labels/:labelName"
                    element={<GitHubLabel />}
                  />
                  <Route
                    path="/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRules/:ruleId"
                    element={<GitHubRuleDetails />}
                  />
                  <Route
                    path="/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRules/:ruleId/conditions/:conditionId"
                    element={<GitHubRuleCondition />}
                  />
                  <Route path="/third/sos/tasks/:taskId" element={<SosTaskDetails />} />
                  <Route path="/hogan-smith/intake" element={<HoganSmithIntake />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-of-service" element={<PrivacyPolicy />} />
                  <Route path="/integrations" element={<PrivacyPolicy />} />
                  <Route path="/pricing" element={<PrivacyPolicy />} />
                  <Route path="/register" element={<PrivacyPolicy />} />


                  <Route path="/administration/hitch/:defId" element={<HitchDefDetails />} />
                </Routes>
              </ErrorBoundary>
            </ThemeProvider>
          </HubAdminConnectionsProvider>
        </ChatConnectionsProvider>
      </OrgConnectionProvider>
    </HubConnectionsProvider>
  );
}
