import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Vision UI Dashboard PRO React components
import HitchBox from "components/HitchBox";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import Footer from "examples/Footer";

// OrderDetails page components
import Header from "layouts/ecommerce/orders/order-details/components/Header";
import OrderInfo from "layouts/ecommerce/orders/order-details/components/OrderInfo";
import TrackOrder from "layouts/ecommerce/orders/order-details/components/TrackOrder";
import PaymentDetails from "layouts/ecommerce/orders/order-details/components/PaymentDetails";
import BillingInformation from "layouts/ecommerce/orders/order-details/components/BillingInformation";
import OrderSummary from "layouts/ecommerce/orders/order-details/components/OrderSummary";

function OrderDetails() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <HitchBox my={7}>
        <HitchBox mx="auto" display="flex" justifyContent="center" maxWidth="780px">
          <Card sx={{ padding: "20px" }}>
            <HitchBox pt={2} px={2}>
              <Header />
            </HitchBox>
            <Divider light />
            <HitchBox pt={1} pb={3} px={2}>
              <HitchBox mb={3}>
                <OrderInfo />
              </HitchBox>
              <Divider light />
              <HitchBox mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <TrackOrder />
                  </Grid>
                  <Grid item xs={12} md={6} lg={5}>
                    <PaymentDetails />
                    <HitchBox mt={3}>
                      <BillingInformation />
                    </HitchBox>
                  </Grid>
                  <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>
                    <OrderSummary />
                  </Grid>
                </Grid>
              </HitchBox>
            </HitchBox>
          </Card>
        </HitchBox>
      </HitchBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderDetails;
