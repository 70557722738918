import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";
import { useHubConnections } from "context/HubConnectionsProvider";

function SosVulnerabilities() {
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const [pageNum, setPageNum] = useState(1);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("fad5c575-0f9a-4104-bf41-0d2eb57108b1", setVs);
            LoadThePage();
            return () => {
                dashboardConnection.off("fad5c575-0f9a-4104-bf41-0d2eb57108b1", setVs);
            }
        }
    }, [dashboardConnection, isConnectionReady]);

    function LoadThePage() {
        hitchDashCommand('sos', 'vulnerabilities', 'list', {
            pageIndex: 1,
            PerPage: 20
        });
    }

    function setVs(data) {
        console.log("fad5c575-0f9a-4104-bf41-0d2eb57108b1", data);
        setList(data);
    }
    
    const cols = [
        { accessor: 'name', Header: 'Vulnerability', width: '30%' },
        { accessor: 'severity', Header: 'Severity', width: '20%' },
        { accessor: 'ghsA_Id', Header: 'GitHub Advisory', width: '20%' },
        { accessor: 'cvE_Id', Header: 'CVE', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '10%',
            Cell: ({ row }) => (
                <HitchButton variant="outlined" color="info" size="small">View Details</HitchButton>
            )
        }
    ];

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    // useEffect(() => {
    //     const mockVulnerabilities = [
    //         { vulnerability: 'SQL Injection', severity: 'High', status: 'Unresolved', source: 'GitHub' },
    //         { vulnerability: 'Cross-Site Scripting', severity: 'Medium', status: 'In Progress', source: 'GitHub' },
    //         { vulnerability: 'Broken Authentication', severity: 'Critical', status: 'Resolved', source: 'Internal Scan' },
    //     ];

    //     let filtered = mockVulnerabilities;
    //     if (searchValue) {
    //         filtered = filtered.filter(item => item.vulnerability.toLowerCase().includes(searchValue.toLowerCase()));
    //     }

    //     setTotalResults(filtered.length);
    //     const start = (page - 1) * perPage;
    //     const end = start + perPage;
    //     setList(filtered.slice(start, end));
    // }, [page, perPage, searchValue]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Vulnerability Management
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search Vulnerabilities"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <HitchButton variant="outlined" color="white">Refresh</HitchButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={{ columns: cols, rows: list }}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={(newPage, newPerPage) => { setPage(newPage); setPerPage(newPerPage); }}
                                paginated={true}
                                page={page}
                            />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosVulnerabilities;
