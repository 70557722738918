
// FILE: /UI\hitch-ai-ui-2\src\examples\Timeline\TimelineList\index.js

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { TimelineProvider } from "examples/Timeline/context";
import colors from "assets/theme/base/colors";

function TimelineList({ title, dark = false, children }) {
  const { info } = colors;

  return (
    <TimelineProvider value={dark}>
      <Card sx={{ background: dark ? info.main : null }}>
        <HitchBox>
          <HitchBox mb="30px">
            <HitchTypography variant="lg" fontWeight="bold" color={dark ? "white" : "white"}>
              {title}
            </HitchTypography>
          </HitchBox>
          <HitchBox>{children}</HitchBox>
        </HitchBox>
      </Card>
    </TimelineProvider>
  );
}

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TimelineList;
