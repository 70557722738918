
// FILE: /UI/hitch-ai-ui-2/src/components/HitchButton/index.js

import { forwardRef } from "react";
import PropTypes from "prop-types";
import HitchButtonRoot from "components/HitchButton/HitchButtonRoot";

const HitchButton = forwardRef(
  (
    { 
      color = "white", 
      variant = "contained", 
      size = "medium", 
      circular = false, 
      iconOnly = false, 
      children, 
      ...rest 

    }, ref) => (
    <HitchButtonRoot
      {...rest}
      ref={ref}
      color="white"
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly }}
    >
      {children}
    </HitchButtonRoot>
  )
);

// Typechecking props for the HitchButton
HitchButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default HitchButton;
