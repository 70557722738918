import HitchBox from "components/HitchBox";
import ChatWindow from "../ChatWindow";
import ChatInput from "../ChatInput";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useChatConnections } from "context/ChatConnectionProvider";


function ChatControl({ chatId, overrideBotId, maxHeight, configurationId }) {
    const [newMessage, setNewMessage] = useState({});

    const {
        hitchUser,
    } = useHubConnections();

    const {
        chatConfigurationId,
        setConfigurationId,
        hitchCommand,
        setMessages,
        setSessionId,
        conversation, // Get the current conversation details
    } = useChatConnections();

    useEffect(() => {
        if(conversation) {
            console.log('conversation', conversation);
        }
    }, [conversation]);

    const handleSendMessage = () => {
        if (newMessage.content !== '') {
            hitchCommand('chat', 'conversation', 'message-add', newMessage, () => { });
            const newMessageToAdd = {
                text: newMessage.content,
                content: newMessage.content,
                contentType: 'text',
                user: hitchUser.userId
            };
            setMessages(prevMessages => [...prevMessages, newMessageToAdd]);
            setNewMessage({ content: '', contentType: 'text', user: null });
        }
    };

    useEffect(() => {
        if (overrideBotId && conversation && conversation.id === chatId && conversation.activeBotId != overrideBotId) {
            console.log('overrideBotId', overrideBotId);
            hitchCommand('chat', 'conversation', 'set-bot', { botId: overrideBotId });
        }

    }, [conversation?.id, conversation?.activeBotId, chatId, overrideBotId]);

    useEffect(() => {
        if(configurationId && chatConfigurationId && configurationId !== chatConfigurationId) {
            setConfigurationId(configurationId);
        }
    }, [configurationId, chatConfigurationId]);

    useEffect(() => {
        if (chatId) {
            setSessionId(chatId);
        }
    }, [chatId]);

    return (
        <HitchBox display="flex" flexDirection="column" flexGrow={1} overflow="hidden" p={3}>
            <HitchBox flexGrow={1} overflow="auto" mb={2}>
                <ChatWindow maxHeight={maxHeight} />
            </HitchBox>
            <ChatInput
                placeholder="Hitchy, what meetings are left today?"
                newMessage={newMessage}
                handleSendMessage={handleSendMessage}
                setNewMessage={setNewMessage} />
        </HitchBox>
    );
}
export default ChatControl;