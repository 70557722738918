
// FILE: /UI/hitch-ai-ui-2/src/layouts/pages/account/components/FormField/index.js

import PropTypes from "prop-types";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import VuiInput from "components/VuiInput";

function FormField({ label = " ", ...rest }) {
  return (
    <HitchBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <HitchBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <HitchTypography
          component="label"
          variant="caption"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </HitchTypography>
      </HitchBox>
      <VuiInput {...rest} />
    </HitchBox>
  );
}

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
};

export default FormField;
