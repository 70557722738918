
import { forwardRef } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { defaultItemIconBox } from "examples/Items/DefaultItem/styles";

const DefaultItem = forwardRef(({ color = "info", icon, title, description, ...rest }, ref) => (
  <HitchBox {...rest} ref={ref} display="flex" alignItems="center">
    <HitchBox sx={(theme) => defaultItemIconBox(theme, { color })}>{icon}</HitchBox>
    <HitchBox ml={2} lineHeight={1}>
      <HitchTypography display="block" variant="button" color="white" fontWeight="bold">
        {title}
      </HitchTypography>
      <HitchTypography variant="button" fontWeight="regular" color="text">
        {description}
      </HitchTypography>
    </HitchBox>
  </HitchBox>
));

// Typechecking props for the DefaultItem
DefaultItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DefaultItem;
