import { forwardRef } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";
import HitchAvatar from "components/HitchAvatar";
import IconButton from "@mui/material/IconButton"; // Import IconButton
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip

const UserNotificationItem = forwardRef(({ color = "dark", onDelete, hitchId, image, title, theLink, date, onClear, ...rest }, ref) => {
    const handleClick = (event) => {
        // Prevent the clear button from triggering this
        if (event.target.closest('.clear-button')) {
            return;
        }
        if (theLink) {
            window.open(theLink, '_blank');
        }
    };

    return (
        <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)} onClick={handleClick}>
            <HitchBox
                width="2.25rem"
                height="2.25rem"
                mt={0.25}
                mr={2}
                mb={0.25}
                borderRadius="lg"
                sx={(theme) => menuImage(theme, { color })}
            >
                {hitchId && (
                    <HitchAvatar
                        hitchId={hitchId}
                        size="sm" />
                )}
            </HitchBox>
            <HitchBox sx={{ flexGrow: 1 }}>
                <HitchTypography variant="button" textTransform="capitalize" fontWeight="regular">
                    <strong>{title[0]}</strong> {title[1]}
                </HitchTypography>
                <HitchTypography
                    variant="caption"
                    color="secondary"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 0.5,
                    }}
                >
                    <HitchTypography variant="button" color="secondary">
                        <Icon
                            sx={{
                                lineHeight: 1.2,
                                mr: 0.5,
                            }}
                        >
                            watch_later
                        </Icon>
                    </HitchTypography>
                    {date}
                </HitchTypography>
            </HitchBox>
            <Tooltip title="Clear">
                <IconButton onClick={onDelete} size="small" sx={{ ml: 1 }} className="clear-button">
                    <Icon>clear</Icon> {/* The "X" icon */}
                </IconButton>
            </Tooltip>
        </MenuItem>
    );
});


// Typechecking props for the UserNotificationItem
UserNotificationItem.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    image: PropTypes.node.isRequired,
    title: PropTypes.arrayOf(PropTypes.string).isRequired,
    date: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired, // Add onDelete prop type
};

export default UserNotificationItem;