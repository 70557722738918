
import { Grid } from "@mui/material";
import CustomTabPanel from "components/CustomTabPanel";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import BotOperations from "./BotOperations";
import OperationSelectionModal from "./OperationSelectionModal";

function BotApis({
    editMode,
    functions,
    handleChange,
    onSelectedOperation,
    openModal,
    removeFunction,
    setFunctions,
    setOpenModal,
    tab,
    values,
}) {
    return (
        <CustomTabPanel value={tab} index={4}>
            <HitchBox pl={0} lineHeight={1}>
                <HitchTypography variant="h5" fontWeight="medium" color="white">
                    APIs
                </HitchTypography>
                <Grid container>
                    <BotOperations
                        values={values}
                        handleChange={handleChange}
                        functions={functions}
                        setFunctions={setFunctions}
                        removeFunction={removeFunction}
                        editMode={editMode}
                        setOpen={setOpenModal} // Pass setOpenModal function to BotOperations
                    />
                </Grid>
            </HitchBox>

            {/* Operation Selection Modal */}
            <OperationSelectionModal
                open={openModal}
                setOpen={setOpenModal} // Pass setOpenModal function to OperationSelectionModal
                onSelect={(selectedOperation) => {
                    // Logic for adding the selected operation to the bot functions
                    onSelectedOperation(selectedOperation);
                    setFunctions([...functions, selectedOperation]);
                }}
            />

        </CustomTabPanel>
    );
}

export default BotApis;