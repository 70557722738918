import Card from "@mui/material/Card";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import HitchButton from "components/HitchButton";
import { useNavigate } from "react-router-dom";

function SosAssets() {
    const cols = [
        { accessor: 'assetName', Header: 'Asset Name', width: '30%' },
        { accessor: 'category', Header: 'Category', width: '20%' },
        { accessor: 'status', Header: 'Status', width: '20%' },
        { accessor: 'vendor', Header: 'Vendor', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '10%',
            Cell: ({ row }) => (
                <HitchButton variant="outlined" color="info" size="small">View Details</HitchButton>
            )
        }
    ];

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        const mockAssets = [
            { assetName: 'Firewall', category: 'Hardware', status: 'Active', vendor: 'Cisco' },
            { assetName: 'Cloud Storage', category: 'Software', status: 'Under Review', vendor: 'AWS' },
            { assetName: 'Workstation', category: 'Hardware', status: 'Inactive', vendor: 'Dell' },
        ];

        let filtered = mockAssets;
        if (searchValue) {
            filtered = filtered.filter(item => item.assetName.toLowerCase().includes(searchValue.toLowerCase()));
        }

        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        setList(filtered.slice(start, end));
    }, [page, perPage, searchValue]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Asset Management
                            </HitchTypography>
                        </HitchBox>
                        <HitchBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search Assets"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <HitchButton variant="outlined" color="white">Refresh</HitchButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={{ columns: cols, rows: list }}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={(newPage, newPerPage) => { setPage(newPage); setPerPage(newPerPage); }}
                                paginated={true}
                                page={page}
                            />
                        </HitchBox>
                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    );
}

export default SosAssets;
