
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/SearchUsersModal/index.js

import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Box, TextField, Checkbox, FormControl, List, ListItem, ListItemText, Typography } from '@mui/material';
import HitchBox from 'components/HitchBox';
import VuiInput from 'components/VuiInput';
import VuiSelect from 'components/VuiSelect';
import HitchButton from 'components/HitchButton';
import { useHubConnections } from 'context/HubConnectionsProvider';

function SearchUsersModal({ open, onClose, onAssign }) {
    const { hitchUser, account, dashboardConnection, hitchDashCommand, isConnectionReady, errorSnack, infoSnack, successSnack } = useHubConnections();
    const [theRoles, setTheRoles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [users, setUsers] = useState([]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'rgba(0, 0, 0, 0.8)',
        color: '#FFFFFF',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
        p: 3,
        outline: 'none',
    };

    // Clear form fields when the modal opens
    useEffect(() => {
        if (open) {
            setSearchTerm('');
            setSelectedUsers([]);
            setSelectedRole('');
            setUsers([]);
        }
    }, [open]);

    // Debounce logic: Perform search only once every second
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length > 3) {
                performSearch(searchTerm);
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady && account) {
            dashboardConnection.on("29f554ed-e5e1-4396-b4ab-f0c59605afb3", (data) => {
                setUsers(data);
            });
            dashboardConnection.on("59dcc752-3322-4b7d-8090-b482e88e4002", (data) => {
                setTheRoles(data);
            });

            hitchDashCommand('account', 'role', 'list', {});

            return () => {
                dashboardConnection.off("29f554ed-e5e1-4396-b4ab-f0c59605afb3");
                dashboardConnection.off("59dcc752-3322-4b7d-8090-b482e88e4002");
            };
        }
    }, [dashboardConnection, isConnectionReady, account]);

    function performSearch(theSearchText) {
        if (dashboardConnection && isConnectionReady && account) {
            hitchDashCommand('user', 'user-search', 'search', {
                searchText: theSearchText,
            });
        }
    }

    // Handle user selection
    const handleUserSelect = (userId) => {
        setSelectedUsers((prev) =>
            prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
        );
    };

    // Handle role change
    const handleRoleChange = (selectedOption) => {
        setSelectedRole(selectedOption ? selectedOption.value : '');
    };

    // Handle search change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Handle assign button click
    const handleAssign = () => {
        onAssign(selectedUsers, selectedRole);
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
            }}
        >
            <HitchBox sx={modalStyle}>
                <Typography variant="h6" sx={{ mb: 2, color: '#E0E0E0' }}>
                    Search Users
                </Typography>

                <VuiInput
                    name="searchTerm"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                    margin="dense"
                    sx={{ input: { color: '#E0E0E0' }, label: { color: '#B0B0B0' } }}
                />

                <List sx={{ maxHeight: 200, overflowY: 'auto', mb: 2 }}>
                    {users
                        .filter(user => user.name.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map(user => (
                            <ListItem key={user.id} button onClick={() => handleUserSelect(user.id)}>
                                <Checkbox
                                    checked={selectedUsers.includes(user.id)}
                                    sx={{ color: '#E0E0E0' }}
                                />
                                <ListItemText
                                    primary={user.name}
                                    secondary={user.email}
                                    primaryTypographyProps={{ color: '#FFFFFF' }}
                                    secondaryTypographyProps={{ color: '#B0B0B0' }}
                                />
                            </ListItem>
                        ))}
                </List>

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <VuiSelect
                        name="role"
                        value={selectedRole?.name}
                        onChange={handleRoleChange}
                        options={theRoles.map((option) => ({
                            label: option.name,
                            value: option.id,
                        }))}
                        placeholder="Select role"
                        isClearable
                        sx={{ color: '#FFFFFF' }}
                    />
                </FormControl>

                <Box display="flex" justifyContent="flex-end">
                    <HitchButton
                        variant="outlined"
                        onClick={handleAssign}
                        color="white"
                        sx={({ palette: { white } }) => ({
                            border: `1px solid ${white.main}`,
                            color: white.main,
                            mr: 1
                        })}
                    >
                        Assign
                    </HitchButton>

                    <HitchButton
                        variant="outlined"
                        onClick={onClose}
                        color="white"
                        sx={({ palette: { white } }) => ({
                            border: `1px solid ${white.main}`,
                            color: white.main
                        })}
                    >
                        Cancel
                    </HitchButton>
                </Box>
            </HitchBox>
        </Modal>
    );
}

export default SearchUsersModal;
