
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/H269A23C5_AB35_4F6C_9ADF_5B1C30285A66/Documentation/index.js

import { Card } from "@mui/material";
import HitchFormField from "components/HitchFormField";
import HitchSelectSimple from "components/HitchSelectSimple";
import SimpleFormField from "components/SimpleFormField";
import HitchBox from "components/HitchBox";
import VuiInput from "components/VuiInput";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import useGitHubFiles from "hooks/useGitHubFiles";
import { useEffect, useState } from "react";
import GitHubFiles from "third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories/GitHubFiles";

function Documentation() {
    const [repos, setRepos] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedRepoName, setSelectedRepoName] = useState(null);

    const [selectedRepoId, setSelectedRepoId] = useState(() => {
        const storedRepoId = localStorage.getItem('selectedRepoId');
        return storedRepoId ? Number(storedRepoId) : null;
    });

    const [selectedBranch, setSelectedBranch] = useState(localStorage.getItem('selectedBranch'));
    const sortedRepos = [...repos].sort((a, b) => a.name?.localeCompare(b.name));
    const sortedBranches = [...branches].sort((a, b) => a.name?.localeCompare(b.name));
// localStorage.getItem('selectedRepoId')
    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                console.log('subscribing to events!');
                dashboardConnection.on("2fb972b7-9d11-45a1-b629-24d02eeeca97", (data) => {
                    setRepos(data);
                });
                dashboardConnection.on("c885d4fa-3c12-48c9-9605-d0b3fad8a149", (data => {
                    console.log('c885d4fa-3c12-48c9-9605-d0b3fad8a149', data);
                    if (data === "An Error Has Occurred.") {
                        console.error(data);
                        return;
                    }
                    setBranches(data);
                }));
                // Add other event subscriptions here
                hitchDashCommand('github', 'repositoriesrepositories', 'list', {
                    page: 1,
                    per_page: 100
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('2fb972b7-9d11-45a1-b629-24d02eeeca97');
                dashboardConnection.off('c885d4fa-3c12-48c9-9605-d0b3fad8a149');
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        console.group('repo update');
        console.log('selectedRepoId', selectedRepoId);
        console.log('repos', repos);
        console.groupEnd();
        if (selectedRepoId && repos) {
            const repo = repos.find((a) => a.id === selectedRepoId);
            console.log('the repo', repo);
            if (repo) {
                setSelectedRepoName(repo.name);
                hitchDashCommand('github', 'branches', 'list', {
                    repoId: repo.name,
                    page: 1,
                    per_page: 100,
                });
            } else {
                console.error('Repository not found for the selectedRepoId:', selectedRepoId);
            }
        } else {
            setBranches([]);
            // setSelectedBranch(null); // Ensure branch is reset
            // setSelectedRepoName(null);
        }
    }, [selectedRepoId, repos]);

    useEffect(() => {
        if (branches.length > 0 && selectedBranch) {
            const validBranch = branches.find((branch) => branch.name === selectedBranch);
            if (!validBranch) {
                setSelectedBranch(null);
            }
        }
    }, [branches, selectedBranch]);

    useEffect(() => {
        if (branches.length > 0 && selectedBranch) {
            const validBranch = branches.find((branch) => branch.name === localStorage.getItem('selectedBranch'));
            if (validBranch) {
                setSelectedBranch(validBranch.name);
            } else {
                setSelectedBranch(null);
            }
        }
    }, [branches]);
    
    function onRepositoryChange(evt) {
        console.log('onRepositoryChange', evt);
        setSelectedRepoId(evt);
        localStorage.setItem('selectedRepoId', evt);
    }

    function onBranchChange(newBranch) {
        console.log('onBranchChange', newBranch);
        if (branches.some((branch) => branch.name === newBranch)) {
            setSelectedBranch(newBranch);
            localStorage.setItem('selectedBranch', newBranch);
        } else {
            console.error('Selected branch not found in the available branches.');
        }
    }


    function documentRequest(repoId, branch, path) {
        hitchDashCommand('engineering', 'documentation', 'documentcodefile', {
            repoId: repoId,
            branch: branch,
            path: path
        });
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <HitchBox pt={6} pb={3}>
                <HitchBox mb={3}>
                    <Card>
                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Hitch Engineering Documentation Feature
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Under Construction
                            </HitchTypography>
                        </HitchBox>

                        <HitchBox p={3} pl={0} lineHeight={1}>
                            <>
                                <HitchSelectSimple
                                    value={selectedRepoId}
                                    label="Repository"
                                    options={sortedRepos.map((option) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                    placeholder="Select Repository"
                                    onChange={(e) => onRepositoryChange(e)}
                                    noOptionsMessage={(e) => "No Repositories Loaded."}
                                    isClearable
                                />

                                <HitchSelectSimple
                                    value={selectedBranch}
                                    label="Branch"
                                    options={sortedBranches.map((option) => ({
                                        label: option.name,
                                        value: option.name,
                                    }))}
                                    placeholder="Select Branch"
                                    onChange={(e) => onBranchChange(e)}
                                    noOptionsMessage={(e) => "No Branches Loaded."}
                                    isClearable
                                />

                                <GitHubFiles
                                    repoId={selectedRepoName}
                                    branch={selectedBranch}
                                    functionLabel="Submit Documentation Request"
                                    specialFunction={documentRequest}
                                />


                            </>
                        </HitchBox>

                    </Card>
                </HitchBox>
            </HitchBox>
        </DashboardLayout>
    )
}

export default Documentation;