import { Card, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
// Data
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
// Vision UI Dashboard React components
import HitchBox from "components/HitchBox";
import VuiProgress from "components/VuiProgress";
import HitchTypography from "components/HitchTypography";
import BarChart from "examples/Charts/BarCharts/BasicBarChart/index";
import { FaShoppingCart, FaWallet } from "react-icons/fa";
import { IoIosRocket } from "react-icons/io";
import { IoBuild } from "react-icons/io5";
import { barChartDataDashboard, barChartOptionsDashboard } from "../../data/barChart";

const ActiveUsers = () => {
  const { gradients } = colors;
  const { cardContent } = gradients;

  return (
    <Card>
      <HitchBox>
        <HitchBox
          mb="24px"
          height="220px"
          sx={{
            background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
            borderRadius: "20px",
          }}
        >
          <BarChart
            barChartData={barChartDataDashboard}
            barChartOptions={barChartOptionsDashboard}
          />
        </HitchBox>
        <HitchTypography variant="lg" color="white" fontWeight="bold" mb="5px">
          Active Users
        </HitchTypography>
        <HitchBox display="flex" alignItems="center" mb="40px">
          <HitchTypography variant="button" color="success" fontWeight="bold">
            (+23){" "}
            <HitchTypography variant="button" color="text" fontWeight="regular">
              than last week
            </HitchTypography>
          </HitchTypography>
        </HitchBox>
        <Grid container spacing="50px">
          <Grid item xs={6} md={3} lg={3}>
            <Stack direction="row" spacing="10px" mb="6px">
              <HitchBox
                bgColor="info"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
              >
                <FaWallet color="#fff" size="12px" />
              </HitchBox>
              <HitchTypography color="text" variant="button" fontWeight="medium">
                Users
              </HitchTypography>
            </Stack>
            <HitchTypography color="white" variant="lg" fontWeight="bold" mb="8px">
              32,984
            </HitchTypography>
            <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <Stack direction="row" spacing="10px" mb="6px">
              <HitchBox
                bgColor="info"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
              >
                <IoIosRocket color="#fff" size="12px" />
              </HitchBox>
              <HitchTypography color="text" variant="button" fontWeight="medium">
                Clicks
              </HitchTypography>
            </Stack>
            <HitchTypography color="white" variant="lg" fontWeight="bold" mb="8px">
              2,42M
            </HitchTypography>
            <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <Stack direction="row" spacing="10px" mb="6px">
              <HitchBox
                bgColor="info"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
              >
                <FaShoppingCart color="#fff" size="12px" />
              </HitchBox>
              <HitchTypography color="text" variant="button" fontWeight="medium">
                Sales
              </HitchTypography>
            </Stack>
            <HitchTypography color="white" variant="lg" fontWeight="bold" mb="8px">
              2,400$
            </HitchTypography>
            <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <Stack direction="row" spacing="10px" mb="6px">
              <HitchBox
                bgColor="info"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
              >
                <IoBuild color="#fff" size="12px" />
              </HitchBox>
              <HitchTypography color="text" variant="button" fontWeight="medium">
                Items
              </HitchTypography>
            </Stack>
            <HitchTypography color="white" variant="lg" fontWeight="bold" mb="8px">
              320
            </HitchTypography>
            <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
          </Grid>
        </Grid>
      </HitchBox>
    </Card>
  );
};

export default ActiveUsers;
