
import { Divider } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import HitchTypography from "components/HitchTypography";

function ActionDependencies({dependencyCols, dependencies, handleAddDependency, handleEditDependency}) {

    return (
        <HitchBox mb={3}>
            <Divider />
            <HitchTypography variant="h6" color="white">
                Dependencies
            </HitchTypography>
            <HitchButton
                onClick={handleAddDependency}
                variant="contained"
                color="info"
            >
                Add Dependency
            </HitchButton>

            <HitchDataTable
                key={dependencies.length} // Force re-render when dependencies change
                table={{
                    columns: dependencyCols,
                    rows: dependencies.map((d) => ({
                        ...d,
                        key: d.id, // Add unique key for each row
                    })),
                }}
                canSearch
                onRowClick={(row) => {
                    console.log("Clicked row:", row);
                    const dependency = dependencies.find((d) => d.id === row.id);
                    if (dependency) {
                        handleEditDependency(dependency);
                    } else {
                        console.error("Dependency not found for row:", row);
                    }
                }}
            />
        </HitchBox>
    );
}

export default ActionDependencies;