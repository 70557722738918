import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import HitchButton from "components/HitchButton";
import HitchSelectSimple from "components/HitchSelectSimple";
import { MdClose } from "react-icons/md";

function HitchSelectDialog({ handleClose, label, onChange, open, selectedValue, theOptions, title }) {
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiPaper-root": {
            backgroundColor: "black", // Set dialog background to black
            color: "white", // Ensure text color is white
            minWidth: "400px",
            minHeight: "400px"
        },
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
            color: "white",
        },
        "& .MuiDialogTitle-root": {
            color: "white",
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
            color: "white",
        },
    }));

    function handleTheChange(e) {
        onChange(e);
        handleClose();
    }

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle sx={{ m: 0, p: 2, color: "white" }} id="customized-dialog-title">
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                color="white"
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8
                }}
            >
                <MdClose />
            </IconButton>
            <DialogContent dividers>
                <HitchSelectSimple
                    value={selectedValue}
                    label={label}
                    options={theOptions.map((option) => ({
                        label: option.name,
                        value: option.id,
                    }))}
                    placeholder={`Select ${label}`}
                    onChange={(e) => handleTheChange(e)}
                    isClearable
                />
            </DialogContent>
            <DialogActions>
                <HitchButton autoFocus onClick={handleClose} variant="outlined" color="white" >
                    Cancel
                </HitchButton>
            </DialogActions>
        </BootstrapDialog>
    );
}

export default HitchSelectDialog;
