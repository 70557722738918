import { Card, Grid } from "@mui/material";
import HitchCodeField from "components/HitchCodeField";
import HitchEditButtons from "components/HitchEditButtons";
import HitchFormField from "components/HitchFormField";
import HitchPage from "components/HitchPage";
import HitchSelect from "components/HitchSelect";
import HitchSwitch from "components/HitchSwitch";
import HitchBox from "components/HitchBox";
import HitchButton from "components/HitchButton";
import { useHubConnections } from "context/HubConnectionsProvider";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import MonacoEditor from "react-monaco-editor";
import { useNavigate, useParams } from "react-router-dom";

function HitchDefDetails() {

    const { defId } = useParams();
    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();
    const { dashboardConnection, hitchDashCommand, hitchUser } = useHubConnections();
    const [data, setData] = useState({});

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: '#111',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        if (dashboardConnection) {
            dashboardConnection.on("68dbfad8-37c7-4f02-9509-89f8f98b6413", setData);
            dashboardConnection.on("860450cb-617d-4c91-8e1a-41ff624a7bab", handleUpdate);

            LoadFromPlatform();

            return () => {
                dashboardConnection.off("68dbfad8-37c7-4f02-9509-89f8f98b6413", setData);
                dashboardConnection.off("860450cb-617d-4c91-8e1a-41ff624a7bab", handleUpdate);
            }
        }
    }, [dashboardConnection]);

    function LoadFromPlatform() {
        hitchDashCommand('account', 'hitch-definition', 'get', {
            hitchId: defId
        });
    }

    function deleteAction(a) {
        console.log('deleteAction', a);
    }

    function editAction() {
        setEditMode(true);
    }

    function handleUpdate(data) {
        console.log("860450cb-617d-4c91-8e1a-41ff624a7bab", data);
        setEditMode(false);
        setData(data);
    }

    useEffect(() => {
        console.log('data', data);
    }, [data]);

    function saveDefinition(values) {
        console.log('values', values);
        const newDef = {
            accountId: data.accountId,
            aliasId: data.aliasId,
            created: data.created,
            createdBy: data.createdBy,
            id: data.id,
            instruction: values.instruction,
            isActive: values.isActive,
            javaScript: values.javaScript,
            lastConversationId: values.lastConversationId,
            modeId: values.modeId,
            name: values.name,
            updated: data.updated,
            updatedBy: data.updatedBy
        };

        console.log('newDef', newDef);
        hitchDashCommand('account', 'hitch-definition', 'update', {
            theDefinition: newDef
        });
    }

    const sortedMode = [
        { name: 'Disabled', id: 0 },
        { name: 'AI', id: 1 },
        { name: 'Training', id: 2 },
        { name: 'Production', id: 9 },
    ];

    return (
        <HitchPage
            title={data ? data.name : "Loading..."}
            subTitle={`Hitch Number ${defId}`}
        >
            <HitchEditButtons
                deleteAction={deleteAction}
                editAction={editAction}
                parentLabel="Organization Hitches"
                parentRoute="/administration/hitch"
                editMode={editMode}
                cancelAction={() => setEditMode(false)}
            />

            <HitchBox pl={4} pr={4} pt={4} pb={4}>
                <Card>
                    <Formik
                        initialValues={{
                            name: data?.name || "",
                            instruction: data?.instruction || "",
                            isActive: data?.isActive || "",
                            javaScript: data?.javaScript || "",
                            lastConversationId: data?.lastConversationId || "",
                            modeId: data?.modeId || 0,
                        }}
                        enableReinitialize
                        onSubmit={(values) => saveDefinition(values)} // Ensure saveEvidence is defined
                    >
                        {({ handleSubmit, setFieldValue, values }) => (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <HitchFormField
                                            name="name"
                                            label="Name"
                                            fullWidth
                                            editMode={editMode}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <HitchSwitch
                                            label="Is Active"
                                            name="isActive"
                                            onLabel="Active"
                                            offLabel="Not Active"
                                            editMode={editMode}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <HitchSelect
                                            name="modeId"
                                            label="Mode"
                                            options={sortedMode.map((option) => ({
                                                label: option.name,
                                                value: option.id,
                                            }))}
                                            placeholder="Select mode"
                                            onChange={(e) => onMode(e)}
                                            isClearable
                                            editMode={editMode}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <HitchFormField
                                            name="instruction"
                                            label="Instructions"
                                            fullWidth
                                            editMode={editMode}
                                            multiline
                                            rows={3}
                                        />
                                    </Grid>



                                    <Grid item xs={12} md={12}>
                                        <HitchCodeField
                                            name="javaScript"
                                            label="Java Script"
                                            editMode={editMode}
                                            options={{ theme: 'vs-dark' }} // Example of passing additional options
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <HitchButton type="submit" variant="outlined" color="success" sx={{ mt: 2, m: 1 }}>
                                            Save
                                        </HitchButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </HitchBox>

        </HitchPage>
    )
}

export default HitchDefDetails;