
// FILE: /UI/hitch-ai-ui-2/src/hitch/plugins/documentRepository/StoreOperationManager.jsx

import React from "react";
import { Box, Card, FormControlLabel, IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal, faSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";


function StoreOperationManager({
  serviceName,
  connectedOperations,
  formData,
  handleOperationClick,
  handleToggleSwitch,
  handleDisconnect,
}) {
  function getStatus(operation) {
    return formData[operation] ? formData[operation] : "NOT CONNECTED";
  }

  function getIcon(operation) {
    return formData[operation] ? faSignal : faSlash;
  }

  function isConnected(operation) {
    return formData[operation] ? true : false;
  }

  return (
    <>
      <Card>
        <HitchBox p={2} backgroundColor={formData.pushToSource ? "#00AAAA" : "#002222"}>
          <FormControlLabel
            control={<Switch checked={formData.pushToSource} onChange={() => handleToggleSwitch("pushToSource")} />}
            label={
              <HitchTypography variant="button" fontWeight="medium" color="white">
                Send To {serviceName}
              </HitchTypography>
            }
          />
          <Box display="flex" flexDirection="column" mt={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <HitchTypography variant="caption" fontWeight="bold" color="white">
                      Operation
                    </HitchTypography>
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    <HitchTypography variant="caption" fontWeight="bold" color="white">
                      Status
                    </HitchTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {["putFileOperation", "deleteFileOperation", "putMetadataOperation", "deleteMetadataOperation"].map(
                  (operation) => (
                    <TableRow key={operation}>
                      <TableCell>
                        <HitchTypography variant="caption" fontWeight="medium" color="white">
                          {operation.replace(/([A-Z])/g, " $1").replace("Operation", "")}
                        </HitchTypography>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleOperationClick(operation)} disabled={isConnected(operation)}>
                          <FontAwesomeIcon icon={getIcon(operation)} color="white" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <HitchTypography variant="caption" fontWeight="medium" color="white">
                          {getStatus(operation)}
                        </HitchTypography>
                        {isConnected(operation) && (
                          <IconButton onClick={() => handleDisconnect(operation)}>
                            <FontAwesomeIcon icon={faTrash} color="white" />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </Box>
        </HitchBox>
      </Card>

      <HitchBox sx={{ height: "10px" }} />

      <Card>
        <HitchBox p={2} backgroundColor={formData.pullFromSource ? "#00AAAA" : "#002222"}>
          <FormControlLabel
            control={<Switch checked={formData.pullFromSource} onChange={() => handleToggleSwitch("pullFromSource")} />}
            label={
              <HitchTypography variant="button" fontWeight="medium" color="white">
                Pull From {serviceName}
              </HitchTypography>
            }
          />
          <Box display="flex" flexDirection="column" mt={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <HitchTypography variant="caption" fontWeight="bold" color="white">
                      Operation
                    </HitchTypography>
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    <HitchTypography variant="caption" fontWeight="bold" color="white">
                      Status
                    </HitchTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {["listOperation", "getOperation"].map((operation) => (
                  <TableRow key={operation}>
                    <TableCell>
                      <HitchTypography variant="caption" fontWeight="medium" color="white">
                        {operation.replace(/([A-Z])/g, " $1").replace("Operation", "")}
                      </HitchTypography>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOperationClick(operation)} disabled={isConnected(operation)}>
                        <FontAwesomeIcon icon={getIcon(operation)} color="white" />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <HitchTypography variant="caption" fontWeight="medium" color="white">
                        {getStatus(operation)}
                      </HitchTypography>
                      {isConnected(operation) && (
                        <IconButton onClick={() => handleDisconnect(operation)}>
                          <FontAwesomeIcon icon={faTrash} color="white" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </HitchBox>
      </Card>
    </>
  );
}

export default StoreOperationManager;
