// FILE: /UI/hitch-ai-ui-2/src/layouts/pages/account/billing/components/Invoice/index.js

import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import HitchBox from "components/HitchBox";
import HitchTypography from "components/HitchTypography";
import { IoDocumentText } from "react-icons/io5";

function Invoice({ date, id, price, noGutter = false }) {
  return (
    <HitchBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb="32px"
    >
      <HitchBox lineHeight={1}>
        <HitchTypography display="block" variant="button" fontWeight="medium" color="white">
          {date}
        </HitchTypography>
        <HitchTypography variant="caption" fontWeight="regular" color="text">
          {id}
        </HitchTypography>
      </HitchBox>
      <HitchBox display="flex" alignItems="center">
        <HitchTypography variant="button" fontWeight="regular" color="text">
          {price}
        </HitchTypography>
        <HitchBox display="flex" alignItems="center" lineHeight={0} ml={3} sx={{ cursor: "poiner" }}>
          <IoDocumentText color="#fff" size="15px" />
          <HitchTypography variant="button" fontWeight="medium" color="text">
            &nbsp;PDF
          </HitchTypography>
        </HitchBox>
      </HitchBox>
    </HitchBox>
  );
}

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
